import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyEmailToken, createVerifiedUser, createCheckoutSession } from '../../services/api';
import { useAuth } from '../../hooks/useAuth';
import AuthLayout from './Layout/AuthLayout';
import { FormError, FormSuccess } from './Layout/FormInput';

const VerifyEmail = () => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const returnUrl = location.state?.returnUrl;
  
  const token = new URLSearchParams(location.search).get('token');

  const createUser = useCallback(async (oneTimeToken, userData) => {
    try {
      const response = await createVerifiedUser(oneTimeToken);
      if (response.token) {
        setMessage('Account created successfully! Logging you in...');
        
        try {
          await login(response.data.email, response.data.password);
          
          if (userData.selectedTier && userData.selectedTier !== 'free') {
            const checkoutSession = await createCheckoutSession(
              userData.selectedTier, 
              userData.selectedInterval, 
              userData.selectedCurrency,
              userData.promoCode,
              userData.trialdays
            );
            if (userData.chatState) {
              localStorage.setItem('pendingChatState', JSON.stringify(userData.chatState));
            }
            window.location.href = checkoutSession.url;
          } else {
            navigate(returnUrl || '/new', { state: userData.chatState });
          }
        } catch (loginError) {
          console.error('Login error:', loginError);
          setError('Account created, but login failed. Please try logging in manually.');
        }
      } else {
        setError('Failed to create account. Please try again.');
      }
    } catch (error) {
      console.error('User creation error:', error);
      setError('Failed to create account. Please try again.');
    }
  }, [login, navigate, returnUrl]);

  const verifyToken = useCallback(async () => {
    if (isVerifying) return;
    setIsVerifying(true);

    try {
      const response = await verifyEmailToken(token);
      if (response.oneTimeToken) {
        setMessage('Email verified. Creating your account...');
        await createUser(response.oneTimeToken, response.data);
      } else {
        setError('Invalid or expired token.');
      }
    } catch (error) {
      console.error('Email verification error:', error);
      if (error.response?.data?.error === 'User already exists') {
        setError('This verification link has already been used.');
      } else {
        setError('Verification failed. Please try again.');
      }
    } finally {
      setIsVerifying(false);
    }
  }, [isVerifying, token, createUser]);

  useEffect(() => {
    if (token) {
      verifyToken();
    }
  }, [token, verifyToken, returnUrl]);

  return (
    <AuthLayout title="Email Verification">
      <div className="verify-email-message">
        {error && <FormError message={error} />}
        {message && <FormSuccess message={message} />}
      </div>
    </AuthLayout>
  );
};

export default VerifyEmail;
