import React, { forwardRef } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export const FormInput = forwardRef(({ 
  label, 
  type = 'text', 
  id, 
  value, 
  onChange, 
  required = false,
  showPassword,
  togglePassword,
  placeholder = '',
  ...props 
}, ref) => {
  const isPassword = type === 'password';

  return (
    <div className="form-group">
      <div className={isPassword ? "password-input-wrapper" : undefined}>
        <input
          ref={ref}
          type={showPassword ? "text" : type}
          id={id}
          name={id}
          value={value}
          onChange={onChange}
          required={required}
          placeholder={placeholder}
          {...props}
        />
        {isPassword && (
          <button 
            type="button" 
            className="toggle-password"
            onClick={togglePassword}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        )}
      </div>
    </div>
  );
});

export const SubmitButton = ({ children, isLoading, disabled }) => (
  <button 
    type="submit" 
    className="submit-button"
    disabled={isLoading || disabled}
  >
    {isLoading ? 'Please wait...' : children}
  </button>
);

export const FormError = ({ message }) => (
  message ? <p className="error-message">{message}</p> : null
);

export const FormSuccess = ({ message }) => (
  message ? <p className="success-message">{message}</p> : null
); 