import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../Styles/ImageModal.css';

const ImageModal = ({ isOpen, onClose, imageUrl, title = "" }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [imageError, setImageError] = useState(false);

  const getMaxCharLength = (width) => {
    if (width <= 320) return 15; // Mobile small
    if (width <= 480) return 20; // Mobile medium 
    if (width <= 768) return 25; // Tablet
    return 30; // Desktop
  };


  if (!isOpen) return null;

  return (
    <div className="image-modal-overlay" onClick={onClose}>
      <div className="image-modal-container" onClick={e => e.stopPropagation()}>
        <div className="image-modal-header">
          <h3 className="image-modal-title">
            {title.substring(0, getMaxCharLength(window.innerWidth)) || 'Image Preview'}
          </h3>
          <button
            onClick={onClose}
            className="image-modal-close"
            aria-label="Close modal"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="image-modal-content">
          {isLoading && <div className="image-loading" />}
          {imageError ? (
            <div className="image-error-message">
              Unable to load image
            </div>
          ) : (
            <img
              src={imageUrl}
              alt="Modal content"
              className="modal-image"
              onLoad={() => {
                setIsLoading(false);
                setImageError(false);
              }}
              onError={() => {
                setIsLoading(false);
                setImageError(true);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ImageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default ImageModal;
