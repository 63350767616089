import React, { useState, useEffect, memo } from 'react';
import { useAuth } from '../hooks/useAuth';
import { updateProfile, deleteAccount, handleStripePortalRedirect, changePassword } from '../services/api';
import '../Styles/Settings.css';
import '../Styles/LoadingOverlay.css';
import useDocumentTitle from '../UseDocumentTitle';
import { FaGlobe, FaQuestion, FaMoon, FaTimes, FaPlus, FaChevronLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import CustomSelect from './Layout/CustomSelect';
import { chatModeOptions } from './Data/Modes';
import { Sun01Icon, Logout02Icon, Message01Icon, File02Icon, Book02Icon, StarIcon, AlertCircleIcon, Tick01Icon, InformationCircleIcon, UserSquareIcon, ProfileIcon, Settings05Icon, TestTube01Icon } from 'hugeicons-react';
import { ConfirmationModal } from './Layout/ConfirmationModal';

const Settings = () => {
  useDocumentTitle('Settings - StudyBuddy');
  const navigate = useNavigate();
  const previousPath = document.referrer.replace(window.location.origin, '') || '/';
  const { user, logout, memoryArray, updateMemory } = useAuth();
  const [activeSection, setActiveSection] = useState('profile');
  const [isLoading, setIsLoading] = useState(true);
  const [profileData, setProfileData] = useState({
    name: user?.name || '',
    phone_number: user?.phone_number || '',
    s_name: user?.s_name || '',
  });
  const [originalProfileData, setOriginalProfileData] = useState({
    name: '',
    phone_number: '',
    s_name: '',
  });
  const [defaultChatMode, setDefaultChatMode] = useState(() => {
    return localStorage.getItem('defaultChatMode') || 'qa';
  });
  const [fontSize, setFontSize] = useState(() => {
    return localStorage.getItem('fontSize') || 'medium';
  });
  const [betaFeatures, setBetaFeatures] = useState(() => {
    const savedFeatures = localStorage.getItem('betaFeatures');
    const parsedFeatures = savedFeatures ? JSON.parse(savedFeatures) : {
      searchConversations: false,
      pdfOCR: false,
    };
    
    // Override saved features if user tier doesn't meet requirements
    if (user?.tier !== 'ultimate' && user?.tier !== 'beta') {
      return {
        searchConversations: false,
        pdfOCR: false,
      };
    }
    
    return parsedFeatures;
  });
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [localMemoryArray, setLocalMemoryArray] = useState(memoryArray);
  const [originalMemoryArray, setOriginalMemoryArray] = useState(memoryArray);
  const [expandedMemoryIndex, setExpandedMemoryIndex] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const showCustomToast = (type, message) => {
    setToastType(type);
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000); // Hide after 3 seconds
  };

  const [theme, setTheme] = useState(() => {
    return localStorage.getItem('theme') || 'system';
  });

  const betaFeaturesListings = [
    {
      id: 'searchConversations',
      title: 'Search Conversations',
      description: 'Unlock advanced search capabilities to effortlessly navigate through all your AI conversations and study materials.',
      checked: betaFeatures.searchConversations,
      disabled: user?.tier !== 'ultimate' && user?.tier !== 'beta'
    },
    {
      id: 'pdfOCR', 
      title: 'Vision-Based PDF Uploads (OCR)',
      description: 'Extract text from scanned documents and images in your PDFs with our advanced OCR technology. Perfect for handwritten notes, textbooks, and more.',
      subtext: 'Please note that OCR processing may take longer than normal depending on document length and complexity.',
      checked: betaFeatures.pdfOCR,
      disabled: user?.tier !== 'ultimate' && user?.tier !== 'beta'
    }
  ];

  const MemoizedCustomToast = memo(({ message, type, onClose }) => {
    return (
      <div className={`custom-toast ${type}`}>
        <span>{message}</span>
      </div>
    );
  }, (prevProps, nextProps) => {
    return (
      prevProps.message === nextProps.message &&
      prevProps.type === nextProps.type
    );
  });
  
  useEffect(() => {
    localStorage.setItem('betaFeatures', JSON.stringify(betaFeatures));
  }, [betaFeatures]);

  useEffect(() => {
    const initialData = {
      name: user?.name || '',
      phone_number: user?.phone_number || '',
      s_name: user?.s_name || '',
    };
    setProfileData(initialData);
    setOriginalProfileData(initialData);
    setIsLoading(false);
  }, [user]);

  useEffect(() => {
    if (user?.memoryArray && Array.isArray(user.memoryArray)) {
      setLocalMemoryArray(user.memoryArray);
      setOriginalMemoryArray(user.memoryArray);
    } else {
      setLocalMemoryArray([]);
      setOriginalMemoryArray([]);
    }
  }, [user]);

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    
    if (!profileData.name.trim() || !profileData.s_name.trim()) {
      showCustomToast('error', 'Name fields cannot be empty');
      return;
    }

    setIsLoading(true);
    try {
      await updateProfile(profileData);
      showCustomToast('success', 'Profile updated successfully');
      setOriginalProfileData(profileData);
      if (user) {
        user.name = profileData.name;
        user.phone_number = profileData.phone_number;
      }
    } catch (error) {
      showCustomToast('error', 'Failed to update profile');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    openModal(
      "Are you sure you want to delete your account?",
      async () => {
        setIsLoading(true);
        try {
          await deleteAccount();
          logout();
          window.location.href = '/';
        } catch (error) {
          showCustomToast('error', 'Failed to delete account');
        } finally {
          setIsLoading(false);
        }
      },
      "This action cannot be undone."
    );
  };

  const handleDefaultChatModeChange = (newModel) => {
    setDefaultChatMode(newModel);
    localStorage.setItem('defaultChatMode', newModel);
  };

  const handleFontSizeChange = (newSize) => {
    setFontSize(newSize);
    localStorage.setItem('fontSize', newSize);
    document.documentElement.style.fontSize = getFontSizeValue(newSize);
  };

  const getFontSizeValue = (size) => {
    switch (size) {
      case 'small':
        return '14px';
      case 'medium':
        return '16px';
      case 'large':
        return '18px';
      default:
        return '16px'; // medium
    }
  };

  useEffect(() => {
    document.documentElement.style.fontSize = getFontSizeValue(fontSize);
  }, [fontSize]);

  const hasProfileChanges = () => {
    if (!profileData.name.trim() || !profileData.s_name.trim()) {
      return false;
    }
    
    return originalProfileData.name !== profileData.name || 
           originalProfileData.phone_number !== profileData.phone_number ||
           originalProfileData.s_name !== profileData.s_name;
  };

  const handleBetaFeatureToggle = (feature) => {
    setBetaFeatures(prev => {
      const newState = {
        ...prev,
        [feature]: !prev[feature]
      };
      return newState;
    });
  };

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    
    if (newTheme === 'system') {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      document.documentElement.setAttribute('data-theme', systemTheme);
    } else {
      document.documentElement.setAttribute('data-theme', newTheme);
    }
  };

  const fontSizeOptions = [
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' }
  ];

  const themeOptions = [
    { value: 'light', label: 'Light', icon: Sun01Icon },
    { value: 'dark', label: 'Dark', icon: FaMoon },
    { value: 'system', label: 'System', icon: FaGlobe }
  ];

  const handleMemoryChange = (index, value) => {
    const updatedMemory = [...localMemoryArray];
    updatedMemory[index] = value;
    setLocalMemoryArray(updatedMemory);
    setHasChanges(JSON.stringify(updatedMemory) !== JSON.stringify(originalMemoryArray));
  };

  const handleMemorySubmit = async () => {
    setIsLoading(true);
    try {
      const cleanedMemoryArray = localMemoryArray.filter(memory => memory && memory.trim());
      
      await updateMemory(cleanedMemoryArray);
      setLocalMemoryArray(cleanedMemoryArray);
      setOriginalMemoryArray(cleanedMemoryArray);
      setHasChanges(false);
      showCustomToast('success', 'Personalization updated successfully');
    } catch (error) {
      showCustomToast('error', 'Failed to update personalization');
      setLocalMemoryArray([...originalMemoryArray]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddMemory = () => {
    const updatedMemory = [...localMemoryArray, ''];
    setLocalMemoryArray(updatedMemory);
    setHasChanges(JSON.stringify(updatedMemory) !== JSON.stringify(originalMemoryArray));
  };

  const handleRemoveMemory = (index) => {
    const updatedMemory = localMemoryArray.filter((_, i) => i !== index);
    setLocalMemoryArray(updatedMemory);
    setHasChanges(JSON.stringify(updatedMemory) !== JSON.stringify(originalMemoryArray));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setPasswordError('');
    setPasswordSuccess('');

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setPasswordError('New passwords do not match');
      return;
    }

    if (passwordData.newPassword === passwordData.currentPassword) {
      setPasswordError('New password must be different from the current password');
      return;
    }

    try {
      await changePassword(passwordData.currentPassword, passwordData.newPassword);
      setPasswordSuccess('Password changed successfully');
      setPasswordData({ currentPassword: '', newPassword: '', confirmPassword: '' });
    } catch (err) {
      setPasswordError(err.response?.data?.message || 'Failed to change password');
    }
  };

  const renderProfileSection = () => (
    <div className="settings-section">
    <div className="settings-card">
      <h3>Personal Information</h3>
      <form onSubmit={handleProfileSubmit} className="profile-form">
        <div className='group-wrap'>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={profileData.name}
              onChange={handleProfileChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">What StudyBudyy calls you?</label>
            <input
              type="text"
              id="s_name"
              name="s_name"
              value={profileData.s_name}
              onChange={handleProfileChange}
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="email">Email (cannot be changed)</label>
          <input
            type="email"
            id="email"
            name="email"
            value={user?.email || ''}
            disabled
          />
        </div>
        {hasProfileChanges() && (
          <button type="submit" className="submit-button">
            Save Changes
          </button>
        )}
      </form>
      </div>

      <div className="settings-card">
        <h3>Personalization</h3>
        <p className="memory-description">
          Add personal details, preferences, or study goals to tailor your StudyBuddy's responses.
        </p>
        <div className="memory-list">
          {Array.isArray(localMemoryArray) && localMemoryArray.length === 0 && (
            <div className="empty-memory-message">
              No personalization added yet. Click the '+' button to get started!
            </div>
          )}
          {Array.isArray(localMemoryArray) && localMemoryArray.map((memory, index) => (
            <div key={index} className="memory-item">
              {expandedMemoryIndex === index ? (
                <textarea
                  value={memory}
                  onChange={(e) => handleMemoryChange(index, e.target.value)}
                  onBlur={() => setExpandedMemoryIndex(null)}
                  autoFocus
                  placeholder="E.g., 'I'm a high school student focusing on AP Biology and Chemistry.'"
                />
              ) : (
                <div 
                  className="memory-preview" 
                  onClick={() => setExpandedMemoryIndex(index)}
                >
                  {memory || 'Click to add details...'}
                </div>
              )}
              <button type="button" className="memory-button" onClick={() => handleRemoveMemory(index)}>
                <FaTimes />
              </button>
            </div>
          ))}
        </div>

        <div className="memory-actions">
          <button 
            type="button" 
            className="save-button" 
            onClick={handleMemorySubmit}
            disabled={!hasChanges} 
            style={{ 
              opacity: hasChanges ? 1 : 0,
              pointerEvents: hasChanges ? 'auto' : 'none'
            }}
          >
            Save
          </button>
          <button type="button" className="memory-add-button" onClick={handleAddMemory}>
            <FaPlus />
          </button>
        </div>
      </div>
      </div>
  );

  const renderAccountSection = () => (
    <div className="settings-section">
      {user?.tier === 'free' ? (
        <div className="subscription-promo">
          <div className="promo-title">
            <h3>Upgrade</h3>
            <p>Unlock advanced features and maximize your potential with a 7-day free trial</p>
          </div>
          <ul className="feature-list">
            <div className="feature-list-items">
              <li className="premium-feature">
                <Message01Icon size={18} style={{ marginRight: '0.75rem', color: '#FF6B6B' }} />
                Unlimited AI interactions
              </li>
              <li className="premium-feature">
                <File02Icon size={18} style={{ marginRight: '0.75rem', color: '#FF6B6B' }} />
                PDF Analysis Capabilities
              </li>
              <li className="premium-feature">
                <Book02Icon size={18} style={{ marginRight: '0.75rem', color: '#FF6B6B' }} />
                Access to Study Spaces
              </li>
              <li className="premium-feature">
                <StarIcon size={18} style={{ marginRight: '0.75rem', color: '#FF6B6B' }} />
                Priority response times
              </li>
            </div>
          </ul>
          <Link to="/pricing" className="upgrade-button">
            View Plans →
          </Link>
        </div>
      ) : (
        <div className="settings-card">
          <p><strong>Current Plan:</strong> {user?.tier?.toUpperCase() || 'N/A'}</p>
          <div className="subscription-actions">
            <button onClick={handleStripePortalRedirect} className="button-main">
              Manage Subscription
            </button>
            <Link to="/pricing" className="subscription-details-link">
              View pricing plans and subscription features
            </Link>
          </div>
          {user?.status === 'trialling' && (
            <div className="trial-disclaimer">
              <InformationCircleIcon />
              <p className='trial-disclaimer-text'>Cancellation of the trial will result in the immediate termination of your subscription.</p>
            </div>
          )}
        </div>
      )}
      <div className="settings-card">
        <h3>Security Settings</h3>
        {!showPasswordForm ? (
          <button 
            onClick={() => setShowPasswordForm(true)} 
            className="button-main"
          >
            Change Password
          </button>
        ) : (
          <form onSubmit={handlePasswordSubmit} className="password-form">
            {passwordError && (
              <p className="error-message">
                <AlertCircleIcon /> {passwordError}
              </p>
            )}
            {passwordSuccess && (
              <p className="success-message">
                <Tick01Icon /> {passwordSuccess}
              </p>
            )}
            <div className="form-group">
              <label htmlFor="currentPassword">Current Password</label>
              <input
                type="password"
                id="currentPassword"
                name="currentPassword"
                value={passwordData.currentPassword}
                onChange={handlePasswordChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                value={passwordData.newPassword}
                onChange={handlePasswordChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm New Password</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={passwordData.confirmPassword}
                onChange={handlePasswordChange}
                required
              />
            </div>
            <div className="button-group">
              <button type="submit" className="button-main">
                Save Password
              </button>
              <button 
                type="button" 
                className="cancel-button"
                onClick={() => {
                  setShowPasswordForm(false);
                  setPasswordData({ currentPassword: '', newPassword: '', confirmPassword: '' });
                  setPasswordError('');
                  setPasswordSuccess('');
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </div>
       <div className="settings-card delete">
        <h3>Delete Account</h3>
        <div className="settings-row">
          <div>
            <p>Warning: This action cannot be undone. All your data will be permanently deleted.</p>
          </div>
          <button onClick={handleDeleteAccount} className="button-main delete">
            Delete My Account
          </button>
        </div>
      </div>
    </div>
  );

  const renderPreferencesSection = () => (
    <div className="settings-section">
      <div className="settings-card">
        <h3>Display Settings</h3>
        <div className="settings-row">
          <div>
            <span>Font Size</span>
            <p className="setting-description">Adjust the text size across the application</p>
          </div>
          <CustomSelect
            options={fontSizeOptions}
            value={fontSize}
            onChange={(value) => handleFontSizeChange(value)}
          />
        </div>
      </div>

      <div className="settings-card">
        <h3>Chat Preferences</h3>
        <div className="settings-row">
          <div>
            <span>Default Chat Mode</span>
            <p className="setting-description">Select your preferred chat interaction style</p>
          </div>
          <CustomSelect
            options={chatModeOptions}
            value={defaultChatMode}
            onChange={(value) => handleDefaultChatModeChange(value)}
          />
        </div>
      </div>

      <div className="settings-card">
        <h3>Theme Settings</h3>
        <div className="settings-row">
          <div>
            <span>Color Theme</span>
            <p className="setting-description">Choose your preferred color theme</p>
          </div>
          <CustomSelect
            options={themeOptions}
            value={theme}
            onChange={(value) => handleThemeChange(value)}
            renderOption={(option) => (
              <div className="theme-option">
                <option.icon className="theme-icon" />
                <span>{option.label}</span>
              </div>
            )}
          />
        </div>
      </div>

      <div style={{ height: '100px' }}></div>
    </div>
  );

  const renderBetaFeaturesSection = () => (
    <div className="settings-section">
        {betaFeaturesListings.map(feature => (
          <div key={feature.id} className="settings-card">
            <div className="beta-feature-header">
              <div>
                <h3>{feature.title}</h3>
                <span className="beta-tag">BETA</span>
              </div>
              <label className={`toggle-switch ${feature.disabled ? 'disabled' : ''}`}>
                <input
                  type="checkbox"
                  checked={feature.checked}
                  onChange={() => handleBetaFeatureToggle(feature.id)}
                  disabled={feature.disabled}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
            <p>{feature.description}</p>
            {feature.subtext && <p>{feature.subtext}</p>}
            {feature.disabled && (
              <Link to="/pricing" className="upgrade-link">
                Upgrade to Ultimate to {feature.id === 'searchConversations' ? 'enjoy exclusive beta features' : 'access beta features'} →
              </Link>
            )}
          </div>
        ))}
    

      <div className="beta-disclaimer">
        <FaQuestion className="disclaimer-icon" />
        <p>Beta features are new and still being tested. We'd love your feedback as we improve them!</p>
      </div>
    </div>
  );

  const [modal, setModal] = useState({ isOpen: false, text: '', onConfirm: null, text2: null });

  const openModal = (text, onConfirm, text2 = null) => {
    setModal({ isOpen: true, text, onConfirm, text2 });
  };

  const closeModal = () => {
    setModal({ isOpen: false, text: '', onConfirm: null, text2: null });
  };

  const sections = [
    { id: 'profile', icon: UserSquareIcon, label: 'Profile', render: renderProfileSection },
    { id: 'account', icon: ProfileIcon, label: 'Account', render: renderAccountSection },
    { id: 'preferences', icon: Settings05Icon, label: 'Preferences', render: renderPreferencesSection },
    { id: 'beta', icon: TestTube01Icon, label: 'Beta Features', render: renderBetaFeaturesSection },
    { 
      id: 'logout', 
      icon: Logout02Icon, 
      label: 'Logout', 
      onClick: () => openModal(
        "Are you sure you want to logout?",
        () => {
          logout();
          navigate('/');
        }
      )
    }
  ];

  const renderLoadingOverlay = () => (
    <div className="loading-overlay">
      <div className="loading-content">
        <div className="loading-spinner"></div>
        <p>Loading...</p>
      </div>
    </div>
  );

  const renderReturnToChatButton = () => (
    <button 
      onClick={() => {
        if (previousPath === '/' || previousPath.includes('study-spaces')) {
          navigate(-1) || navigate('/');
        } else {
          navigate('/');
        }
      }} 
      className="return-to-chat-button"
    >
      <FaChevronLeft size={24} />
      <span>Return to Chats</span>
    </button>
  );

  return (
    <div className="settings-container">
      {showToast && (
        <MemoizedCustomToast 
          message={toastMessage} 
          type={toastType} 
          onClose={() => setShowToast(false)} 
      />
      )}
      <header className="settings-header">
        <div className="settings-header-left">
          {renderReturnToChatButton()}
          <h1>Settings</h1>
        </div>
        <button 
          className="help-button" 
          onClick={() => window.open('//cognora.ca/faq', '_blank')}
          aria-label="Help"
        >
          <FaQuestion size={20} />
          Help
        </button>
      </header>
      <div className="settings-content">
        <nav className="settings-nav">
          {sections.map((section) => (
            <button
              key={section.id}
              className={`nav-item ${activeSection === section.id ? 'active' : '' } ${section.label === 'Logout' ? 'logout' : ''}`}
              onClick={() => section.onClick ? section.onClick() : setActiveSection(section.id)}
            >
              <div className="icon-container">
                <section.icon className="icon" />
              </div>
              <span>{section.label}</span>
            </button>
          ))}
        </nav>
        <main className="settings-main">
          {isLoading ? renderLoadingOverlay() : (
            sections.find(section => section.id === activeSection)?.render()
          )}
        </main>
      </div>
      
      <ConfirmationModal
        isOpen={modal.isOpen}
        text={modal.text}
        text2={modal.text2}
        onConfirm={modal.onConfirm}
        onClose={closeModal}
      />
    </div>
  );
};

export default Settings;
