import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { signUp } from '../../services/api';
import AuthLayout from './Layout/AuthLayout';
import { FormInput, SubmitButton, FormError, FormSuccess } from './Layout/FormInput';

const SignUp = () => {
  const [userData, setUserData] = useState({ email: '', password: '', phone_number: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showFullForm, setShowFullForm] = useState(false);
  const location = useLocation();
  const passwordRef = useRef(null);

  // Memoize location state values
  const locationState = useMemo(() => ({
    selectedTier: location.state?.selectedTier || 'free',
    selectedInterval: location.state?.interval || 'monthly',
    selectedCurrency: location.state?.currency || 'usd',
    chatState: location.state?.chatState,
    promoCode: location.state?.promoCode,
    trialdays: location.state?.trialdays,
    returnUrl: location.state?.returnUrl
  }), [location.state]);

  useEffect(() => {
    if (showFullForm && passwordRef.current) {
      passwordRef.current.focus();
    }
  }, [showFullForm]);

  // Memoize handleChange
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setUserData(prev => ({ ...prev, [name]: value }));
  }, []);

  // Memoize checkbox handler
  const handleTermsChange = useCallback((e) => {
    setAgreeTerms(e.target.checked);
  }, []);

  // Memoize login link state
  const loginLinkState = useMemo(() => ({
    chatState: locationState.chatState,
    returnUrl: locationState.returnUrl,
    selectedTier: locationState.selectedTier,
    selectedInterval: locationState.selectedInterval,
    selectedCurrency: locationState.selectedCurrency,
    promoCode: locationState.promoCode,
    trialdays: locationState.trialdays
  }), [locationState]);

  // Memoize password toggle handler
  const handleTogglePassword = useCallback(() => {
    setShowPassword(prev => !prev);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    if (!agreeTerms) {
      setError('You must agree to the Terms and Conditions to sign up.');
      setIsLoading(false);
      return;
    }

    // Password validation
    const passwordRegex = /^.{8,}$/;
    if (!passwordRegex.test(userData.password)) {
      setError('Password must be at least 8 characters long.');
      setIsLoading(false);
      return;
    }
    
    try {
      await signUp({ 
        ...userData, 
        name: '', // Send empty string for name
        selectedTier: locationState.selectedTier, 
        selectedInterval: locationState.selectedInterval, 
        selectedCurrency: locationState.selectedCurrency,
        chatState: locationState.chatState,
        promoCode: locationState.promoCode,
        trialdays: locationState.trialdays
      });
      setSuccess('Verification email sent! Please check your email to complete the registration.');
    } catch (error) {
      console.error('Registration error:', error.response?.data || error.message);
      setError(error.response?.data?.error || 'Registration failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleContinue = (e) => {
    e.preventDefault();
    if (userData.email) {
      setShowFullForm(true);
    } else {
      setError('Please enter your email address.');
    }
  };

  return (
    <AuthLayout title="Create Account">
      <form onSubmit={showFullForm ? handleSubmit : handleContinue}>
        <FormError message={error} />
        <FormSuccess message={success} />
        
        {!showFullForm ? (
          <FormInput
            key="email-input"
            label="Email Address"
            type="email"
            id="email"
            value={userData.email}
            onChange={handleChange}
            disabled={isLoading}
            required
            placeholder="Enter Your Email Address"
          />
        ) : (
          <>
            <div className="form-group email-display-container">
              <p className="email-display">{userData.email}</p>
              <button 
                type="button" 
                className="back-to-email"
                onClick={() => setShowFullForm(false)}
              >
                Change
              </button>
            </div>
            <FormInput
              key="password-input"
              label="Password"
              type="password"
              id="password"
              value={userData.password}
              onChange={handleChange}
              ref={passwordRef}
              showPassword={showPassword}
              togglePassword={handleTogglePassword}
              disabled={isLoading}
              required
              placeholder="At least 8 characters"
            />

            <div className="form-group checkbox-group">
              <input
                type="checkbox"
                id="agreeTerms"
                checked={agreeTerms}
                onChange={handleTermsChange}
              />
              <label htmlFor="agreeTerms">
                I agree to the <a href="//cognora.ca/terms-of-service" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a href="//cognora.ca/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              </label>
            </div>
          </>
        )}

        <SubmitButton isLoading={isLoading} disabled={showFullForm && !agreeTerms}>
          {showFullForm ? (isLoading ? 'Signing Up...' : 'Sign Up') : 'Continue'}
        </SubmitButton>
      </form>

      <p className="auth-link">
        Already have an account?{" "}
        <Link to="/login" state={loginLinkState}>
          Sign In
        </Link>
      </p>
    </AuthLayout>
  );
};

export default SignUp;
