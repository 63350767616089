import { FolderAttachmentIcon, Image01Icon, Pdf01Icon, Cancel01Icon, SparklesIcon } from "hugeicons-react";
import { Link } from 'react-router-dom';
import { ACCEPTED_FILE_TYPES } from '../Data/AcceptedFileTypes';
import '../../Styles/Layout/InputArea.css'
const CircularLoading = () => {
    return (
      <div className="mini-loading">
        <div className="mini-loading-circle"></div>
      </div>
    );
  };

export const InputArea = ({
    user,
    textFiles,
    imageFiles,
    removeImageFile,
    removeTextFile,
    MemoizedSubmitButton,
    MemoizedAutoCompleteDropdown,
    showAutoComplete,
    ModeSelector,
    handleSubmit,
    MemoizedTextarea,
    handleAttachClick,
    handleFileChange,
    fileInputRef,
    remainingChats,
    windowWidth,
    isChat = false
}) => {
  return (
    <form className={`input-wrapper ${isChat ? 'chat' : 'empty'} ${user ? '' : 'nouser'}`} onSubmit={handleSubmit}>
      {(textFiles?.length > 0 || imageFiles?.length > 0) ? (
        <div className="attached-files-section">
          {[...textFiles, ...imageFiles].map(file => (
            <div key={file.id} className="file-card">
              {file.isLoaded === undefined ? (
                <Image01Icon className="file-icon" />
              ) : (
                !file.isLoaded ? <CircularLoading /> : <Pdf01Icon className="file-icon" />
              )}
              <span className="file-name">
                {file.file.name.length > 20 ? `${file.file.name.substring(0, 17)}...` : file.file.name}
              </span>
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  file.isLoaded === undefined ? removeImageFile(file.id) : removeTextFile(file.id);
                }} 
                className="remove-file"
                aria-label="Remove File"
              >
                <Cancel01Icon />
              </button>
            </div>
          ))}
        </div>
      ) : null}

      <MemoizedTextarea />
        
      {showAutoComplete && (
        <MemoizedAutoCompleteDropdown />
      )}

      <div className="input-button-wrapper">
        <div className="input-button-wrapper-left">
          <button
            type="button"
            className="attach-button"
            onClick={handleAttachClick}
            aria-label="Attach files"
            title="Attach files"
            disabled={!user}
          >
            <FolderAttachmentIcon className="button-wrapper-icon" size={24} />
            <span className="hover-text top">
              {user ? 'Upload up to 3 files (PDFs, Images, Documents)' : 'Sign in to Upload Files'}
            </span>
          </button>
        
          <input
            type="file"
            accept={Object.keys(ACCEPTED_FILE_TYPES).join(',')}
            multiple
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
            id="file-upload"
          />

          <ModeSelector isChat={true} />
          {/* <div 
            className={`think-harder-option ${isThinkHarderActive ? 'active' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              setIsThinkHarderActive(!isThinkHarderActive);
            }}
            aria-label="Switch to Think Harder mode"
          >
            <SparklesIcon className={`${isThinkHarderActive ? 'sparkles-active' : ''}`} size={16} />
            {window.innerWidth > 768 ? 'Think Longer' : 'Think'}
            <span className='hover-text top'>
              Enhanced reasoning mode
            </span>
          </div> */}
        </div>

        <div className="input-button-wrapper-right">
          {user && remainingChats === 0 && windowWidth > 600 && (
            <div className="upgrade-prompt">
              <Link to="/pricing" className="upgra">
                <SparklesIcon size={16} />
                <span>
                  <span>No Messages Left. </span>
                  <span className="upgrade-text">Upgrade Plan</span>
                </span>
              </Link>
            </div>
          )}
          <MemoizedSubmitButton />
        </div>
      </div>
    </form>
  );
};