import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { SidebarRight01Icon, SidebarLeft01Icon, Edit02Icon, NewReleasesIcon, DashboardCircleIcon, Delete02Icon, NoteIcon, Delete01Icon, UserAdd01Icon, ArrowLeft01Icon, PencilEdit02Icon } from 'hugeicons-react';
import { useAuth } from '../../hooks/useAuth';
import '../../Styles/Sidebar.css';
import { fetchChats, removeChat, renameChat, removeChats } from '../../services/api';
import { useQuery, useQueryClient } from 'react-query';

const LoadingChats = () => (
  <div className="loading-container">
    {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
      <div key={i} className="loading-skeleton" />
    ))}
  </div>
);

const Sidebar = React.memo(({
  isOpen,
  toggleSidebar,
  showCustomToast,
  handleAddChat,
  onMouseEnter,
  onMouseLeave,
  isHoverOpen
}) => {
  const { user } = useAuth();
  const [editingChatId, setEditingChatId] = useState(null);
  const [editingChatTitle, setEditingChatTitle] = useState('');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isUpgradeClicked, setIsUpgradeClicked] = useState(false);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [selectedChats, setSelectedChats] = useState(new Set());
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const { data: chatsData, isLoading: isChatsLoading } = useQuery(
    'chats',
    fetchChats,
    {
      enabled: !!user,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
      refetchOnWindowFocus: true,
      onError: (error) => console.error('Error fetching chats data:', error),
      select: (data) => ({
        ...data,
        chats: data.chats.filter(chat => !chat.spaceId)
      })
    }
  );

  const chats = useMemo(() => chatsData?.chats || [], [chatsData?.chats]);

  const sortedChats = useMemo(() => {
    return [...chats].sort((a, b) => new Date(b.last_modified) - new Date(a.last_modified));
  }, [chats]);

  const categorizedChats = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);
    const lastMonth = new Date(today);
    lastMonth.setDate(lastMonth.getDate() - 30);

    return sortedChats.reduce((acc, chat) => {
      const chatDate = new Date(chat.last_modified);
      if (chatDate >= today) {
        acc.today.push(chat);
      } else if (chatDate >= yesterday) {
        acc.yesterday.push(chat);
      } else if (chatDate >= lastWeek) {
        acc.lastWeek.push(chat);
      } else if (chatDate >= lastMonth) {
        acc.lastMonth.push(chat);
      } else {
        acc.older.push(chat);
      }
      return acc;
    }, { today: [], yesterday: [], lastWeek: [], lastMonth: [], older: [] });
  }, [sortedChats]);

  const handleRemoveChat = useCallback(async (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const currentIndex = sortedChats.findIndex(chat => chat.id === id);
      const nextChatId = currentIndex < sortedChats.length - 1 ? sortedChats[currentIndex + 1].id :
                         currentIndex > 0 ? sortedChats[currentIndex - 1].id : 'new';

      await removeChat(id);
      queryClient.invalidateQueries('chats');
      queryClient.invalidateQueries(['messages', id]);
      localStorage.removeItem(`chat_${id}`);

      showCustomToast('success', 'Chat deleted successfully');

      navigate(`/${nextChatId}`, {
        replace: true,
        state: { keepSidebarOpen: true }
      });
    } catch (error) {
      console.error('Error removing chat:', error);
    }
  }, [sortedChats, queryClient, navigate, showCustomToast]);

  const handleRenameChat = useCallback(async (id) => {
    try {
      await renameChat(id, editingChatTitle);
      queryClient.invalidateQueries('chats');
      setEditingChatId(null);
      setEditingChatTitle('');
    } catch (error) {
      console.error('Error renaming chat:', error);
    }
  }, [queryClient, editingChatTitle, setEditingChatId, setEditingChatTitle]);

  const handleSelectChat = useCallback((chatId, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedChats(prev => {
      const newSet = new Set(prev);
      if (newSet.has(chatId)) {
        newSet.delete(chatId);
      } else {
        newSet.add(chatId);
      }
      return newSet;
    });
  }, [setSelectedChats]);

  const handleMassDelete = async () => {
    try {
      const chatIdsArray = Array.from(selectedChats);
      await removeChats(chatIdsArray);
      
      queryClient.invalidateQueries('chats');
      chatIdsArray.forEach(id => {
        queryClient.invalidateQueries(['messages', id]);
      });
      
      showCustomToast('success', 'Chats deleted successfully');
      
      const currentPath = window.location.pathname;
      const currentChatId = currentPath.split('/').pop();
      if (chatIdsArray.includes(currentChatId)) {
        navigate('/new', { replace: true });
      }
      
      setSelectedChats(new Set());
      setIsSelectionMode(false);
    } catch (error) {
      console.error('Error deleting multiple chats:', error);
      showCustomToast('error', 'Failed to delete chats');
    }
  };

  const handleStudySpacesClick = (e) => {
    if ((user?.tier !== 'ultimate') && (user?.tier !== 'pro') && (user?.tier !== 'beta')) {
      e.preventDefault();
      navigate('/pricing#study-spaces');
      return;
    }

    localStorage.setItem('hasSeenStudySpaces', 'true');
    navigate('/study-spaces');
  };

  const handleNavLinkClick = useCallback((e, chatId) => {
    if (isSelectionMode) {
      e.preventDefault();
      handleSelectChat(chatId, e);
      return;
    }
    if (windowWidth <= 668 && !isSelectionMode) {
      toggleSidebar();
    }
  }, [isSelectionMode, windowWidth, toggleSidebar, handleSelectChat]);

  const renderChatList = useCallback((chats, title) => {
    if (chats.length === 0) return null;
    return (
      <>
        <h4 className="chat-section-title">{title}</h4>
        <div className="chat-section">
          <ul>
            {chats.map((chat) => (
              <li key={chat.id} className={isSelectionMode ? 'selection-mode' : ''}>
                {editingChatId === chat.id ? (
                  <form onSubmit={(e) => { 
                    e.preventDefault(); 
                    const trimmedTitle = editingChatTitle.trim();
                    if (trimmedTitle && trimmedTitle !== chat.title) {
                      handleRenameChat(chat.id);
                    } else {
                      setEditingChatId(null);
                      setEditingChatTitle('');
                    }
                  }}>
                    <input
                      value={editingChatTitle}
                      onChange={(e) => setEditingChatTitle(e.target.value)}
                      onBlur={() => {
                        const trimmedTitle = editingChatTitle.trim();
                        if (trimmedTitle && trimmedTitle !== chat.title) {
                          handleRenameChat(chat.id);
                        } else {
                          setEditingChatId(null);
                          setEditingChatTitle('');
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Escape') {
                          setEditingChatId(null);
                          setEditingChatTitle('');
                        }
                      }}
                      autoFocus
                      placeholder="Enter chat name"
                    />
                  </form>
                ) : (
                  <NavLink 
                    to={`/${chat.id}`} 
                    onClick={(e) => handleNavLinkClick(e, chat.id)}
                  >
                    {isSelectionMode && (
                      <input
                        type="checkbox"
                        checked={selectedChats.has(chat.id)}
                        onChange={(e) => handleSelectChat(chat.id, e)}
                        onClick={(e) => e.stopPropagation()}
                        className="chat-checkbox"
                      />
                    )}
                    <span className="chat-title">
                      {chat.title || 'Untitled Chat'}
                    </span>
                    {!isSelectionMode && (
                      <div className="button-group">
                        <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); setEditingChatId(chat.id); setEditingChatTitle(chat.title); }} className="edit-btn" aria-label={`Edit ${chat.title}`}>
                          <Edit02Icon size={14} style={{ marginRight: '-7px' }} />
                          <span className="hover-text bottom-group">Edit Chat</span>
                        </button>
                        <button onClick={(e) => handleRemoveChat(chat.id, e)} className="remove-btn" aria-label={`Remove ${chat.title}`}>
                          <Delete02Icon size={14} />
                          <span className="hover-text bottom-group">Delete</span>
                        </button>
                      </div>
                    )}
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  }, [isSelectionMode, editingChatId, handleNavLinkClick, handleRenameChat, handleRemoveChat, handleSelectChat, editingChatTitle, selectedChats]);

  useEffect(() => {
    if (isOpen) {
      const currentPath = window.location.pathname;
      const currentChatId = currentPath.split('/').pop();
      
      if (currentChatId === 'new') return;

      const activeChatElement = document.querySelector(`a[href='/${currentChatId}']`);
      if (activeChatElement) {
        activeChatElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [isOpen, chats]);

  const renderStudySpacesButton = () => {
    return (
      <button 
        onClick={handleStudySpacesClick} 
        className={`sidebar-feature`}
        aria-label="Study Spaces"
      >
        <div className="rounded-icon">
          <DashboardCircleIcon size={13} />
        </div>
        <span className="button-text">
          Study Spaces
        </span>
      </button>
    );
  };

  // eslint-disable-next-line no-unused-vars
  const renderStudyPadButton = () => {
    return (
      <button 
        className={`sidebar-feature`}
        aria-label="Study Pad"
      >
        <div className="rounded-icon">
          <NoteIcon size={13} />
        </div>
        <span className="button-text">
          Study Pad
        </span>
      </button>
    );
  };

  // Memoize the sidebar class calculation
  const sidebarClassName = useMemo(() => {
    return `sidebar ${(isOpen || isHoverOpen) ? 'open' : 'collapsed'} ${isHoverOpen && windowWidth > 668 ? 'hover-open' : ''}`;
  }, [isOpen, isHoverOpen, windowWidth]);

  return (
    <aside 
      className={sidebarClassName}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {user ? (
        <>
          <div className="sidebar-header">

            <button 
              className={`sd-menu-button ${isOpen ? 'open' : 'collapsed'}`} 
              onClick={toggleSidebar}
              aria-label="Toggle sidebar"
            >
              {!isOpen ? <SidebarRight01Icon size={22} /> : <SidebarLeft01Icon size={22} />}
              <span className="hover-text toggle">
                {windowWidth >= 668 
                  ? (isOpen ? 'Unpin Sidebar' : 'Pin Sidebar') 
                  : (isOpen ? 'Collapse Sidebar' : 'Expand Sidebar')}
              </span>
            </button>

            <button 
              className="header-button hoverable" 
              onClick={handleAddChat}
              aria-label="Start new chat"
            >
              < PencilEdit02Icon size={22} />
              <span className="hover-text">New Chat</span>
            </button>
          </div>
          

          {(isOpen || isHoverOpen) && (
            <>
              <nav className={`sidebar-nav ${isHoverOpen ? 'hover-open' : ''}`}>
                <div className='sidebar-features'>
                {renderStudySpacesButton()}
                {/* {renderStudyPadButton()} */}
                </div>
                {chats.length > 6 && (
                  <div className="nav-controls">
                    <div className="select-toggle">
                      <label className="toggle-label">
                        <span className="toggle-text">
                          {isSelectionMode ? "Selecting" : "Select"}
                        </span>
                        <input
                          type="checkbox"
                          checked={isSelectionMode}
                          onChange={() => {
                            setIsSelectionMode(!isSelectionMode);
                            if (isSelectionMode) {
                              setSelectedChats(new Set());
                            }
                          }}
                        />
                        <span className="toggle-slider"></span>
                        <span className="hover-text bottom">Select Multiple Chats</span>
                      </label>
                      {isSelectionMode && selectedChats.size > 0 && (
                        <button 
                          onClick={handleMassDelete}
                          className="minimal-delete-btn"
                          aria-label="Delete Selected Chats"
                        >
                          <Delete01Icon size={14} />
                          <span>{selectedChats.size}</span>
                          <span className="hover-text bottom">Delete Selected Chats</span>
                        </button>
                      )}
                    </div>
                  </div>
                )}

                <div className="scrollable-section">
                  {isChatsLoading ? (
                    <LoadingChats />
                  ) : (
                    <>
                      {chats.length > 0 ? (
                        <>
                          {renderChatList(categorizedChats.today, "Today")}
                          {renderChatList(categorizedChats.yesterday, "Yesterday")}
                          {renderChatList(categorizedChats.lastWeek, "Last 7 Days")}
                          {renderChatList(categorizedChats.lastMonth, "Last 30 Days")}
                          {renderChatList(categorizedChats.older, "Older")}
                        </>
                      ) : (
                        <div className="no-chats-container">
                          <p>No Chats Available</p>
                          <button 
                            onClick={() => queryClient.invalidateQueries('chats')}
                            className="refresh-chats-btn"
                            aria-label="Refresh Chats"
                          >
                            Refresh Chats
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </nav>

              {(user.tier === 'free' || user.tier === 'premium') && (
            <div className='sidebar-upgrade'>
              <button 
                onClick={() => {
                  if (!isUpgradeClicked) {
                    setIsUpgradeClicked(true);
                    navigate('/pricing');
                  }
                }}
                className={`upgrade-but ${isUpgradeClicked ? 'clicked' : ''}`}
                disabled={isUpgradeClicked}
                aria-label="Upgrade Plan"
              >
                <NewReleasesIcon size={22} style={{ marginRight: '8px' }} />
                Upgrade Plan
              </button>
            </div>
          )}

              {/* Logout Button 
              <button onClick={handleLogout} className="logout-btn" aria-label="Logout">
                <HiLogout size={16} /> Logout
                
                <span className="hover-text top">Sign Out of Account</span>
              </button> */}
            </>
          )}
        </>
      ) : (
        <>
          <div className="sidebar-header">
            <div className="user-avatar empty" aria-label="User avatar">
              {/* No avatar for guests */}
            </div>
            <span className="user-email">Welcome, Guest</span>
            <button className="collapse-btn" onClick={toggleSidebar} aria-label="Collapse Sidebar">
              <ArrowLeft01Icon size={24} />
              <span className="hover-text right">Collapse Sidebar</span>
            </button>
          </div>
          {(isOpen || isHoverOpen) && (
            <>
              <div className="sidebar-prompt">
                <span>Please sign in to use StudyBuddy</span>
                <Link to="/login" className="login-btn" aria-label="Sign In">
                  <UserAdd01Icon size={16} /> Sign In
                  <span className="hover-text right">Login to Your Account</span>
                </Link>
              </div>
            </>
          )}
        </>
      )}
    </aside>
  );
}, (prevProps, nextProps) => {
  // More specific comparison for when to re-render
  return (
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.isHoverOpen === nextProps.isHoverOpen &&
    prevProps.showSalesModal === nextProps.showSalesModal &&
    prevProps.handleAddChat === nextProps.handleAddChat &&
    prevProps.onMouseEnter === nextProps.onMouseEnter &&
    prevProps.onMouseLeave === nextProps.onMouseLeave
  );
});

export default Sidebar;
