import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CognoraLogo from '../../assets/Cognore Inverse Logo.png';
import { HelpCircleIcon, Message01Icon } from 'hugeicons-react';

const NavBar = ({ showLogo = true }) => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleNav = () => setIsNavOpen(!isNavOpen);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div>
            <nav className="top-nav">
                {showLogo && (
                    <Link to="/">
                        <img src={CognoraLogo} alt="Cognora Logo" className="navlogo" />
                    </Link>
                )}
                <div className={`nav-links ${isNavOpen ? 'open' : ''}`}>
                <Link to="/new" className="nav-link" onClick={toggleNav}>
                        <Message01Icon /> Chat
                    </Link>
                    {/* <Link to="/features" className="nav-link" onClick={toggleNav}>
                        <FaInfoCircle /> How It Works
                    </Link> */}
                    <Link to="https://cognora.ca/faq" className="nav-link" onClick={toggleNav}>
                        <HelpCircleIcon /> Help
                    </Link>
                    {/* {isAuthenticated ? (
                        <div className="settings-dropdown" ref={dropdownRef}>
                            <button onClick={toggleDropdown} className="settings-button">
                                <FaCog /> Settings
                            </button>
                            {isDropdownOpen && (
                                <div className="dropdown-menu">
                                    <Link to="/settings" className="dropdown-item">Profile</Link>
                                    <button onClick={logout} className="dropdown-item logout-button">
                                        <FaSignOutAlt /> Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Link to="/login" className="sign-in-button">Sign In</Link>
                    )} */}
                </div>
            </nav>


        </div>
    );
};

export default NavBar;