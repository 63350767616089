import React, { useState, useEffect, useMemo } from 'react';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import '../../Styles/Resources.css';
import { useAuth } from '../../hooks/useAuth';
import { useLocation, useSearchParams } from 'react-router-dom';
import CustomSelect from '../Layout/CustomSelect';
import { AiUserIcon, Add01Icon, Cancel01Icon} from 'hugeicons-react';

// Add this memoized component near the top of the file, before the ResourcesSection component
const MemoizedCustomSelect = React.memo(CustomSelect, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value && 
         JSON.stringify(prevProps.options) === JSON.stringify(nextProps.options);
});

const ResourcesSection = ({ isVisible, expandedResource, setExpandedResource, renderExpanded, ExpandedResource, onClose, projectTitle, isExpanded, setIsExpanded, resources, pdfContent, onResourceClick, isLoadingResource, showCustomToast, getResourceIcon, handleImageClick }) => {
  const [defaultchatmode, setDefaultchatmode] = useState(() => localStorage.getItem('defaultChatMode') || 'qa');
  const { memoryArray, updateMemory } = useAuth();
  const [localMemoryArray, setLocalMemoryArray] = useState([]);
  const [originalMemoryArray, setOriginalMemoryArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedMemoryIndex, setExpandedMemoryIndex] = useState(null);
  const [isPersonalizeOpen, setIsPersonalizeOpen] = useState(false);

  const MAX_TITLE_LENGTH = 30; // Define the maximum title length

  const truncateTitle = (title) => {
    if (title.length <= MAX_TITLE_LENGTH) return title;
    return title.substring(0, MAX_TITLE_LENGTH) + '...';
  };

  const getResourceVersions = (resourceList) => {
    const versions = {};
    resourceList.forEach(resource => {
      if (!versions[resource.title]) {
        versions[resource.title] = [];
      }
      versions[resource.title].push(resource);
    });
    return versions;
  };

  const [materialResources, setMaterialResources] = useState([]);
  const [otherResources, setOtherResources] = useState([]);

  const location = useLocation();

  // Add searchParams
  const [searchParams, setSearchParams] = useSearchParams();

  // Update this useEffect
  useEffect(() => {
    if (location.pathname === '/new') {
      setExpandedResource(null);
      setIsExpanded(false);
      setMaterialResources([]);
      setOtherResources([]);
    }
  }, [location, setExpandedResource, setIsExpanded]);

  // Update this useEffect to handle resources properly
  useEffect(() => {
    if (resources) {
      const materials = resources.filter(resource => 
        resource.type === 'file' || resource.type === 'image'
      );
      const otherMaterials = resources.filter(resource => 
        resource.type !== 'file' && 
        resource.type !== 'image'
      );
      setMaterialResources(materials);
      setOtherResources(otherMaterials); // Remove the array wrapping
    }
  }, [resources]);

  const resourceVersions = useMemo(() => getResourceVersions(otherResources), [otherResources]);

  useEffect(() => {
    // Add a small delay before setting isLoading to false to ensure smooth transition
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  const handleDefaultChatModeChange = React.useCallback((newValue) => {
    setDefaultchatmode(newValue);
    localStorage.setItem('defaultChatMode', newValue);
  }, []);

  useEffect(() => {
    if (memoryArray) {
      setLocalMemoryArray(memoryArray);
      setOriginalMemoryArray(memoryArray);
    } else {
      setLocalMemoryArray([]);
      setOriginalMemoryArray([]);
    }
  }, [memoryArray]);

  const handleMemoryChange = (index, value) => {
    const updatedMemory = [...localMemoryArray];
    updatedMemory[index] = value;
    setLocalMemoryArray(updatedMemory);
  };

  const handleMemorySubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await updateMemory(localMemoryArray);
      showCustomToast('success', 'Memory updated successfully');
    } catch (error) {
      showCustomToast('error', 'Failed to update memory: ' + (error.response?.data?.error || error.message));
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddMemory = () => {
    setLocalMemoryArray([...localMemoryArray, '']);
  };

  const handleRemoveMemory = (index) => {
    setLocalMemoryArray(localMemoryArray.filter((_, i) => i !== index));
  };



  const hasChanges = useMemo(() => {
    if (!localMemoryArray || !originalMemoryArray) return false;
    if (localMemoryArray.length !== originalMemoryArray.length) return true;
    return JSON.stringify(localMemoryArray) !== JSON.stringify(originalMemoryArray);
  }, [localMemoryArray, originalMemoryArray]);

  const handleResourceClick = (resourceTitle, url, title) => {
    if(url && url!= null){
      handleImageClick(url, title);
      return;
    } 

    setIsExpanded(!isExpanded);
    
    const allResources = [...materialResources, ...otherResources];
    const foundResources = allResources.filter(resource => resource.title === resourceTitle);
    
    if (foundResources.length > 0) {
      // Sort resources by version, descending
      const sortedResources = foundResources.sort((a, b) => b.version - a.version);
      const latestResource = sortedResources[0];

      // Pass both the resource and its version number
      setExpandedResource({
        ...latestResource,
        desiredVersion: foundResources.length
      });



      // Add query params
      setSearchParams({
        ...Object.fromEntries(searchParams),
        resource: resourceTitle,
        version: latestResource.version?.toString() || '1'
      });
    }
  };

  const togglePersonalize = () => {
    setIsPersonalizeOpen(!isPersonalizeOpen);
  };

  // Add this state near the other useState declarations at the top
  const [isEmpty, setIsEmpty] = useState(true);

  // Add this useEffect to track empty state
  useEffect(() => {
    setIsEmpty(Object.keys(resourceVersions).length === 0);
  }, [resourceVersions]);

  // Add this near your other useMemo hooks
  const selectOptions = useMemo(() => [
    { value: 'qa', label: 'Q&A' },
    { value: 'tutor', label: 'Tutor' },
    { value: 'brainstorm', label: 'Brainstorm' },
    { value: 'debate', label: 'Debate' }
  ], []);

  return (
    <AnimatePresence mode="wait">
      {isVisible && (
        <motion.div
          className={`resources-section ${isExpanded ? 'expanded' : ''}`}
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        >
          {expandedResource && isExpanded ? (
            renderExpanded()
          ) : (
            <>
              <div className="resources-header">
                <h3>Study Panel</h3>
                <button className="close-button" onClick={onClose}><Cancel01Icon size={16} /></button>
              </div>
              <div className="resources-content">
                {isLoading ? (
                  <div className="loading">Loading...</div>
                ) : (
                  <>
                    {isLoadingResource ? (
                      <motion.div 
                        className="resource-loading-indicator"
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.95 }}
                        transition={{ duration: 0.4 }}
                      >
                        <div className="loading-spinner"></div>
                        <h3>Writing Resource</h3>
                      </motion.div>
                    ) : (
                      <>
                        {/* Other Resources Section */}
                        <h2 className="section-title">Resources</h2>
                        <section className={`other-resources-list ${isEmpty ? 'empty' : 'notempty'}`}>
                          {isEmpty ? (
                            <div className="empty-resources-message">
                              <p>
                                <strong>No resources available.</strong>
                                Use StudyBuddy to generate one!
                              </p>
                            </div>
                          ) : (
                            Object.entries(resourceVersions).map(([title, versions]) => {
                              const latestVersion = versions.reduce((latest, current) => 
                                current.version > latest.version ? current : latest
                              );
                              return (
                                <div 
                                  key={latestVersion.id || title} 
                                  className={`resource-item ${latestVersion.type || 'general'}`}
                                  onClick={() => handleResourceClick(title)}
                                >
                                  <span className="resource-icon">
                                    {getResourceIcon(latestVersion.type)}
                                  </span>
                                  <div className="resource-info">
                                    <h3 className="resource-title">{truncateTitle(title)}</h3>
                                  </div>
                                  <FaChevronRight className="resource-arrow" />
                                </div>
                              );
                            })
                          )}
                        </section>
                      </>
                    )}

                    {/* PDF Resources Section */}
                    <h2 className="section-title materials">Study Materials</h2>
                    <section className={`materials-list study-materials-section ${materialResources.length === 0 ? 'empty' : 'notempty'}`}>
                      {materialResources.length === 0 ? (
                        <div className="empty-resources-message">
                          <p>
                            <strong>No Materials Available.</strong>
                            Upload Files to analyze their content!
                          </p>
                        </div>
                      ) : (
                        materialResources.map((resource, index) => (
                          <div 
                            key={resource.id || index} 
                            className={`resource-item ${resource.type || 'general'}`}
                            onClick={() => handleResourceClick(resource.title, resource.type === 'image' ? resource.url : null, resource.title)}
                          >
                            <span className="resource-icon">
                              {getResourceIcon(resource.type)}
                            </span>
                            <div className="resource-info">
                              <h3 className="resource-title">{truncateTitle(resource.title)}</h3>
                            </div>
                            <FaChevronRight className="resource-arrow" />
                          </div>
                        ))
                      )}
                    </section>
                    
                    <div className="form-section">
                          <div className="form-group">
                            <label htmlFor="defaultChatMode">Default Chat Mode</label>
                            <MemoizedCustomSelect
                              options={selectOptions}
                              value={defaultchatmode}
                              onChange={handleDefaultChatModeChange}
                            />
                          
                        </div>
                    </div>

                    <div className="memory-group">
                      <div className="memory-header" onClick={togglePersonalize}>
                        <h2><AiUserIcon /> Personalize StudyBuddy</h2>
                        <FaChevronDown className={`memory-toggle ${isPersonalizeOpen ? 'open' : ''}`} />
                      </div>
                      <div className={`memory-content ${isPersonalizeOpen ? 'open' : ''}`}>
                        <p className="memory-description">
                          Add personal details, preferences, or study goals to tailor your StudyBuddy's responses. 
                        </p>
                        <form onSubmit={handleMemorySubmit}>
                          <div className="memory-list">
                            {Array.isArray(localMemoryArray) && localMemoryArray.length === 0 && (
                              <div className="empty-memory-message">
                                No personalization added yet. Click the '+' button to get started!
                              </div>
                            )}
                            {Array.isArray(localMemoryArray) && localMemoryArray.map((memory, index) => (
                              <div key={index} className="memory-item">
                                {expandedMemoryIndex === index ? (
                                  <textarea
                                    value={memory}
                                    onChange={(e) => handleMemoryChange(index, e.target.value)}
                                    onBlur={() => setExpandedMemoryIndex(null)}
                                    autoFocus
                                    placeholder="E.g., 'I'm a high school student focusing on AP Biology and Chemistry.'"
                                    style={{
                                      width: '100%',
                                      minHeight: '60px',
                                      maxHeight: '200px',
                                      overflowY: 'auto',
                                      resize: 'vertical'
                                    }}
                                  />
                                ) : (
                                  <div 
                                    className="memory-preview" 
                                    onClick={() => setExpandedMemoryIndex(index)}
                                  >
                                    {memory.substring(0, 30)}{memory.length > 30 ? '...' : ''}
                                  </div>
                                )}
                                <button type="button" className="memory-button" onClick={() => handleRemoveMemory(index)}>
                                  <Cancel01Icon size={16} />
                                </button>
                              </div>
                            ))}
                          </div>
                          <div className="memory-actions">
                            <button 
                              type="submit" 
                              className="save-button" 
                              disabled={!hasChanges} 
                              style={{ 
                                opacity: hasChanges ? 1 : 0,
                                pointerEvents: hasChanges ? 'auto' : 'none'
                              }}
                            >
                              Save
                            </button>
                            <button type="button" className="memory-add-button" onClick={handleAddMemory}>
                              <Add01Icon size={16} />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </>
                )}

              </div>
            </>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ResourcesSection;
