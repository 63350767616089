export const pricingTiers = [
    { 
        name: 'Premium', 
        tagline: 'Essential Learning Tools',
        highlight: false,
        price: { 
            usd: { monthly: '$5.59', annual: '$49.99' },
            cad: { monthly: '$6.99', annual: '$59.99' },
            eur: { monthly: '€5.59', annual: '€49.99' }
        },
        features: [
            'Access to core AI capabilities',
            'Unlimited AI interactions',
            'PDF Upload Capabilities',
        ]
    },
    { 
        name: 'Ultimate', 
        tagline: 'Complete Learning Suite',
        highlight: true,
        freeTrial: '7 days',
        price: { 
            usd: { monthly: '$18.99', annual: '$159.99' },
            cad: { monthly: '$21.99', annual: '$189.99' },
            eur: { monthly: '€18.99', annual: '€159.99' }
        },
        features: [
            'Everything in Premium, plus:',
            'Access to beta features',
            'Up to 7 File uploads per chat',
            'Advanced Reasoning',
            'Access to Study Spaces',
        ]
    }
];
