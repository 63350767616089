import { marked } from 'marked';
import hljs from 'highlight.js';
import { encode } from 'gpt-tokenizer/encoding/o200k_base';

export const getRelativeTime = (date) => {
  const now = new Date();
  const chatDate = new Date(date);
  
  // Handle invalid dates
  if (isNaN(chatDate.getTime())) {
    return 'Unknown date';
  }

  // Reset hours to compare just dates
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const dateToCompare = new Date(chatDate.getFullYear(), chatDate.getMonth(), chatDate.getDate());
  
  const diffTime = today.getTime() - dateToCompare.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  // Handle future dates
  if (diffDays < 0) {
    return chatDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  }
  
  // Handle relative times
  switch (true) {
    case diffDays === 0:
      return 'Today';
    case diffDays === 1:
      return 'Yesterday';
    case diffDays < 7:
      return `${diffDays} days ago`;
    case diffDays < 30:
      const weeks = Math.floor(diffDays / 7);
      return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
    default:
      return chatDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
  }
};

export const copyRichText = async (text) => {
  const cleanMarkdown = text
    .split('<|endofinput|>')[0]
    .replace(/<artifacts>[\s\S]*?<\/artifacts>/g, '')
    .replace(/\[Generation stopped\]/g, '')
    .trim();

  // Create temporary container for HTML conversion
  const tempDiv = document.createElement('div');
  
  // Convert markdown to HTML
  tempDiv.innerHTML = marked.parse(cleanMarkdown, {
    gfm: true,
    breaks: true,
    smartLists: true,
    highlight: function(code, lang) {
      try {
        return hljs.highlight(code, { language: lang || 'text' }).value;
      } catch (err) {
        return code;
      }
    }
  });

  // Style the HTML content
  const styledHTML = `
    <div style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial, sans-serif;">
      ${tempDiv.innerHTML}
    </div>
  `.replace(/<pre>/g, '<pre style="background-color: #f6f8fa; padding: 16px; border-radius: 6px; overflow: auto;">');

  // Create a Blob with HTML content
  const blob = new Blob([styledHTML], { type: 'text/html' });
  
  // Create the clipboard data
  const clipboardData = new ClipboardItem({
    'text/plain': new Blob([cleanMarkdown], { type: 'text/plain' }),
    'text/html': blob
  });

    // Copy to clipboard using the new Clipboard API
    navigator.clipboard.write([clipboardData]).then(() => {
    }).catch(err => {
      // Fallback to basic text copying if rich copy fails
      navigator.clipboard.writeText(cleanMarkdown).then(() => {
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    });
}

export const getTokenCount = (text) => {
  try {
    return encode(text).length;
  } catch (error) {
    // Fallback to word-based estimation
    const words = text.trim().split(/\s+/).length;
    return Math.ceil(words * 1.3);
  }
};

export const parseTextContent = (text) => {
  if (!text) return [];
  
  const resources = [];
  const sections = text.split(/(?=<(?:PDF|DOC) START>)/);

  sections.forEach((section, index) => {
    if (!section.trim()) return;

    const typeMatch = section.match(/<(PDF|DOC) START>/);
    const titleMatch = section.match(/\[(?:PDF|DOC) TITLE:\s*(.*?)\]/);
    const linkMatch = section.match(/\[LINK:\s*(.*?)\]/);

    if (!typeMatch || !titleMatch) return;

    const docType = typeMatch[1].toLowerCase();
    const title = titleMatch[1].trim() || `Untitled ${docType.toUpperCase()} ${index + 1}`;
    const url = linkMatch ? linkMatch[1].trim() : null;

    const contentMatch = section.match(/\]([^[<]*?)(?:<(?:PDF|DOC) END>|$)/s);
    if (!contentMatch) return;

    const content = contentMatch[1].trim();
    if (!content) return;

    const resourceId = `file-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    resources.push({
      id: resourceId,
      title,
      content,
      type: 'file',
      docType,
      url,
      timestamp: new Date().toISOString()
    });
  });

  return resources;
};