//import { copyRichText } from './chatHelper';

// export const downloadMarkdownAsDocx = async (markdownText, filename = 'document.docx') => {
//     // Convert markdown to HTML
//     let RenderedHTML = await copyRichText(markdownText);

//     // Create a new document
//     const doc = new Document({
//         sections: [{
//             properties: {},
//             children: [
//                 new Paragraph({
//                     children: [
//                         new TextRun(RenderedHTML),
//                     ],
//                 }),
//             ],
//         }],
//     });

//     // Create blob
//     const blob = await Packer.toBlob(doc);

//     // Download the blob
//     const url = window.URL.createObjectURL(blob);
//     const a = document.createElement('a');
//     a.href = url;
//     a.download = filename;
//     a.click();
//     window.URL.revokeObjectURL(url);
// };

export const downloadCodeFile = (code, language, filename = 'code') => {
  // Remove any existing file extension from filename
  const baseFilename = filename.split('.')[0];
  
  // Map common language names to file extensions
  const extensionMap = {
    javascript: 'js',
    typescript: 'ts', 
    python: 'py',
    java: 'java',
    cpp: 'cpp',
    'c++': 'cpp',
    csharp: 'cs',
    'c#': 'cs',
    php: 'php',
    ruby: 'rb',
    swift: 'swift',
    go: 'go',
    rust: 'rs',
    html: 'html',
    css: 'css',
    sql: 'sql'
  };

  // Get appropriate extension, default to .txt if language not found
  const extension = extensionMap[language.toLowerCase()] || 'txt';
  
  // Create full filename with extension
  const fullFilename = `${baseFilename}.${extension}`;

  // Create blob with the code content
  const blob = new Blob([code], { type: 'text/plain' });
  
  // Create download link
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fullFilename;
  
  // Trigger download
  a.click();
  
  // Cleanup
  window.URL.revokeObjectURL(url);
};

export const downloadMarkdownFile = (content, title) => {
  // Remove any existing file extension from title
  const baseTitle = title.split('.')[0];
  
  // Create full filename with .md extension
  const fullFilename = `${baseTitle}.md`;

  // Create blob with the markdown content
  const blob = new Blob([content], { type: 'text/markdown' });
  
  // Create download link
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fullFilename;
  
  // Trigger download
  a.click();
  
  // Cleanup
  window.URL.revokeObjectURL(url);
};

