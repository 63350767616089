import React, { useState, useRef, useEffect } from 'react';
import { FaArrowLeft, FaExternalLinkAlt, FaMagic, FaInfoCircle, FaPaperPlane, FaChevronLeft, FaChevronRight, FaExchangeAlt } from 'react-icons/fa';
import { AIResponseRenderer } from './LLMTextRenderer';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import '../../Styles/Expanded.css';
import { toast } from 'react-toastify';
import { Line, Column, Pie } from '@ant-design/plots';
import { Tabs } from 'antd';
import flowchart from 'flowchart.js';
import { useQuery } from 'react-query';
import { downloadMarkdownFile, downloadCodeFile } from '../helpers/artifactHelper';
import {useTheme} from '../../App';
import { CopyButton } from '../Layout/Buttons';
import { DownloadCircle02Icon } from 'hugeicons-react';

const ExpandedResource = ({ 
  resource, 
  onImprove, 
  onExplain, 
  onClose, 
  allVersions,
  desiredVersion,
  showCustomToast,
  isSpaceChat,
  onAddToSpace
}) => {
  const [isCode] = useState(resource.type === 'code');
  const [isfile] = useState(resource.type === 'file');
  const [selectedText, setSelectedText] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [optionsPosition, setOptionsPosition] = useState({ top: 0, left: 0 });
  const [isImproveMode, setIsImproveMode] = useState(false);
  const [improveSuggestion, setImproveSuggestion] = useState('');
  const contentRef = useRef(null);
  const improveInputRef = useRef(null);
  const optionsRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [versions, setVersions] = useState([]);  
  const [showDiff, setShowDiff] = useState(false);

  const [activeTab, setActiveTab] = useState('0');

  const { data: artifactTitles = [] } = useQuery('artifactTitles', () => [], {
    staleTime: Infinity
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isResourceInSpace = () => {
    return artifactTitles.some(artifact => 
      artifact.title === currentResourceVersion.title
    );
  };

  useEffect(() => {
    if (allVersions && allVersions.length > 0) {
      const sortedVersions = [...allVersions].sort((a, b) => a.version - b.version);
      setVersions(sortedVersions);
      
      const lastVersionIndex = sortedVersions.length - 1;

      if (desiredVersion) {
        const indexCandidate = Math.min(desiredVersion - 1, lastVersionIndex);
        setCurrentIndex(Math.max(0, indexCandidate));
      } else if (resource.messageId) {
        const versionIndex = sortedVersions.findIndex(
          version => version.messageId === resource.messageId
        );
        setCurrentIndex(versionIndex !== -1 ? versionIndex : lastVersionIndex);
      } else {
        setCurrentIndex(lastVersionIndex);
      }
    } else {
      setVersions([resource]);
      setCurrentIndex(0);
    }
  }, [allVersions, resource, desiredVersion]);

  useEffect(() => {
    const handleMouseUp = () => {
      const selection = window.getSelection();
      const text = selection.toString().trim();
      
      const isWithinContent = contentRef.current?.contains(selection.anchorNode);
      
      if (!isWithinContent) {
        setShowOptions(false);
        return;
      }

      const isPreviousVersion = selection.anchorNode.parentElement?.closest('.previous-version');
      const isRemovedBlock = selection.anchorNode.parentElement?.closest('.removed-block');

      if (
        text && 
        (resource.type === 'essay' || resource.type === 'code') && 
        !isPreviousVersion &&
        !isRemovedBlock
      ) {
        setSelectedText(text);
        
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        
        const viewportPosition = {
          top: rect.top + window.scrollY,
          left: rect.left + (rect.width / 2) + window.scrollX
        };

        const contentRect = contentRef.current.getBoundingClientRect();

        const newPosition = {
          top: viewportPosition.top - contentRect.top,
          left: Math.min(viewportPosition.left - contentRect.left, contentRect.width - 100)
        };

        setOptionsPosition(newPosition);
        setShowOptions(true);
      } else {
        setShowOptions(false);
      }
    };

    const handleClickOutside = (event) => {
      const isClickInsideContent = contentRef.current?.contains(event.target);
      const isClickInsideOptions = optionsRef.current?.contains(event.target);
      const isClickInsideImproveInput = improveInputRef.current?.contains(event.target);
      
      if (isClickInsideOptions && isImproveMode) {
        return;
      }
      
      if (isClickInsideImproveInput) {
        setShowOptions(true);
        setIsImproveMode(true);
      } else if (isClickInsideOptions) {
        setShowOptions(true);
      } else if (isClickInsideContent && !isImproveMode) {
        setShowOptions(false);
        setImproveSuggestion('');
      } else if (isClickInsideContent && isImproveMode) {
        setIsImproveMode(false);
      } else if (!isClickInsideContent && !isClickInsideOptions && !isClickInsideImproveInput) {
        setShowOptions(false);
        setIsImproveMode(false);
        setImproveSuggestion('');
      }
    };

    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousedown', handleClickOutside);

    if (isImproveMode && improveInputRef.current) {
      improveInputRef.current.focus();
    }

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [resource.type, isImproveMode, showOptions]);

  const toggleVersionControl = (direction) => {
    if (versions.length <= 1) return;

    let newIndex = currentIndex;
    if (direction === 'previous' && currentIndex > 0) {
      newIndex = currentIndex - 1;
    } else if (direction === 'next' && currentIndex < versions.length - 1) {
      newIndex = currentIndex + 1;
    }
    setCurrentIndex(newIndex);
  };

  const currentResourceVersion = versions[currentIndex] || resource;

  const handleImprove = (e) => {
    e.stopPropagation();
    setIsImproveMode(true);
  };

  const handleExplain = (e) => {
    e.stopPropagation();
    const message = isCode
      ? `Please explain this code: \`\`\`${selectedText}\`\`\``
      : `Please explain this text: <quote>${selectedText}</quote>`;
    onExplain(message);
    setShowOptions(false);
  };

  const handleSendImprovement = (e) => {
    e.stopPropagation();
    const message = isCode 
      ? `I want to improve this code: \`\`\`${selectedText}\`\`\` Here are my thoughts on what to improve: ${improveSuggestion}`
      : `I want to improve this text: <quote>${selectedText}</quote> Here are my thoughts on what to improve: ${improveSuggestion}`;
    onImprove(message);
    setShowOptions(false);
    setIsImproveMode(false);
    setImproveSuggestion('');
    toast.info('Improvement request sent!');
  };

  const truncateText = (text) => {
    const maxLength = Math.min(text.length, 90);
    const width = window.innerWidth;
    let finalMaxLength = maxLength;
    
    if (width <= 480) {
      finalMaxLength = Math.floor(maxLength * 0.3);
    } else if (width <= 768) {
      finalMaxLength = Math.floor(maxLength * 0.5);
    } else if (width <= 1008) {
      finalMaxLength = Math.floor(maxLength * 0.6);
    } else if (width <= 1380) {
      finalMaxLength = Math.floor(maxLength * 0.7);
    } else {
      finalMaxLength = maxLength;
    }

    return text.length > finalMaxLength ? text.substring(0, finalMaxLength) + '...' : text;
  };

  const handleImproveSuggestionKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendImprovement(e);
    }
  };

  const computeDiff = () => {
    if (currentIndex === 0 || versions.length <= 1) return null;
    
    const currentContent = versions[currentIndex].content;
    const previousContent = versions[currentIndex - 1].content;
    
    const currentParagraphs = currentContent.split('\n\n');
    const previousParagraphs = previousContent.split('\n\n');
    
    const changes = [];
    let maxLength = Math.max(previousParagraphs.length, currentParagraphs.length);
    
    for (let i = 0; i < maxLength; i++) {
      const prevPara = previousParagraphs[i] || '';
      const currPara = currentParagraphs[i] || '';
      
      if (prevPara !== currPara) {
        changes.push({
          previous: prevPara,
          current: currPara,
          type: prevPara && currPara ? 'modified' : 
                !prevPara ? 'added' : 'removed'
        });
      }
    }
    
    return changes;
  };

  const renderDiffContent = () => {
    const changes = computeDiff();
    if (!changes || changes.length === 0) return null;

    return (
      <div className="diff-content">
        {changes.map((change, index) => (
          <div key={index} className="change-block">
            {change.type === 'modified' && (
              <>
                <div className="previous-version">
                  <div className="change-label">Previous Version:</div>
                  <div className="content removed">
                    <AIResponseRenderer content={change.previous} />
                  </div>
                </div>
                <div className="arrow">↓</div>
                <div className="current-version">
                  <div className="change-label">Current Version:</div>
                  <div className="content added">
                    <AIResponseRenderer content={change.current} />
                  </div>
                </div>
              </>
            )}
            {change.type === 'added' && (
              <div className="added-block">
                <div className="change-label">Added Content:</div>
                <div className="content added">
                  <AIResponseRenderer content={change.current} />
                </div>
              </div>
            )}
            {change.type === 'removed' && (
              <div className="removed-block">
                <div className="change-label">Removed Content:</div>
                <div className="content removed">
                  <AIResponseRenderer content={change.previous} />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const customTheme = {
    'code[class*="language-"]': {
      color: '#d6deeb',
      fontFamily: 'var(--font-mono)',
      direction: 'ltr',
      textAlign: 'left',
      whiteSpace: 'pre',
      wordSpacing: 'normal',
      wordBreak: 'normal',
      lineHeight: '1.5',
      MozTabSize: '4',
      OTabSize: '4',
      tabSize: '4',
      WebkitHyphens: 'none',
      MozHyphens: 'none',
      msHyphens: 'none',
      hyphens: 'none',
    },
    'pre[class*="language-"]': {
      color: '#d6deeb',
      background: '#011627',
      fontFamily: 'var(--font-mono)',
      direction: 'ltr',
      textAlign: 'left',
      whiteSpace: 'pre',
      wordSpacing: 'normal',
      wordBreak: 'normal',
      lineHeight: '1.5',
      MozTabSize: '4',
      OTabSize: '4',
      tabSize: '4',
      WebkitHyphens: 'none',
      MozHyphens: 'none',
      msHyphens: 'none',
      hyphens: 'none',
      padding: '1em',
      margin: '0.5em 0',
      overflow: 'auto',
      borderRadius: '0.3em',
    },
    'comment': { color: '#637777', fontStyle: 'italic' },
    'prolog': { color: '#637777' },
    'doctype': { color: '#637777' },
    'cdata': { color: '#637777' },
    'punctuation': { color: '#c792ea' },
    'property': { color: '#80cbc4' },
    'tag': { color: '#7fdbca' },
    'boolean': { color: '#ff5874' },
    'number': { color: '#f78c6c' },
    'constant': { color: '#82aaff' },
    'symbol': { color: '#82aaff' },
    'deleted': { color: '#ff5874' },
    'selector': { color: '#c792ea' },
    'attr-name': { color: '#7fdbca' },
    'string': { color: '#ecc48d' },
    'char': { color: '#ecc48d' },
    'builtin': { color: '#82aaff' },
    'inserted': { color: '#80cbc4' },
    'operator': { color: '#c792ea' },
    'entity': { color: '#f78c6c' },
    'url': { color: '#66d9ef' },
    'variable': { color: '#addb67' },
    'atrule': { color: '#82aaff' },
    'attr-value': { color: '#c3e88d' },
    'function': { color: '#82aaff' },
    'class-name': { color: '#f78c6c' },
    'keyword': { color: '#c792ea' },
    'regex': { color: '#80cbc4' },
    'important': { color: '#c792ea', fontWeight: 'bold' },
  };

  const parseVisualizationData = (content) => {
    try {
      const data = JSON.parse(content);
      return {
        type: data.type,
        subtype: data.subtype,
        data: data.data,
        config: data.config || {},
        tabs: data.tabs || []
      };
    } catch (error) {
      return null;
    }
  };

  const { resolvedTheme } = useTheme();

  const renderVisualization = (vizData) => {
    if (!vizData) return null;

    const defaultConfig = {
      xAxis: {
        label: {
          fontSize: 12,
          style: {
            fontSize: 12
          }
        }
      },
      yAxis: {
        label: {
          fontSize: 12,
          style: {
            fontSize: 12
          }
        }
      },
      label: {
        fontSize: 12,
        style: {
          fontSize: 12
        }
      }
    };

    const commonProps = {
      ...defaultConfig,
      ...vizData.config,
      data: vizData.data,
      animation: true,
    };

    switch (vizData.subtype) {
      case 'line':
        return <Line {...commonProps} />;
      case 'bar':
        return <Column {...commonProps} />;
      case 'pie':
        return (
          <Pie 
            {...commonProps}
            angleField={vizData.config?.angleField || 'value'}
            colorField={vizData.config?.colorField || 'type'}
          />
        );
      case 'flow':
        return <FlowChartRenderer data={vizData.data} config={vizData.config} />;
      default:
        return <div>Unsupported visualization type: {vizData.subtype}</div>;
    }
  };

  const FlowChartRenderer = ({ data, config }) => {
    const containerRef = useRef(null);

    useEffect(() => {
      if (containerRef.current && data) {
        try {
          const diagram = flowchart.parse(data);
          containerRef.current.innerHTML = '';
          diagram.drawSVG(containerRef.current, config || {});
        } catch (error) {
          containerRef.current.innerHTML = 'Error rendering flowchart';
        }
      }
    }, [data, config]);

    return <div ref={containerRef} className="flow-chart-container" />;
  };

  // eslint-disable-next-line no-unused-vars
  const handleDownloadArtifact = (type, content, title) => {
    if (type === 'essay') {
      const formattedContent = formatTextForDownload(content);
      downloadMarkdownFile(formattedContent, title);
    }
    if (type === 'code') {
      downloadCodeFile(content, currentResourceVersion.language, title);
    }

    showCustomToast('success', `${title.substring(0, 30)}${title.length > 30 ? '...' : ''} Successfully Downloaded`);
  };

  const formatTextForDownload = (text) => {
    if (text.includes('<code')) {
      // Convert code blocks to markdown format
      return text.replace(/<code language="(\w+)".*?>([\s\S]*?)<\/code>/g, '```$1\n$2```');
    }
    return text;
  };

  const renderContent = () => {
    if (currentResourceVersion.type === 'visualization') {
      const vizData = parseVisualizationData(currentResourceVersion.content);
      
      if (!vizData) {
        return <div>Invalid visualization data</div>;
      }

      if (vizData.tabs && vizData.tabs.length > 0) {
        return (
          <Tabs
            activeKey={activeTab}
            onChange={setActiveTab}
            items={vizData.tabs.map((tab, index) => ({
              key: index.toString(),
              label: tab.title,
              children: renderVisualization(tab.visualization)
            }))}
          />
        );
      }

      return renderVisualization(vizData);
    }

    if (currentResourceVersion.type === 'file') {
      return <div>{currentResourceVersion.content}</div>;
    } else if (isCode) {
      return resolvedTheme === 'dark' ? (
        <div style={{ width: '100%', overflow: 'auto' }}>
          <SyntaxHighlighter
            language={currentResourceVersion.language || 'text'}
            wrapLines={true}
            wrapLongLines={true}
            style={customTheme}
            customStyle={{
              margin: 0,
              borderRadius: '0px',
              padding: '1rem',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
              width: '100%',
              fontFamily: 'var(--font-mono)',
              fontSize: '0.8rem',
              lineHeight: '1.7',
              backgroundColor: 'var(--background-light)',
              border: '1px solid var(--border-color)',
              boxShadow: 'var(--shadow-elevated)',
            }}
          >
            {currentResourceVersion.content}
          </SyntaxHighlighter>
        </div>
      ) : (
        <div style={{ width: '100%', overflow: 'auto' }}>
          <SyntaxHighlighter
            language={currentResourceVersion.language || 'text'}
            wrapLines={true}
            wrapLongLines={true}
            customStyle={{
              margin: 0,
              borderRadius: '0px',
              padding: '1rem',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
              width: '100%',
              fontFamily: 'var(--font-mono)',
              fontSize: '0.8rem',
              lineHeight: '1.3',
              backgroundColor: 'var(--background-light)',
              border: '1px solid var(--border-color)',
            }}
          >
            {currentResourceVersion.content}
          </SyntaxHighlighter>
        </div>
      );
    } else {
      return (
        <div className="essay-text">
          {showDiff && currentIndex > 0 ? (
            renderDiffContent()
          ) : (
            <AIResponseRenderer content={currentResourceVersion.content} />
          )}
        </div>
      );
    }
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="expanded-resource">
      <div className="expanded-resource-header">
        <button className="back-button" onClick={onClose}>
          <FaArrowLeft /> 
        </button>
        <h2 title={currentResourceVersion.title}>
          {truncateText(currentResourceVersion.title)}
        </h2>

        {currentResourceVersion.type === 'file' && currentResourceVersion.url && (
          <a 
            href={currentResourceVersion.link} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="pdf-link"
          >
            {windowWidth <= 1100 ? 'File' : 'View Original File'} <FaExternalLinkAlt />
          </a>
        )}
      </div>
      <div 
        ref={contentRef}
        className={`
          expanded-resource-content
          ${currentResourceVersion.type === 'visualization' ? 'visualization-content' : ''}
          ${isCode ? 'code-content' : ''}
          ${isfile ? 'pdf-content' : 'not-pdf-content'}
          ${currentResourceVersion.type === 'essay' || (!isCode && !isfile) ? 'essay-content' : ''}
        `}
      >
        {renderContent()}
      </div>

      {showOptions && (
        <div 
          ref={optionsRef}
          className="text-options"
          style={{
            position: 'absolute',
            top: `${optionsPosition.top}px`,
            left: `${optionsPosition.left}px`,
          }}
        >
          {isImproveMode ? (
            <div className="improve-input-container">
              <input
                type="text"
                ref={improveInputRef}
                value={improveSuggestion}
                onChange={(e) => setImproveSuggestion(e.target.value)}
                onKeyDown={handleImproveSuggestionKeyPress}
                placeholder="What would you like to improve?"
                className="improve-suggestion-input"
              />
              <button onClick={handleSendImprovement} className="send-improvement-button">
                <FaPaperPlane />
              </button>
            </div>
          ) : (
            <>
              <button onClick={handleImprove} className="option-button improve">
                <FaMagic /> Improve
              </button>
              <button onClick={(e) => handleExplain(e)} className="option-button explain">
                <FaInfoCircle /> Explain
              </button>
            </>
          )}
        </div>
      )}

      {currentResourceVersion.type !== 'file' && (versions.length > 0 || isSpaceChat) && (
        <div className="smart-footer">
          {versions.length > 1 && (
            <div className="version-controls">
              <button
                className="version-nav-btn prev"
                onClick={() => toggleVersionControl('previous')}
                disabled={currentIndex === 0}
                aria-label="Previous version"
              >
                <FaChevronLeft />
              </button>
              <div className="version-display">
                <span className="version-current">{currentIndex + 1}</span>
                <span className="version-divider"> of </span>
                <span className="version-total">{versions.length}</span>
              </div>
              <button
                className="version-nav-btn next"
                onClick={() => toggleVersionControl('next')}
                disabled={currentIndex === versions.length - 1}
                aria-label="Next version"
              >
                <FaChevronRight />
              </button>
              {currentResourceVersion.type === 'essay' && currentIndex > 0 && (
                <button
                  className="version-nav-btn diff-toggle"
                  onClick={() => setShowDiff(!showDiff)}
                  aria-label="Toggle changes view"
                >
                  <FaExchangeAlt />
                </button>
              )}
            </div>
          )}
          <div className="footer-right">
            {(isCode || currentResourceVersion.type === 'essay') && (
              <CopyButton 
                activeMessage={currentResourceVersion}
                showHoverText={false}
                useRichCopy={true}
                className="footer-right-button"
              />
            )}

            {(currentResourceVersion.type === 'essay' || currentResourceVersion.type === 'code') && (
              <button
                className="footer-right-button"
                onClick={() =>
                  handleDownloadArtifact(
                  currentResourceVersion.type,
                  currentResourceVersion.content,
                  currentResourceVersion.title
                )
              }
            >
                <DownloadCircle02Icon className="footer-right-button-icon" />
              </button>
            )}

            {isSpaceChat && !isResourceInSpace() && (
              <button
                className="add-to-space-button"
                onClick={() => onAddToSpace(currentResourceVersion)}
                title="Add to Space Resources"
              >
                Add to Space
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpandedResource;
