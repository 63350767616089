/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext, createContext } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import SignUp from './components/Auth/Signup.jsx';
import Login from './components/Auth/Login.jsx';
import Settings from './components/Settings.jsx';
import PrivateRoute from './components/Auth/PrivateRoute.js';
import Chats from './components/Chats.jsx';
import { AuthProvider, useAuth } from './hooks/useAuth';
import './Styles/variables.css';
import './App.css';
import './Styles/animations.css';
import Home from './components/Home.jsx';
import Footer from './components/Partials/Footer.jsx';
import Pricing from './components/Pricing.jsx';
import NavBar from './components/Partials/NavBar.jsx';
import NotFound from './components/Partials/404.jsx';
import LoadingOverlay from './components/Partials/LoadingOverlay.jsx';
import { QueryClient, QueryClientProvider } from 'react-query';
import SitewideBanner from './components/Partials/SitewideBanner'; // Add this import
import { useQuery } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Styles/Toast.css';  // Add this import
import PaymentConfirmation from './components/PaymentConfirmation.jsx';
import { fetchChats, getProfile, logout, getSpaces } from './services/api';
import ForgotPassword from './components/Auth/ForgotPassword.jsx';
import ResetPassword from './components/Auth/ResetPassword.jsx';
import VerifyEmail from './components/Auth/VerifyEmail.jsx';
import StudySpaces from './components/StudySpaces.jsx';
import ScreenSizeWarning from './components/Partials/ScreenSizeWarning.jsx';
import StudySpace from './components/StudySpace.jsx';
import { HelmetProvider } from 'react-helmet-async';
import UniversityPromo from './components/UniversityPromo.jsx';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 30 * 60 * 1000, // 30 minutes
      refetchOnMount: true, // Ensure data is fresh on mount
    },
  },
});



const PublicRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();
  


  return isAuthenticated ? (
    <Navigate to={location.state?.from?.pathname || "/"} replace />
  ) : (
    children
  );
};

const ExternalRedirect = ({ to, onMount }) => {
  useEffect(() => {
    if (onMount) onMount();
    window.location.href = to;
  }, [to, onMount]);
  return null;
};

// ThemeContext setup
const ThemeContext = createContext({
  theme: 'light', // Selected theme: 'light', 'dark', or 'system'
  resolvedTheme: 'light', // Actual applied theme: 'light' or 'dark'
  toggleTheme: () => {},
  setSystemTheme: () => {},
});

// Custom hook to use ThemeContext
export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

// ThemeProvider component
export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme || 'system';
  });

  const [resolvedTheme, setResolvedTheme] = useState(() => {
    if (theme === 'system') {
      return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }
    return theme;
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = (e) => {
      if (theme === 'system') {
        const newResolvedTheme = e.matches ? 'dark' : 'light';
        setResolvedTheme(newResolvedTheme);
        document.documentElement.setAttribute('data-theme', newResolvedTheme);
      }
    };

    // Apply the theme initially
    if (theme === 'system') {
      const initialResolvedTheme = mediaQuery.matches ? 'dark' : 'light';
      setResolvedTheme(initialResolvedTheme);
      document.documentElement.setAttribute('data-theme', initialResolvedTheme);
    } else {
      setResolvedTheme(theme);
      document.documentElement.setAttribute('data-theme', theme);
    }

    // Add event listener for system theme changes
    mediaQuery.addEventListener('change', handleChange);

    // Cleanup listener on unmount or when theme changes
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [theme]);

  const toggleTheme = () => {
    let newTheme;
    if (theme === 'light') {
      newTheme = 'dark';
    } else if (theme === 'dark') {
      newTheme = 'system';
    } else {
      newTheme = 'light';
    }
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);

    if (newTheme === 'system') {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      setResolvedTheme(systemTheme);
      document.documentElement.setAttribute('data-theme', systemTheme);
    } else {
      setResolvedTheme(newTheme);
      document.documentElement.setAttribute('data-theme', newTheme);
    }
  };

  const setSystemTheme = () => {
    setTheme('system');
    localStorage.setItem('theme', 'system');
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    setResolvedTheme(systemTheme);
    document.documentElement.setAttribute('data-theme', systemTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, resolvedTheme, toggleTheme, setSystemTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

const AppContent = () => {
  const { isLoading, user, spaces, chats } = useAuth();
  const location = useLocation();
  
  // Add state for screen size check
  const [isScreenTooSmall, setIsScreenTooSmall] = React.useState(false);

  // Add effect to check screen size
  useEffect(() => {
    const checkScreenSize = () => {
      setIsScreenTooSmall( (window.innerWidth > 300 && window.innerHeight < 300) || (window.innerWidth < 300));
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  // Add scroll to top effect
  useEffect(() => {
    const isChatsPage = /^\/($|[^/]+\/?$)/.test(location.pathname);
    if (!isChatsPage) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  const isChatsPage = /^\/($|[^/]+\/?$|ss\/[^/]+\/?$)/.test(location.pathname);
  const isFeaturesPage = /^\/features/.test(location.pathname);
  const isPricingPage = /^\/pricing/.test(location.pathname);
  const isPolicyPage = /^\/privacy-policy/.test(location.pathname);
  const isTermsPage = /^\/terms-of-service/.test(location.pathname);
  const isContactPage = /^\/contact/.test(location.pathname);
  const isYorkuPage = /^\/yorku/i.test(location.pathname);
  const isUmanitobaPage = /^\/umanitoba/i.test(location.pathname);
  const showNavBar = isFeaturesPage || isPricingPage || isContactPage ||isTermsPage || isPolicyPage || isYorkuPage || isUmanitobaPage;
  const showFooter = isFeaturesPage || isPricingPage || isPolicyPage || isTermsPage || isContactPage || isYorkuPage || isUmanitobaPage;

  const showLogo = !(isYorkuPage || isUmanitobaPage);

  const showSitewideBanner = user && user.status === 'failed';

  // Use useQuery for subscription data
  const { data: subscriptionData } = useQuery(
    'subscription',
    () => getProfile(),
    {
      enabled: !!user,
      select: (data) => data.subscription,
      // Use initialData from auth context
      initialData: () => user?.subscription
    }
  );

  // Remove or modify the chats query since it's now in auth context
  const { data: chatsData } = useQuery(
    ['chats', user?.id],
    fetchChats,
    {
      enabled: !!user,
      // Use initialData from auth context
      initialData: () => ({
        chats: chats || [],
        subscription: user?.subscription,
        remainingChats: user?.remainingChats
      })
    }
  );

  

  // Remove or modify the spaces query since it's now in auth context
  const { data: spacesData } = useQuery(
    'spaces',
    getSpaces,
    {
      enabled: !!user,
      // Use initialData from auth context
      initialData: () => spaces || []
    }
  );

  const { data: spaceChatsData } = useQuery(
    ['space-chats', user?.id],
    () => {
      // Return the filtered data from the cache
      const data = queryClient.getQueryData(['chats', user?.id]);
      if (!data) return { chats: [], subscription: null, remainingChats: null };
      
      const spaceChats = data.chats?.filter(chat => chat.spaceId) || [];
      return {
        chats: spaceChats,
        subscription: data.subscription,
        remainingChats: data.remainingChats
      };
    },
    {
      enabled: !!user,
      // Use the chats query as a dependency
      initialData: () => {
        const data = queryClient.getQueryData(['chats', user?.id]);
        if (!data) return undefined;
        
        const spaceChats = data.chats?.filter(chat => chat.spaceId) || [];
        return {
          chats: spaceChats,
          subscription: data.subscription,
          remainingChats: data.remainingChats
        };
      }
    }
  );

  // Memoize derived data
  const { chats: chatsDataMemo, remainingChats: remainingChatsData } = React.useMemo(() => ({
    chats: chatsData?.chats || [],
    remainingChats: user?.remainingChats || chatsData?.remainingChats
  }), [chatsData, user?.remainingChats]);

  // Add this query alongside other queries like chatsData and spacesData
  const { data: artifactTitlesData } = useQuery(
    'artifactTitles',
    async () => {
      // Get all spaces first
      const spaces = queryClient.getQueryData('spaces');
      
      // Extract and process artifacts from all spaces
      const allArtifacts = spaces.flatMap(space => {
        if (!space.Artifacts_text) return [];
        
        const artifactsMatch = space.Artifacts_text.match(/<artifacts>([\s\S]*?)<\/artifacts>/g) || [];
        
        return artifactsMatch.flatMap(artifact => {
          const chatIdMatch = artifact.match(/<chatid>(.*?)<\/chatid>/);
          const chatId = chatIdMatch ? chatIdMatch[1] : null;
          
          const titles = [];
          
          // Extract essay titles
          const essayMatch = artifact.match(/<essay>([\s\S]*?)<\/essay>/);
          if (essayMatch) {
            const titleMatch = essayMatch[1].match(/<title>(.*?)<\/title>/);
            if (titleMatch) {
              titles.push({
                title: titleMatch[1],
                type: 'essay',
                chatId
              });
            }
          }
          
          // Extract code titles
          const codeMatch = artifact.match(/<code.*?>([\s\S]*?)<\/code>/);
          if (codeMatch) {
            const titleMatch = codeMatch[0].match(/title="(.*?)"/);
            if (titleMatch) {
              titles.push({
                title: titleMatch[1],
                type: 'code',
                chatId
              });
            }
          }
          
          // Extract visualization titles
          const vizMatch = artifact.match(/<visualization.*?>([\s\S]*?)<\/visualization>/);
          if (vizMatch) {
            const titleMatch = vizMatch[0].match(/title="(.*?)"/);
            if (titleMatch) {
              titles.push({
                title: titleMatch[1],
                type: 'visualization',
                chatId
              });
            }
          }
          
          return titles;
        });
      });
      
      // Remove duplicates
      return allArtifacts.filter((title, index, self) => 
        index === self.findIndex(t => 
          t.title === title.title && t.chatId === title.chatId
        )
      );
    },
    {
      enabled: !!user,
    }
  );

  // Add screen size check
  if (isScreenTooSmall) {
    return <ScreenSizeWarning />;
  }

  return (
    <div className={`app-container ${isChatsPage ? 'is-chats-page' : ''}`}>

      <main className="content">
        <div className="main-container">
          {showSitewideBanner && <SitewideBanner />}
          {showNavBar && <NavBar showLogo={showLogo} />}
          <Routes>
          <Route path="/" element={
              <Chats 
                subscription={subscriptionData}
                remainingChats={user?.remainingChats || remainingChatsData}
              />
            } />
            <Route 
              path="/new" 
              element={<Navigate to="/" replace />} 
            />
            <Route 
              path="/undefined" 
              element={<Navigate to="/" replace />} 
            />
            <Route path="/home" element={<Home />} />
            <Route 
              path="/privacy-policy" 
              element={<ExternalRedirect to="https://cognora.ca/privacy-policy" />} 
            />
            <Route 
              path="/terms-of-service" 
              element={<ExternalRedirect to="https://cognora.ca/terms-of-service" />} 
            />
            <Route path="/signup" element={<PublicRoute><SignUp /></PublicRoute>} />
            <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/pricing/:tierName" element={<Pricing />} />
            <Route path="/payment-confirmation" element={<PaymentConfirmation />} />
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
            <Route path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
            <Route path="/reset-password" element={<PublicRoute><ResetPassword /></PublicRoute>} />
            <Route path="/verify-email" element={<PublicRoute><VerifyEmail /></PublicRoute>} />
            <Route path="/yorku" element={<UniversityPromo universityName="York University" universityCode="yorku" logoUrl="Assets/York University Logo.png" />} />
            <Route path="/umanitoba" element={<UniversityPromo universityName="University of Manitoba" universityCode="umanitoba" logoUrl="Assets/University of Manitoba Logo.jpg" />} />
            <Route 
              path="/study-spaces" 
              element={
                <PrivateRoute requiresElite={true}>
                  <StudySpaces />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/study-spaces/:id" 
              element={
                <PrivateRoute requiresElite={true}>
                  <StudySpace />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/logout" 
              element={
                <PublicRoute>
                  <ExternalRedirect 
                    to="/login" 
                    onMount={() => logout()} 
                  />
                </PublicRoute>
              } 
            />
            <Route path="/ss/:chatId" element={
              <PrivateRoute requiresElite={true}>
                <Chats 
                  subscription={subscriptionData}
                  isSpaceChat={true}
                />
              </PrivateRoute>
            } />
            <Route
              path="/:chatId?"
              element={
                isLoading ? (
                  <LoadingOverlay />
                ) :
                user && location.pathname.slice(1) &&
                  /^(?=.*[a-zA-Z])(?=.*\d).+$/.test(location.pathname.slice(1)) ? (
                  <Chats subscription={subscriptionData} isSpaceChat={false} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </div>
      </main>
      {showFooter && <Footer />}
    </div>
  );
};

const App = () => {
  useEffect(() => {
    const getFontSizeValue = (size) => {
      switch (size) {
        case 'small': return '14px';
        case 'medium': return '16px';
        case 'large': return '18px';
        default: return '16px';
      }
    };

    const setFontSize = () => {
      const fontSize = localStorage.getItem('fontSize') || 'medium';
      document.documentElement.style.fontSize = getFontSizeValue(fontSize);
    };

    setFontSize();
    window.addEventListener('storage', setFontSize);
    return () => window.removeEventListener('storage', setFontSize);
  }, []);

  return (
    <HelmetProvider>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <AuthProvider>
              <AppContent />
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </AuthProvider>
          </Router>
        </QueryClientProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
