import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { resetPassword } from '../../services/api';
import AuthLayout from './Layout/AuthLayout';
import { FormInput, SubmitButton, FormError, FormSuccess } from './Layout/FormInput';

const ResetPassword = () => {
  const [passwords, setPasswords] = useState({ new: '', confirm: '' });
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showPasswords, setShowPasswords] = useState({ new: false, confirm: false });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const token = new URLSearchParams(useLocation().search).get('token');

  React.useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    setIsLoading(true);

    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d|.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    if (!passwordRegex.test(passwords.new)) {
      setError('Password must be at least 8 characters long and contain a number or symbol.');
      setIsLoading(false);
      return;
    }

    if (passwords.new !== passwords.confirm) {
      setError('Passwords do not match');
      setIsLoading(false);
      return;
    }

    try {
      const response = await resetPassword(token, passwords.new);
      setMessage(response.message);
      setTimeout(() => navigate('/login'), 2000);
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout title="Reset Password">
      <form onSubmit={handleSubmit}>
        <FormError message={error} />
        <FormSuccess message={message} />
        
        <FormInput
          label="New Password"
          type="password"
          id="new"
          value={passwords.new}
          onChange={(e) => setPasswords(prev => ({ ...prev, new: e.target.value }))}
          showPassword={showPasswords.new}
          togglePassword={() => setShowPasswords(prev => ({ ...prev, new: !prev.new }))}
          disabled={isLoading}
          required
          placeholder="At least 8 characters with a number or symbol"
        />

        <FormInput
          label="Confirm New Password"
          type="password"
          id="confirm"
          value={passwords.confirm}
          onChange={(e) => setPasswords(prev => ({ ...prev, confirm: e.target.value }))}
          showPassword={showPasswords.confirm}
          togglePassword={() => setShowPasswords(prev => ({ ...prev, confirm: !prev.confirm }))}
          disabled={isLoading}
          required
          placeholder="Re-enter your new password"
        />

        <SubmitButton isLoading={isLoading}>
          Set New Password
        </SubmitButton>
      </form>

      <p className="auth-link">
        Remember your password? <Link to="/login">Log in</Link>
      </p>
    </AuthLayout>
  );
};

export default ResetPassword;