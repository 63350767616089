import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaCheck, FaArrowRight, FaChevronRight } from 'react-icons/fa';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../Styles/UniversityPromo.css';
import { useAuth } from '../hooks/useAuth';
import { createCheckoutSession, handleStripePortalRedirect } from '../services/api';
import useDocumentTitle from '../UseDocumentTitle';
import LoadingOverlay from './Partials/LoadingOverlay';
import faqs from './Data/Faqs';
import { pricingTiers } from './Data/PricingData';
import { Add01Icon, Book02Icon, File02Icon, Message01Icon, Search01Icon, Upload01Icon } from 'hugeicons-react';

const universityLogos = [
    { src: '/Assets/stanford_logo.png', alt: 'Stanford' },
    { src: '/Assets/University of Toronto Logo.png', alt: 'UofT' },
    { src: '/Assets/UofGuelph.png', alt: 'UofGuelph' },
    { src: '/Assets/York University Logo.png', alt: 'York University' },
    { src: '/Assets/University of Manitoba Logo.jpg', alt: 'University of Manitoba' },
    { src: '/Assets/BuffaloState.png', alt: 'Buffalo State' },
    { src: "/Assets/ubc-logo.png", alt: "University of British Columbia" },
    { src: "/Assets/university-of-waterloo-1.svg", alt: "University of Waterloo" },
    { src: "/Assets/UniversityofPennsylvania.png", alt: "University of Pennsylvania" },
    { src: "/Assets/dalhousie_university.jpg", alt: "Dalhousie University" },
];


const UniversityPromo = ({ 
    universityName = "",
    universityCode = "",
    logoUrl = "" // Default value
}) => {
    const { user, isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const subscription = user?.subscription || null;
    const [billingCycle, setBillingCycle] = useState('monthly');
    const [currency, setCurrency] = useState('usd');
    const [isLoading, setIsLoading] = useState(false);
    const [activeFaqId, setActiveFaqId] = useState(null);
    const promoCode = null;

    const toggleFaq = (id) => {
        setActiveFaqId(activeFaqId === id ? null : id);
    };
    
    useEffect(() => {
        const userLanguage = navigator.language || navigator.userLanguage;
        if (userLanguage.toLowerCase().includes('ca')) {
            setCurrency('cad');
        } else {
            setCurrency('usd');
        }
    }, []);

    const handleSubscription = useCallback(async (tier, interval = billingCycle, currencyCode = currency, trialdays = 31) => {
        setIsLoading(true);
        if (!isAuthenticated) {
            navigate('/signup', { 
                state: { 
                    selectedTier: tier, 
                    interval, 
                    currency: currencyCode,
                    promoCode: null,
                    trialdays: 31,
                    returnUrl: `/${universityCode}`
                } 
            });
            return;
        }

        try {
            if(subscription && subscription.tier !== "free"){
                await handleStripePortalRedirect();
                return;
            }
            
            const response = await createCheckoutSession(tier, interval, currencyCode, promoCode, trialdays);

            if (response?.url) {
                window.location.assign(response.url);
            } else {
                console.error('Invalid checkout session response:', response);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error.message);
        } finally {
            setIsLoading(false);
        }
    }, [isAuthenticated, navigate, subscription, billingCycle, currency, promoCode, universityCode]);

    useDocumentTitle(`StudyBuddy - ${universityName}`);
    return (
        isLoading ? <LoadingOverlay /> : <HelmetProvider>
            <div className="university-promo">
                <Helmet>
                    <title>{`StudyBuddy - ${universityName}`}</title>
                    <meta name="description" content={`Exclusive StudyBuddy offer for ${universityName} students. Get 1 month free access to our Ultimate plan. Enhance your learning with AI-powered discussions, document analysis, and organized study spaces.`} />
                    <meta name="keywords" content={`StudyBuddy, ${universityName}, student offer, AI tutor, study tools, education, Ultimate plan, free trial, university discount`} />
                    <link rel="canonical" href={`https://studdybuddy.ca/${universityCode}`} />
                    <meta name="robots" content="index, follow" />

                    {/* Structured Data (Schema.org) */}
                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "http://schema.org",
                            "@type": "Offer",
                            "name": "StudyBuddy Ultimate Plan - 1 Month Free",
                            "description": `Exclusive offer for ${universityName} students. Get 1 month free access to StudyBuddy's Ultimate plan.`,
                            "url": `https://studdybuddy.ca/university/${universityCode}`,
                            "availability": "http://schema.org/InStock",
                            "price": "0",
                            "priceCurrency": "USD",
                            "validFrom": new Date().toISOString(),
                            "seller": {
                                "@type": "Organization",
                                "name": "Cognora",
                                "url": "https://cognora.ca"
                            },
                            "eligibleCustomerType": "http://schema.org/Student",
                            "eligibleRegion": {
                                "@type": "Country",
                                "name": "CA"
                            },
                            "itemOffered": {
                                "@type": "Service",
                                "name": "StudyBuddy Ultimate Plan",
                                "serviceType": "Educational",
                                "provider": {
                                    "@type": "Organization",
                                    "name": "Cognora",
                                    "url": "https://cognora.ca"
                                },
                                "areaServed": {
                                    "@type": "Country",
                                    "name": "CA"
                                }
                            }
                        })}
                    </script>
                </Helmet>

                {/* Simplify header and make offer more prominent */}
                <div className="promo-header">
                    <div className="logo-container">
                        <img src="/Cognore Inverse Logo.png" alt="Cognora Logo" className="logo" />
                        <img src={logoUrl} alt="University Logo" className="logo school" />
                    </div>
                    <h1>
                        Unlock Your Academic Potential
                        {window.innerWidth > 768 && ': Get 1 Month Free'}
                    </h1>
                    <p className="subtitle">Exclusive for {universityName} Students</p>
                    <button onClick={() => handleSubscription('ultimate')} className="signup-button cta">
                        Start Your Free Month
                        <FaChevronRight size={20} />
                    </button>
                </div>

                {/* Move social proof higher */}
                <div className="used-by-students">
                    <h3>Used by students from:</h3>
                    <div className="university-logos">
                        {universityLogos
                            .sort(() => 0.5 - Math.random())
                            .slice(0, window.innerWidth <= 768 ? 3 : 5)
                            .map((logo, index) => (
                                <img key={index} src={logo.src} alt={logo.alt} className="university-logo" />
                            ))}
                    </div>
                </div>

                {/* Move feature grid before study spaces */}
                <div className="feature-grid">
                    <div className="feature-column left">
                        <div className="feature-card">
                            <div className="feature-icon">
                                <Message01Icon size={24} />
                            </div>
                            <div className="feature-content">
                                <h3>AI-Powered Discussions</h3>
                                <p>Start multiple chat sessions within each space, maintaining context across conversations for deeper understanding.</p>
                            </div>
                        </div>
                        <div className="feature-card">
                            <div className="feature-icon">
                                <Upload01Icon size={24} />
                            </div>
                            <div className="feature-content">
                                <h3>Document Analysis</h3>
                                <p>Upload PDFs and study materials for AI-assisted analysis and interactive discussions about your content.</p>
                            </div>
                        </div>
                    </div>

                    <div className="feature-column right">
                        <div className="feature-card">
                            <div className="feature-icon">
                                <Book02Icon size={24} />
                            </div>
                            <div className="feature-content">
                                <h3>Subject Organization</h3>
                                <p>Create dedicated spaces for each subject with custom titles, descriptions, and organized study materials.</p>
                            </div>
                        </div>
                        <div className="feature-card">
                            <div className="feature-icon">
                                <File02Icon size={24} />
                            </div>
                            <div className="feature-content">
                                <h3>Learning Resources</h3>
                                <p>Access study materials, chat history, and AI-generated content in one organized space for each subject.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Move pricing section higher */}
                <div className="pricing-toggle promo">
                    <span className={billingCycle === 'monthly' ? 'active' : ''}>Monthly</span>
                    <div className="toggle-switch">
                        <input
                            type="checkbox"
                            id="billing-toggle"
                            checked={billingCycle === 'annual'}
                            onChange={() => setBillingCycle(billingCycle === 'monthly' ? 'annual' : 'monthly')}
                        />
                        <label htmlFor="billing-toggle"></label>
                    </div>
                    <span className={billingCycle === 'annual' ? 'active' : ''}>
                        Annual
                        <span className="save-badge">
                            {window.innerWidth < 600 ? '30%' : 'Save 30%'}
                        </span>
                    </span>
                </div>

                <div className="pricing-cards">
                    {pricingTiers.filter(tier => tier.name === 'Ultimate').map((tier) => (
                        <div 
                            key={tier.name} 
                            className={`pricing-card ${tier.highlight ? 'highlight' : ''} promo`}
                        >
                            {tier.highlight && <div className="popular-badge">Most Popular</div>}
                            <div className="card-header">
                                <h3>{tier.name}</h3>
                                <p className="tagline">{tier.tagline}</p>
                                <div className="price-container">
                                    {tier.name !== 'Free' ? (
                                        <>
                                            <span className="price">
                                                {billingCycle === 'annual' ? (
                                                    <>
                                                        <span className="monthly-equivalent">
                                                            {`${tier.price[currency].annual.charAt(0)}${(parseFloat(tier.price[currency].annual.slice(1)) / 12).toFixed(2)}`}/mo
                                                        </span>
                                                        <span className="annual-total">
                                                            {tier.price[currency].annual}/yr
                                                        </span>
                                                    </>
                                                ) : (
                                                    tier.price[currency][billingCycle]
                                                )}
                                            </span>
                                            <span className="period">
                                                {billingCycle === 'monthly' ? '/mo' : ''}
                                            </span>
                                        </>
                                    ) : (
                                        <span className="price">Free</span>
                                    )}
                                </div>
                            </div>

                            <div className="features">
                                <div className="feature">
                                    <FaCheck /> Unlimited AI interactions
                                </div>
                                {tier.features.slice(1).map((feature, index) => (
                                    <div key={index} className="feature">
                                        <FaCheck /> {feature}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Move study spaces showcase lower */}
                <div id="study-spaces-showcase" className="study-spaces-showcase">
                    <div className="showcase-header">
                        <h2>Organize Your Learning with Study Spaces</h2>
                        <p>Create dedicated spaces for each subject, complete with AI-powered discussions and study materials</p>
                    </div>

                    <div className="showcase-content">
                        <div className="showcase-visual">
                            <div className="feature-preview">
                                <div className="space-preview-header">
                                    <div className="header-left">
                                        <div className="return-to-chats">
                                            <Message01Icon size={20} />
                                            <span>Chats</span>
                                        </div>
                                        <h3>Study Spaces</h3>
                                    </div>
                                    <div className="header-actions">
                                        <button className="create-space-btn">
                                            <Add01Icon size={16} />
                                            New Space
                                        </button>
                                    </div>
                                </div>

                                <div className="space-preview-search">
                                    <Search01Icon size={18} className="search-icon" />
                                    <input
                                        type="text"
                                        placeholder="Search study spaces..."
                                        disabled
                                    />
                                </div>

                                <div className="space-preview-grid">
                                    {[
                                        {
                                            title: "Physics 101",
                                            description: "Classical mechanics and kinematics",
                                            index: 0
                                        },
                                        {
                                            title: "Data Structures",
                                            description: "Algorithms and programming concepts",
                                            index: 1
                                        },
                                        {
                                            title: "World History",
                                            description: "Modern historical analysis",
                                            index: 2
                                        }
                                    ].map((space) => (
                                        <div 
                                            key={space.title}
                                            className="space-preview-card"
                                            style={{ '--index': space.index }}
                                        >
                                            <div className="space-header">
                                                <Book02Icon size={20} />
                                                <h3>{space.title}</h3>
                                            </div>
                                            <p className="space-description">{space.description}</p>
                                            <div className="space-footer">
                                                <div className="space-stats">
                                                    <span className="stat">
                                                        <Message01Icon size={14} />
                                                        3 chats
                                                    </span>
                                                    <span className="stat">
                                                        <File02Icon size={14} />
                                                        2 files
                                                    </span>
                                                </div>
                                                <FaChevronRight size={16} className="arrow-icon" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Move FAQs to bottom */}
                <div className="promo-footer">
                    <button onClick={() => handleSubscription('ultimate')} className="signup-button cta">
                        Claim Your Free Month Now
                        <FaChevronRight size={20} />
                    </button>
                    <p className="footer-note">
                        Exclusive offer for {universityName} students. 
                    </p>
                </div>
                
                <div className="pricing-faq">
                    <h2>Frequently Asked Questions</h2>
                    <div className="faq-grid">
                        {faqs.map((faq) => (
                            <button 
                                key={faq.id} 
                                className={`faq-item ${activeFaqId === faq.id ? 'active' : ''}`}
                                onClick={() => toggleFaq(faq.id)}
                            >
                                <div className="faq-question">
                                    <span>{faq.question}</span>
                                    <FaArrowRight className="arrow" />
                                </div>
                                <div className="faq-answer">{faq.answer}</div>
                            </button>
                        ))}
                    </div>
                </div>

                <div className="free-tier-footer">
                    <p>Have more questions? Visit our <Link to="https://cognora.ca/faq">FAQ page</Link> for help.</p>
                </div>

            </div>
        </HelmetProvider>
    );
};

export default UniversityPromo;