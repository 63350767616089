import { FallingStarIcon } from "hugeicons-react";
import { useState } from "react";
import '../../Styles/Layout/ThinkingPanel.css';

// eslint-disable-next-line no-unused-vars
const SamplethinkingProcessText = `First, I carefully analyze the context and key components of your question, breaking it down into core concepts and identifying the specific areas that need to be addressed. This involves understanding both explicit and implicit aspects of your query to ensure a comprehensive response.

Next, I access my extensive knowledge base to retrieve relevant information, drawing from multiple domains and sources to gather the most pertinent data. This includes academic concepts, practical applications, and real-world examples that will help illustrate the answer clearly.

With this foundation, I develop a structured approach to presenting the information, organizing key points in a logical flow that builds understanding progressively. This ensures that complex concepts are broken down into digestible pieces while maintaining their interconnections.

I then focus on generating clear, precise explanations that bridge any potential knowledge gaps. This involves crafting examples, analogies, and detailed explanations that make abstract concepts concrete and accessible, while maintaining technical accuracy.

Finally, I conduct a thorough review of the response, checking for completeness, accuracy, and clarity. This includes verifying that all aspects of the original question are addressed and ensuring the explanation is both comprehensive and coherent.`;

const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  
  if (mins === 0) {
    return `${secs} second${secs !== 1 ? 's' : ''}`;
  }
  
  return `${mins} minute${mins !== 1 ? 's' : ''}, ${secs} second${secs !== 1 ? 's' : ''}`;
};

export const ThinkingPanel = ({currentThinkingTime, thinkingTime, thinkingContent}) => {
  const [isOpen, setIsOpen] = useState(false);

  if(!thinkingTime && !currentThinkingTime){
    return null;
  }

  const isStillThinking = !thinkingTime && currentThinkingTime; 
  const thinkingProcessText = thinkingContent;


  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`thinking-panel ${isStillThinking ? 'thinking' : ''}`} onClick={togglePanel} style={{cursor: 'pointer'}}>
      <div className={`thinking-header ${isStillThinking ? 'thinking' : ''}`}>
        <FallingStarIcon 
          size={20}
          style={{
            color: 'var(--primary-color)',
            opacity: currentThinkingTime ? 1 : 0.7
          }}
          className={`thinking-icon`}
        />
        <span>
          {isStillThinking ? (
            `Thinking for ${formatTime(currentThinkingTime)}...`
          ) : (
            `Thought for ${formatTime(thinkingTime)}`
          )}
        </span>
      </div>

      {isOpen && !isStillThinking && (
        <div className="thinking-process">
            <p>{thinkingProcessText}</p>
        </div>
      )}
    </div>
  )
}