import React, { useState, useRef, useEffect } from 'react';
import { ArrowDown01Icon } from 'hugeicons-react';
import '../../Styles/Layout/ModeSelector.css';
import { chatModeOptions } from '../Data/Modes';

const ModeSelector = ({ currentMode, selectMode, isChat = false }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  
  // Sort modes to put current mode first
  const sortedModes = [...chatModeOptions].sort((a, b) => {
    if (a.value === currentMode) return -1;
    if (b.value === currentMode) return 1;
    return 0;
  });

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleModeSelect = (mode) => {
    selectMode(mode);
    setIsDropdownOpen(false);
  };
  
  const CurrentIcon = chatModeOptions.find(mode => mode.value === currentMode)?.icon;

  return (
    <div className="mode-selector" ref={dropdownRef}>
      <button 
        type="button"
        className={`mode-selector-button ${isChat ? 'chat' : ''} ${isDropdownOpen ? 'active' : ''}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDropdownOpen(!isDropdownOpen);
        }}
      >

        <CurrentIcon size={isChat ? 23 : 20} />
        {isChat && (
          <span className="mode-text">
              {chatModeOptions.find(mode => mode.value === currentMode)?.label}
          </span>
        )}
        <ArrowDown01Icon 
          size={16} 
          className={`arrow-icon ${isDropdownOpen ? 'rotated' : ''}`}
        />
      </button>
      {isDropdownOpen && (
        <div className={`mode-dropdown ${isDropdownOpen ? 'active' : ''} ${isChat ? 'chat' : ''}`}>
          <div className="mode-dropdown-title">Switch Mode</div>
          {sortedModes.map(mode => (
            <div 
              key={mode.value}
              className={`mode-option mode-${mode.value} ${currentMode === mode.value ? 'active' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                handleModeSelect(mode.value);
              }}
              aria-label={`Switch to ${mode.label} mode`}
            >
              <mode.icon size={20} />
              <div>
                <div className="mode-title">{mode.label}</div>
                <div className="mode-description">{mode.description}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ModeSelector;
