import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Calendar03Icon, Comment01Icon, Book02Icon } from 'hugeicons-react';
import { getRelativeTime } from '../helpers/chatHelper';
import { FaChevronRight } from 'react-icons/fa';

const RecentChats = ({ chats, windowWidth = window.innerWidth }) => {
  const navigate = useNavigate();

  return (
    <div className="recent-chats-section">
      <h2>
        Recent Conversations
      </h2>
      <ul className="recent-chats-list">
        {[...chats]
          .sort((a, b) => new Date(b.last_modified) - new Date(a.last_modified))
          .slice(0, 9)
          .map((chat) => (
            <li 
              key={chat.id} 
              className={`recent-chat-item ${chat.space_id ? 'space-chat' : ''}`}
              onClick={() => navigate(chat.space_id ? `/ss/${chat.id}` : `/${chat.id}`)}
            >
              <div className="chat-preview">
                <div className="chat-preview-header">
                  <div className="chat-preview-icon">
                    {chat.space_id ? (
                      <Book02Icon size={18} className="space-chat-icon" />
                    ) : (
                      <Comment01Icon size={18} />
                    )}
                  </div>
                  <div className="chat-info">
                    <span className="chat-title" title={chat.title || 'Untitled Chat'}>
                      {windowWidth < 500 && chat.title && chat.title.length > 20 
                        ? `${chat.title.substring(0, 30)}...` 
                        : (chat.title || 'Untitled Chat')}
                    </span>
                    <span className="chat-date">
                      <Calendar03Icon size={14} />
                      {getRelativeTime(chat.last_modified)}
                    </span>
                  </div>
                </div>
                <FaChevronRight size={18} className="preview-arrow" />
              </div>
            </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentChats;
