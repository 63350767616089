import { Copy01Icon, Tick01Icon, ArrowDown01Icon, TickDouble01Icon, UploadCircle01Icon, RefreshIcon } from 'hugeicons-react';
import { copyRichText } from '../helpers/chatHelper';
import { useState, useRef, useEffect } from 'react';
import '../../Styles/Layout/Buttons.css';
import '../../Styles/Layout/ModeSelector.css';
import { chatModeOptions } from '../Data/Modes';

export const CopyButton = ({ activeMessage, showHoverText = true, useRichCopy = true, className = "action-button" }) => {
  const [isHolding, setIsHolding] = useState(false);
  const holdTimeout = useRef(null);
  const HOLD_DURATION = 500; // milliseconds
  const [isCopied, setIsCopied] = useState(false);
  const [isRichCopied, setIsRichCopied] = useState(false);

  const resetCopyState = () => {
    setTimeout(() => {
      setIsCopied(false);
      setIsRichCopied(false);
    }, 2000); // Show copy confirmation for 2 seconds
  };

  const handleMouseDown = () => {
    holdTimeout.current = setTimeout(() => {
      setIsHolding(true);
      if(useRichCopy) {
        copyRichText(activeMessage.content);
        setIsRichCopied(true);
      } else {
        navigator.clipboard.writeText(activeMessage.content);
        setIsCopied(true);
      }
      resetCopyState();
    }, HOLD_DURATION);
  };

  const handleMouseUp = () => {
    clearTimeout(holdTimeout.current);
    if (!isHolding) {
      navigator.clipboard.writeText(activeMessage.content);
      setIsCopied(true);
      resetCopyState();
    }
    setIsHolding(false);
  };

  const handleMouseLeave = () => {
    clearTimeout(holdTimeout.current);
    setIsHolding(false);
  };

  return (
    <button 
      className={className}
      aria-label="Copy message"
      title="Copy message"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
    >
      {isRichCopied ? (
        <TickDouble01Icon size={useRichCopy ? 18 : 16} />
      ) : (
        isCopied ? (
          <Tick01Icon size={useRichCopy ? 18 : 16} />
        ) : (
          <Copy01Icon size={useRichCopy ? 18 : 16} />
        )
      )}
      {showHoverText && (
        <span className="hover-text bottom">
          {isRichCopied ? "Rich Copied" : (
            isCopied ? "Copied" : "Hold to Rich Copy"
          )}
        </span>
      )}
    </button>
  );
};

export const SubmitButton = ({ 
  isSubmitting,
  isInputProcessing,
  input,
  remainingChats,
  isFileProcessing,
  onStopStreaming,
}) => {
  const isMobile = window.innerWidth <= 868;

  const hoverLocation = isMobile ? "top" : "right";

  const isDisabled = isSubmitting || 
    isInputProcessing || 
    (!input?.trim()) || 
    (remainingChats <= 0 && remainingChats != null) ||
    isFileProcessing;

  return (
    <button 
      type="submit"
      disabled={isDisabled}
    >
      {!input?.trim() ? (
        <UploadCircle01Icon
        className={`send-button-icon ${isDisabled ? 'disabled' : 'not-disabled'}`} size={isDisabled ? 30 : 34} 
        disabled={isDisabled}
        />
      ) :// isSubmitting ? (
        // <StopIcon className={`send-button-icon ${isDisabled ? 'disabled' : 'not-disabled'}`} size={30} onClick={onStopStreaming} />
      // ) : (
        (<UploadCircle01Icon 
          className={`send-button-icon ${isDisabled ? 'disabled' : 'not-disabled'}`} size={isDisabled ? 30 : 34}
          disabled={isDisabled}
          />
      )}
      <span className={`hover-text ${hoverLocation}`}>
        {isSubmitting ? "Sending..." :
        isInputProcessing ? "Processing..." :
        !input?.trim() ? "Enter a message" :
        remainingChats <= 0 && remainingChats != null ? "No chats left" : "Send A Message"}
      </span>
    </button>
  );
};

export const RegenerateButton = ({message, onRegenerate, currentMode, isLastMessage, isFirstMessage }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleModeSelect = (mode) => {
    onRegenerate(isLastMessage, message, mode);
    setIsDropdownOpen(false);
  };

  return (
    <div className="mode-selector" ref={dropdownRef}>
      <button 
        className="action-button"
      >
        <div className="regenerate-button-content">
        <RefreshIcon size={16} onClick={() => handleModeSelect(message.mode || currentMode)}/>
        <span className="mode-text">
          <ArrowDown01Icon 
            size={16} 
            className={`arrow-icon ${isDropdownOpen ? 'rotated' : ''}`}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          />
        </span>
        </div>
        <span className="hover-text bottom">Regenerate</span>
      </button>

      {isDropdownOpen && (
        <div className={`mode-dropdown regenerate-dropdown ${isDropdownOpen ? 'active' : ''} ${isFirstMessage ? 'first-message' : ''}`}>
          <div className="mode-dropdown-title">Regenerate As</div>
          {chatModeOptions.map(mode => (
            <div 
              key={mode.value}
              className={`mode-option mode-${mode.value} ${currentMode === mode.value ? 'active' : ''}`}
              onClick={() => handleModeSelect(mode.value)}
            >
              <mode.icon size={20} />
              <div>
                <div className="mode-title">{mode.label}</div>
                <div className="mode-description">
                  {currentMode === mode.id ? "Current Mode" : mode.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};