import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
import './Styles/variables.css'
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

  const ErrorFallback = ({ error }) => (
    <div className="error-boundary-fallback">
      <h2>Oops! Something went wrong</h2>
      <p>Please try refreshing the page or <a href="https://cognora.ca/contact">contact support</a> if the problem persists.</p>
      
      <div className="error-boundary-fallback-buttons">
        <button onClick={() => window.location.reload()}>
          Refresh Page
        </button>

        <button onClick={() => window.location.href = '/'}>
          Return Home
        </button>
      </div>
    </div>


  );

root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<ErrorFallback />}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
