import { useEffect } from 'react';
import '../../Styles/Layout/ConfirmationModal.css';
export const ConfirmationModal = ({ isOpen, onClose, onConfirm, text, text2 }) => {
    useEffect(() => {
      const handleEscape = (e) => {
        if (e.key === 'Escape') onClose();
      };
  
      if (isOpen) {
        document.addEventListener('keydown', handleEscape);
        return () => document.removeEventListener('keydown', handleEscape);
      }
    }, [isOpen, onClose]);
  
    if (!isOpen) return null;
  
    const handleOverlayClick = (e) => {
      if (e.target === e.currentTarget) onClose();
    };
  
    return (
      <div 
        className="confirmation-modal-overlay"
        onClick={handleOverlayClick}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-title"
      >
        <div className="confirmation-modal-content">
          <div className="confirmation-modal-text">
            <p>{text}</p>
            {text2 && <p>{text2}</p>}
          </div>
          <div className="confirmation-modal-actions">
            <button 
              className="confirmation-cancel-button"
              onClick={onClose}
              autoFocus
            >
              Cancel
            </button>
            <button 
              className="confirmation-confirm-button"
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  }