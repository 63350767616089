import React from 'react';
import { Link } from 'react-router-dom';
import CognoraLogoDark from '../../assets/Cognore Inverse Logo.png';
import { useTheme } from '../../App';
import '../../Styles/Partials/404.css';


export const NotFound = ( linkTo = '/') => {
  const { theme } = useTheme();
  return (
    <div className="auth-container">
      <div className="branding">
        <Link to="/" className="logo">
          <img src={theme === 'dark' ? CognoraLogoDark : CognoraLogoDark} alt="Cognora Logo" className="cognora-logo" />
        </Link>
      </div>
      <div className="auth-form">
        <h1 className="auth-title">Oops!</h1>
        <p className="auth-message">
          We couldn't find the page you're looking for. Let's get you back on track!
        </p>
        <Link to={linkTo} className="submit-button">
          Go to Home
        </Link>
      </div>
      <footer className="auth-footer">
        <Link to="/pricing">Pricing</Link>
        <span className="separator">|</span>
        <Link to="/faq">FAQ</Link>
        <span className="separator">|</span>
        <Link to="/terms-of-service">Terms of Service</Link>
        <span className="separator">|</span>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </footer>
    </div>
  );
};

export default NotFound;