import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Styles/SalesModal.css';
import { useAuth } from '../../hooks/useAuth';
import { createCheckoutSession, handleStripePortalRedirect } from '../../services/api';
import { pricingTiers } from '../Data/PricingData';
import LoadingOverlay from './LoadingOverlay';
import { Cancel01Icon, Tick01Icon } from 'hugeicons-react';

const SalesModal = ({ feature, onClose }) => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const subscription = user?.subscription;
  const [currency, setCurrency] = useState('usd');
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [isLoading, setIsLoading] = useState(false);
  const trialdays = user?.status === 'succeeded' ? '7' : null;

  useEffect(() => {
    const detectUserLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        if (data.country_code === 'CA') {
          setCurrency('cad');
        } else if (data.country_code === 'US') {
          setCurrency('usd');
        } else if (data.continent_code === 'EU') {
          setCurrency('eur');
        } else {
          setCurrency('usd'); // Default to USD for other countries
        }
      } catch (error) {
        console.error('Error detecting user location:', error);
        setCurrency('usd'); // Default to USD if there's an error
      }
    };

    detectUserLocation();
  }, []);

  const handleSubscription = async (tier, interval = billingCycle, currencyCode = currency) => {
    setIsLoading(true);
    if (!isAuthenticated) {
      navigate('/signup', { 
        state: { selectedTier: tier, interval, currency: currencyCode, trialdays } 
      });
      return;
    }

    try {
      if(subscription && subscription.tier !== "free"){
        await handleStripePortalRedirect();
        return;
      }
      const response = await createCheckoutSession(tier, interval, currencyCode);
      if (response.url) {
        window.location.href = response.url;
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const renderButton = (tier) => {
    if (!user) {
      return (
        <button 
          onClick={() => handleSubscription(tier.name.toLowerCase(), billingCycle, currency)} 
          className="upgrade-button"
        >
          {`Choose ${tier.name}`}
        </button>
      );
    }

    if (subscription?.tier && subscription.tier !== 'free') {

        return (
          <button onClick={handleStripePortalRedirect} className="upgrade-button">
            Manage Subscription
          </button>
        );
 
    }

    return (
      <button 
        onClick={() => handleSubscription(tier.name.toLowerCase(), billingCycle, currency)} 
        className="upgrade-button"
      >
        {`Choose ${tier.name}`}
      </button>
    );
  };
  return (
    isLoading ? <LoadingOverlay /> : (
      <div className="sales-modal-overlay" onClick={onClose}>
        <div className="sales-modal-content" onClick={e => e.stopPropagation()}>
          <button className="sales-modal-close" onClick={onClose}>
            <Cancel01Icon size={24} />
          </button>
          <h2>Unlock {feature}</h2>
          <p className="modal-description">Choose the plan that fits your learning needs and take your studies to the next level!</p>
          
          <div className="pricing-toggle" style={{ display: window.innerWidth < 4500 ? 'none' : 'flex' }}>
            <span className={billingCycle === 'monthly' ? 'active' : ''}>Monthly</span>
            <div className="toggle-switch">
              <input
                type="checkbox"
                id="modal-billing-toggle"
                checked={billingCycle === 'annual'}
                onChange={() => setBillingCycle(billingCycle === 'monthly' ? 'annual' : 'monthly')}
              />
              <label htmlFor="modal-billing-toggle"></label>
            </div>
            <span className={billingCycle === 'annual' ? 'active' : ''}>
              Annual
            </span>
          </div>

          <div className="plans-grid">
            {pricingTiers
              .filter(tier => tier.name === 'Ultimate')
              .map((tier) => (
                <div key={tier.name} className={`plan-card ${tier.name.toLowerCase()}`}>
                  {tier.freeTrial && user.status === 'succeeded' && (
                    <>
                      <div className="free-trial-badge">
                        {tier.freeTrial} Free Trial
                      </div>
                    </>
                  )}
                  <div className="card-header">
                    <h3>{tier.name}</h3>
                    <p className="tagline">{tier.tagline}</p>
                    <div className="price-container">
                      {billingCycle === 'annual' ? (
                        <>
                          <span className="monthly-equivalent">
                            {`${tier.price[currency].annual.charAt(0)}${(parseFloat(tier.price[currency].annual.slice(1)) / 12).toFixed(2)}`}/mo
                          </span>
                          <span className="annual-total">
                            {tier.price[currency].annual}/yr
                          </span>
                        </>
                      ) : (
                        <p className="plan-price">
                          {tier.price[currency][billingCycle]}
                          <span className="period">/{billingCycle === 'monthly' ? 'mo' : 'yr'}</span>
                        </p>
                      )}
                    </div>
                  </div>
                  <ul className="feature-list">
                    {tier.features.map((item, index) => (
                      <li key={index}><Tick01Icon size={18} /> {item}</li>
                    ))}
                  </ul>
                  {renderButton(tier)}
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  );
};

export default SalesModal;
