import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import AuthLayout from './Layout/AuthLayout';
import { useAuth } from '../../hooks/useAuth';
import { FormInput, SubmitButton, FormError } from './Layout/FormInput';

const Login = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const { login } = useAuth();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showFullForm, setShowFullForm] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const passwordRef = useRef(null);

  const locationState = useMemo(() => ({
    selectedTier: location.state?.selectedTier || 'free',
    interval: location.state?.interval || 'monthly',
    currency: location.state?.currency || 'usd',
    chatState: location.state?.chatState,
    promoCode: location.state?.promoCode,
    trialdays: location.state?.trialdays,
    returnUrl: location.state?.returnUrl
  }), [location.state]);

  const signupLink = useMemo(() => ({ 
    pathname: '/signup', 
    state: locationState
  }), [locationState]);

  useEffect(() => {
    if (showFullForm && passwordRef.current) {
      passwordRef.current.focus();
    }
  }, [showFullForm]);

  const handleChange = useCallback((e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setCredentials(prev => ({
      ...prev,
      [name]: value,
    }));
    setError('');
  }, []);

  const handleSubmit = async (e) => {

    console.log(locationState);
    e.preventDefault();
    if (isLoading) return;

    setError('');
    setIsLoading(true);

    try {
      await login(credentials.email, credentials.password);
      if (locationState.returnUrl && locationState.returnUrl.startsWith('http')) {
        window.location.href = locationState.returnUrl;
      } else {
        navigate(locationState.returnUrl || '/', { 
          state: locationState.chatState,
          replace: true 
        });
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
      setCredentials(prevState => ({ ...prevState, password: '' }));    
    }
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    if (credentials.email) {
      setShowFullForm(true);
      // Password field will be focused via useEffect
    } else {
      setError('Please enter your email address.');
    }
  };

  // Add memoized password toggle handler
  const handleTogglePassword = useCallback(() => {
    setShowPassword(prev => !prev);
  }, []);

  return (
    <AuthLayout title="Welcome Back">
      <form onSubmit={showFullForm ? handleSubmit : handleContinue}>
        <FormError message={error} />
        
        {!showFullForm ? (
          <FormInput
            key="email-input"
            label="Email Address"
            type="email"
            id="email"
            value={credentials.email}
            onChange={handleChange}
            disabled={isLoading}
            required
            placeholder="Enter Your Email Address"
          />
        ) : (
          <>
            <div className="form-group email-display-container">
              <p className="email-display">{credentials.email}</p>
              <button 
                type="button" 
                className="back-to-email"
                onClick={() => setShowFullForm(false)}
              >
                Change
              </button>
            </div>
            <FormInput
              key="password-input"
              label="Password"
              type="password"
              id="password"
              value={credentials.password}
              onChange={handleChange}
              disabled={isLoading}
              required
              ref={passwordRef}
              showPassword={showPassword}
              togglePassword={handleTogglePassword}
              placeholder="Enter Your Password"
            />
          </>
        )}

        <SubmitButton isLoading={isLoading}>
          {showFullForm ? 'Sign In' : 'Continue'}
        </SubmitButton>
      </form>

      <div className="auth-links">
        <p className="auth-link">
          <Link to="/forgot-password" className="auth-link" tabIndex={isLoading ? -1 : 0}>
            Forgot Password?
          </Link>
        </p>
        <p className="auth-link">
          Don't have an account?{' '}
          <Link state={locationState} to={signupLink} tabIndex={isLoading ? -1 : 0}>
            Sign Up
          </Link>
        </p>
      </div>
    </AuthLayout>
  );
};

export default Login;
