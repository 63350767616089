const faqs = [
    {
        id: 1,
        question: "How does StudyBuddy's AI tutor work?",
        answer: "StudyBuddy is an expertly crafted interface that leverages the power of advanced language models. It analyzes your inputs and learning patterns to provide tailored, interactive educational experiences. Think of it as having a highly intelligent tutor available 24/7, ready to assist you with personalized explanations, targeted practice questions, and customized study materials."
    },
    {
        id: 2,
        question: "Is StudyBuddy suitable for all subjects?",
        answer: "Yes! StudyBuddy covers a wide range of subjects including mathematics, sciences, humanities, and languages. Our AI is constantly learning and expanding its knowledge base."
    },
    {
        id: 3,
        question: "Can I use StudyBuddy on multiple devices?",
        answer: "Absolutely! StudyBuddy is accessible on desktop, tablet, and mobile devices. Your progress syncs across all platforms for seamless learning. Mobile app coming!."
    },
    {
        id: 4,
        question: "What payment methods do you accept?",
        answer: "We accept all major credit cards, including Visa, MasterCard, American Express, and Discover."
    },
    {
        id: 5,
        question: "How much does StudyBuddy cost?",
        answer: "We offer flexible pricing plans to suit different needs, ranging from a free tier to premium options. Check our pricing section for current rates. We also offer a free trial for new users on paid plans!"
    },
    {
        id: 6,
        question: "Can I cancel my subscription at any time?",
        answer: "Yes, you can cancel your subscription at any time. Your access will continue until the end of your current billing period."
    },
    {
        id: 7,
        question: "Is there a refund policy?",
        answer: "Refunds are dealt with on a case-by-case basis. Please reach out to our support team for assistance."
    },
    {
        id: 8,
        question: "Can I upgrade or downgrade my plan?",
        answer: "Yes, you can change your plan at any time. When upgrading, you'll have immediate access to new features. When downgrading, changes will take effect at the start of your next billing cycle."
    },
    {
        id: 9,
        question: "What are Study Spaces?",
        answer: "Study Spaces are personalized learning environments where you can organize your study materials, upload PDFs, and have focused AI conversations around specific topics or subjects. Each space can contain multiple chats, uploaded documents, and automatically generated study artifacts like summaries, code examples, and visualizations. It's like having a dedicated virtual study room for each subject or project."
    },
    {
        id: 10,
        question: "How do Study Spaces help with learning?",
        answer: "Study Spaces enhance your learning experience by: 1) Organizing related materials and conversations in one place, 2) Allowing PDF uploads for AI-assisted document analysis, 3) Generating study artifacts like summaries and explanations, 4) Enabling focused discussions around specific topics, and 5) Tracking your learning progress across different subjects."
    },
    {
        id: 11,
        question: "What's the difference between regular chats and Study Spaces?",
        answer: "While regular chats are great for quick questions and general learning, Study Spaces provide a structured environment for deeper, long-term learning. They allow you to upload study materials, maintain context across multiple conversations, and automatically organize generated study content. Think of regular chats as quick tutoring sessions and Study Spaces as dedicated virtual classrooms."
    }
];

export default faqs;