export const AutoCompleteDropdown = ({ suggestions, handleSuggestionSelect, setSelectedSuggestionIndex, selectedSuggestionIndex }) => {

    return (
        <div className="auto-complete-dropdown">
            {(suggestions?.length === 0 ? suggestions.slice(0, 4) : suggestions).map((suggestion, index) => (
                <div
                    key={index}
                    className={`auto-complete-item ${index === selectedSuggestionIndex ? 'selected' : ''}`}
                    onClick={() => handleSuggestionSelect(suggestion)}
                    onMouseEnter={() => setSelectedSuggestionIndex(index)}
                >
                    {suggestion.icon && <suggestion.icon size={16} />}
                    <span className="suggestion-category">{suggestion.category}</span>
                    <span className="suggestion-text">{suggestion.text}</span>
                </div>
            ))}
        </div>
    );
};