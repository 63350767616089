import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import '../Styles/StudySpace.css';
import { useQuery, useQueryClient } from 'react-query';
import { uploadToSpace, updateSpaceInstructions, fetchChats, updateSpaceTitle, deleteChat, removePDFFromSpace } from '../services/api';
import { useAuth } from '../hooks/useAuth';
import '../Styles/Chat.css';
import LoadingOverlay from './Partials/LoadingOverlay';
import { Add01Icon, Delete01Icon, Edit02Icon, File02Icon, Message01Icon, Settings02Icon, Upload03Icon } from 'hugeicons-react';
import { getResourceIcon } from './Chats';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { NotFound } from './Partials/404';
import { ConfirmationModal } from './Layout/ConfirmationModal';


const StudySpace = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const fileInputRef = useRef(null);

  const [navigatingChatId, setNavigatingChatId] = useState(null);

  const handleChatNavigation = useCallback((chatId) => {
    if (navigatingChatId) return; // Prevent multiple clicks
    setNavigatingChatId(chatId);
    navigate(`/ss/${chatId}`);
  }, [navigate, navigatingChatId]);

  const [isEditingInstructions, setIsEditingInstructions] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState({
    isUploading: false,
    progress: 0,
    totalFiles: 0,
    currentFile: '',
    completedFiles: [],
    failedFiles: []
  });

  const [artifacts, setArtifacts] = useState([]);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isInstructionsExpanded, setIsInstructionsExpanded] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [chatToDelete, setChatToDelete] = useState(null);
  const [pdfToDelete, setPdfToDelete] = useState(null);

  const [modal, setModal] = useState({ isOpen: false, text: '', onConfirm: null, text2: null });

  const openModal = (text, onConfirm, text2 = null) => {
    setModal({ isOpen: true, text, onConfirm, text2 });
  };

  const closeModal = () => {
    setModal({ isOpen: false, text: '', onConfirm: null, text2: null });
  };

  const { data: spaces = [], isLoading: spacesLoading } = useQuery(
    'spaces',
    () => queryClient.getQueryData('spaces') || [],
    { enabled: false }
  );

  const space = useMemo(() => 
    spaces.find(s => String(s.id) === String(id)), 
    [spaces, id]
  );

  const [title, setTitle] = useState(space?.title || '');
  const [instructions, setInstructions] = useState(space?.instruction_text || '');

  const { data: spaceChatsData, isLoading: chatsLoading } = useQuery(
    ['space-chats', id],
    () => {
      const allChatsData = queryClient.getQueryData(['chats', user?.id]);
      if (!allChatsData) {
        return fetchChats().then(data => ({
          ...data,
          chats: data.chats.filter(chat => {
            const chatSpaceId = chat.spaceId?.toString() || chat.space_id?.toString();
            return chatSpaceId === id?.toString();
          })
        }));
      }
      
      return {
        ...allChatsData,
        chats: allChatsData.chats.filter(chat => {
          const chatSpaceId = chat.spaceId?.toString() || chat.space_id?.toString();
          return chatSpaceId === id?.toString();
        })
      };
    },
    {
      enabled: !!id && !!user,
      initialData: () => {
        const allChatsData = queryClient.getQueryData(['chats', user?.id]);
        if (!allChatsData) return undefined;
        
        return {
          ...allChatsData,
          chats: allChatsData.chats.filter(chat => {
            const chatSpaceId = chat.spaceId?.toString() || chat.space_id?.toString();
            return chatSpaceId === id?.toString();
          })
        };
      }
    }
  );

  // File parsing and management
  const parsePdfContentText = (contentText) => {
    if (!contentText) return [];
    
    const pdfEntries = contentText.split('<PDF START>').filter(entry => entry.trim());
    return pdfEntries.map(entry => {
      const titleMatch = entry.match(/\[PDF TITLE: (.*?)\]/);
      const linkMatch = entry.match(/\[LINK: (.*?)\]/);
      
      if (titleMatch && linkMatch) {
        return {
          name: titleMatch[1],
          url: linkMatch[1],
          uploadedAt: new Date(linkMatch[1].split('/')[1].split('-')[0]),
          size: 'PDF'
        };
      }
      return null;
    }).filter(Boolean);
  };

  // Artifacts processing
  const processArtifacts = useCallback((artifactContent) => {
    if (!artifactContent) return;
    
    const artifactsMatch = artifactContent.match(/<artifacts>([\s\S]*?)<\/artifacts>/g);
    
    if (artifactsMatch) {
      const newArtifacts = [];
      
      artifactsMatch.forEach((artifact) => {
        const chatIdMatch = artifact.match(/<chatid>(.*?)<\/chatid>/);
        const chatId = chatIdMatch ? chatIdMatch[1] : null;

        // Process essays
        const essayMatch = artifact.match(/<essay>([\s\S]*?)<\/essay>/);
        if (essayMatch) {
          const essayContent = essayMatch[1];
          const titleMatch = essayContent.match(/<title>(.*?)<\/title>/);
          const contentMatch = essayContent.match(/<content>([\s\S]*?)<\/content>/);
          
          if (titleMatch && contentMatch) {
            newArtifacts.push({
              type: 'essay',
              chatId: chatId,
              title: titleMatch[1],
              content: contentMatch[1],
              timestamp: new Date().toISOString()
            });
          }
        }

        // Process code artifacts
        const codeMatch = artifact.match(/<code.*?>([\s\S]*?)<\/code>/);
        if (codeMatch) {
          const codeContent = codeMatch[1];
          const languageMatch = codeMatch[0].match(/language="(.*?)"/);
          const titleMatch = codeMatch[0].match(/title="(.*?)"/);
          
          if (languageMatch && titleMatch) {
            newArtifacts.push({
              type: 'code',
              chatId: chatId,
              language: languageMatch[1],
              title: titleMatch[1],
              content: codeContent,
              timestamp: new Date().toISOString()
            });
          }
        }

        // Process visualization artifacts
        const vizMatch = artifact.match(/<visualization.*?>([\s\S]*?)<\/visualization>/);
        if (vizMatch) {
          const vizContent = vizMatch[1];
          const titleMatch = vizMatch[0].match(/title="(.*?)"/);
          
          if (titleMatch) {
            try {
              JSON.parse(vizContent);
              newArtifacts.push({
                type: 'visualization',
                chatId: chatId,
                title: titleMatch[1],
                content: vizContent,
                timestamp: new Date().toISOString()
              });
            } catch (error) {
              console.error('Invalid visualization JSON:', error);
            }
          }
        }
      });

      setArtifacts(prev => [...prev, ...newArtifacts]);
    }
  }, []);

  // Effects
  useEffect(() => {
    if (space?.pdf_content_text) {
      const parsedFiles = parsePdfContentText(space.pdf_content_text);
      setFiles(parsedFiles);
    }
  }, [space]);

  useEffect(() => {
    if (space?.instruction_text) {
      setInstructions(space.instruction_text);
    }
  }, [space]);

  useEffect(() => {
    if (space?.Artifacts_text) {
      setArtifacts([]);
      processArtifacts(space.Artifacts_text);
    }
  }, [space, processArtifacts]);

  // Move renderResource before any conditional returns f
  const renderResource = useCallback((artifact) => (
    <div 
      key={`${artifact.type}-${artifact.title}-${artifact.chatId}`}
      className="resource-item"
      onClick={() => {
        if (artifact.chatId) {
          navigate(`/ss/${artifact.chatId}?resource=${artifact.title}`, {
            state: { title: artifact.title }
          });
        }
      }}
    >
      <span className="resource-icon">
        {getResourceIcon(artifact.type)}
      </span>
      <div className="resource-info">
        <h3 className="resource-title">{artifact.title}</h3>
      </div>
      <FaChevronRight size={15} className="resource-arrow" />
    </div>
  ), [navigate]);

  // Add this near the top with other useEffect hooks
  useEffect(() => {
    if (artifacts.length > 0) {
      const existingTitles = queryClient.getQueryData('artifactTitles') || [];
      const newTitles = artifacts.map(artifact => ({
        title: artifact.title,
        type: artifact.type,
        chatId: artifact.chatId
      }));
      
      const mergedTitles = [...existingTitles, ...newTitles]
        .filter((title, index, self) => 
          index === self.findIndex(t => 
            t.title === title.title && t.chatId === title.chatId
          )
        );
      
      queryClient.setQueryData('artifactTitles', mergedTitles);
    }
  }, [artifacts, queryClient]);

  // Add this useEffect to handle initial textarea height
  useEffect(() => {
    if (isEditingInstructions) {
      const textarea = document.querySelector('.instructions-editor');
      if (textarea) {
        adjustTextareaHeight(textarea);
      }
    }
  }, [isEditingInstructions]);

  if (spacesLoading || chatsLoading) {
    return <LoadingOverlay message="Loading study space..." />;
  }

  if (!space) {
    return (
      <NotFound linkTo="/study-spaces" />
    );
  }



  const handleFileUpload = async (event) => {
    const knowledgePercentage = Math.min((space?.knowledge_count || 0) / 30000 * 100, 100);
    if (knowledgePercentage >= 100) {
      // showCustomToast('error', 'Knowledge capacity is full. Please create a new study space.');
      return;
    }

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
    const files = Array.from(event.target.files).filter(file => {
      if (file.type !== 'application/pdf') {
        // showCustomToast('error', `File "${file.name}" must be a PDF`);
        return false;
      }
      if (file.size > MAX_FILE_SIZE) {
        // showCustomToast('error', `File "${file.name}" exceeds 5MB limit`);
        return false;
      }
      return true;
    });

    if (files.length === 0) {
      // showCustomToast('error', 'Please select valid PDF files (max 5MB each)');
      return;
    }

    setUploadStatus({
      isUploading: true,
      progress: 0,
      totalFiles: files.length,
      currentFile: files[0].name,
      completedFiles: [],
      failedFiles: []
    });

    try {
      const response = await uploadToSpace(id, files, (progress) => {
        setUploadStatus(prev => ({
          ...prev,
          progress
        }));
      });

      // Show success message for completed uploads
      if (response.results.some(r => r.success)) {
        // showCustomToast('success', `Successfully uploaded ${response.results.filter(r => r.success).length} file(s)`);
      }

      // Show error message for failed uploads
      if (response.results.some(r => !r.success)) {
        // showCustomToast('error', `Failed to upload ${response.results.filter(r => !r.success).length} file(s)`);
      }

      // Handle the response which includes results for each file
      const { results } = response;
      
      setUploadStatus(prev => ({
        ...prev,
        completedFiles: results.filter(r => r.success).map(r => ({
          name: r.fileName,
          url: r.pdfUrl,
          uploadedAt: new Date(),
          size: 'PDF'
        })),
        failedFiles: results.filter(r => !r.success).map(r => ({
          name: r.fileName,
          error: r.error
        }))
      }));

      // Update files list with successful uploads
      const successfulUploads = results.filter(r => r.success);
      if (successfulUploads.length > 0) {
        setFiles(prevFiles => [
          ...prevFiles,
          ...successfulUploads.map(r => ({
            name: r.fileName,
            url: r.pdfUrl,
            uploadedAt: new Date(),
            size: 'PDF'
          }))
        ]);
      }

      // Refetch spaces to update the cache
      queryClient.setQueryData(['spaces'], (oldData) => {
        if (!oldData) return oldData;
        return oldData.map(space => {
          if (space.id === id) {
            return {
              ...space,
              knowledge_count: response.knowledge_count
            };
          }
          return space;
        });
      });
      queryClient.invalidateQueries('spaces');

    } catch (error) {
      console.error('Error uploading files:', error);
      // showCustomToast('error', 'Failed to upload files');
    } finally {
      setUploadStatus(prev => ({
        ...prev,
        isUploading: false,
        progress: 0
      }));
    }
  };

  const handleCreateChat = () => {
    navigate(`/ss/new?spaceId=${id}`);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const handleUpdateInstructions = async () => {
    const originalInstructions = space?.instruction_text;
    
    // Optimistically update the cache
    queryClient.setQueryData('spaces', (oldData) => {
      return oldData.map(space => {
        if (space.id === id) {
          return {
            ...space,
            instruction_text: instructions
          };
        }
        return space;
      });
    });

    try {
      await updateSpaceInstructions(id, instructions);
      setIsEditingInstructions(false);
      // showCustomToast('success', 'Goals & objectives updated successfully');
    } catch (error) {
      console.error('Failed to update instructions:', error);
      // Revert cache to original state
      queryClient.setQueryData('spaces', (oldData) => {
        return oldData.map(space => {
          if (space.id === id) {
            return {
              ...space,
              instruction_text: originalInstructions
            };
          }
          return space;
        });
      });
      // showCustomToast('error', 'Failed to update goals & objectives');
      setInstructions(originalInstructions); // Reset form state
    }
  };

  const renderUploadProgress = () => (
    <div className="pdf-processing-message">
      <div className="processing-content">
        <div className="processing-icon">
          <File02Icon size={20} className="animate-pulse" />
        </div>
        <div className="processing-text">
          <h4>Uploading Study Materials</h4>
          <p>
            {uploadStatus.currentFile 
              ? `Uploading ${uploadStatus.currentFile}`
              : uploadStatus.progress === 100
              ? 'Processing files...'
              : 'Preparing your files...'}
          </p>
        </div>
      </div>
    </div>
  );

  // Add this function near your other helper functions
  const adjustTextareaHeight = (element) => {
    element.style.height = 'auto';
    element.style.height = `${Math.min(element.scrollHeight, 400)}px`; // Max height of 400px
  };

  const handleUpdateTitle = async () => {
    const originalTitle = space?.title;

    if (title.length < 1) {
      setTitle(originalTitle);
      return;
    }
    
    // Optimistically update the cache
    queryClient.setQueryData('spaces', (oldData) => {
      return oldData.map(space => {
        if (space.id === id) {
          return {
            ...space,
            title: title
          };
        }
        return space;
      });
    });

    try {
      await updateSpaceTitle(id, title);
      setIsEditingTitle(false);
      // showCustomToast('success', 'Space title updated successfully');
    } catch (error) {
      console.error('Failed to update title:', error);
      // Revert cache to original state
      queryClient.setQueryData('spaces', (oldData) => {
        return oldData.map(space => {
          if (space.id === id) {
            return {
              ...space,
              title: originalTitle
            };
          }
          return space;
        });
      });
      // showCustomToast('error', 'Failed to update title. Please try again.');
      setTitle(originalTitle); // Reset form state
    }
  };

  const handleDeleteChat = async (chatId) => {
    try {
      await deleteChat(chatId);
      
      // Update the cache by filtering out the deleted chat
      queryClient.setQueryData(['space-chats', id], (oldData) => ({
        ...oldData,
        chats: oldData.chats.filter(chat => chat.id !== chatId)
      }));

      queryClient.setQueryData(['chats', user?.id], (oldData) => ({
        ...oldData,
        chats: oldData.chats.filter(chat => chat.id !== chatId)
      }));

      // showCustomToast('success', 'Chat deleted successfully');
      setDeleteModalOpen(false);
      setChatToDelete(null);
    } catch (error) {
      console.error('Failed to delete chat:', error);
      // showCustomToast('error', 'Failed to delete chat. Please try again.');
    }
  };

  // Add this near other file-related sections in the Files Section
  const renderKnowledgeTracker = () => {
    const knowledgePercentage = Math.min((space?.knowledge_count || 0) / 30000 * 100, 100);
    const isFull = knowledgePercentage >= 100;
    
    return (
      <div className="knowledge-tracker">
        <div className="knowledge-tracker-header">
          <span>Knowledge Capacity</span>
          <span>{Math.round(knowledgePercentage)}%{isFull && ' (Full)'}</span>
        </div>
        <div className="knowledge-tracker-bar">
          <div 
            className={`knowledge-tracker-progress ${isFull ? 'full' : ''}`}
            style={{ width: `${knowledgePercentage}%` }}
          />
        </div>
      </div>
    );
  };

  const handleRemovePDF = async () => {
    if (!pdfToDelete) return;
    
    try {
      const response = await removePDFFromSpace(id, pdfToDelete.url);
      
      // Update files state
      setFiles(prevFiles => prevFiles.filter(file => file.url !== pdfToDelete.url));
      
      // Update spaces cache to remove the PDF content
      queryClient.setQueryData('spaces', (oldData) => {
        return oldData.map(space => {
          if (space.id === id) {
            // Find and remove the PDF content block from pdf_content_text
            const pdfPattern = new RegExp(
              `<PDF START>\\n\\[PDF TITLE: [^\\]]+\\]\\n\\[LINK: ${pdfToDelete.url.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\][\\s\\S]*?<PDF END>\\n*`,
              'g'
            );
            
            return {
              ...space,
              pdf_content_text: (space.pdf_content_text || '').replace(pdfPattern, '').trim(),
              knowledge_count: response.knowledge_count
            };
          }
          return space;
        });
      });

      // showCustomToast('success', 'PDF removed successfully');
    } catch (error) {
      console.error('Failed to remove PDF:', error);
      // showCustomToast('error', 'Failed to remove PDF. Please try again.');
    } finally {
      setPdfToDelete(null);
    }
  };

  return (
    <div className="study-space-page">

      {/* {showToast && (
        <Toast 
          message={toastMessage} 
          type={toastType} 
          onClose={() => setShowToast(false)} 
        />
      )} */}
      {/* Header */}
      <div className="study-space-header">
        <button 
          className="back-button"
          onClick={() => navigate('/study-spaces')}
        >
          <FaChevronLeft size={20} />
          Back to Spaces
        </button>
        <div className="header-content">
          <div className="header-left">
            <NavLink to="/" className="return-to-chats">
              <Message01Icon size={20} />
              <span>Chats</span>
            </NavLink>
            {isEditingTitle ? (
              <div className="title-editor">
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleUpdateTitle();
                    } else if (e.key === 'Escape') {
                      handleUpdateTitle();
                    }
                  }}
                  onBlur={() => {
                    if (title !== space?.title) {
                      handleUpdateTitle();
                    }
                    setIsEditingTitle(false);
                  }}
                  placeholder="Enter space title..."
                  autoFocus
                />
              </div>
            ) : (
              <h1 
                onClick={() => setIsEditingTitle(true)}
                className="editable-title"
              >
                {space.title}
                <Edit02Icon size={16} className="edit-icon" />
              </h1>
            )}
          </div>
          {window.innerWidth > 500 && (
            <div className="header-right">
              <button 
                className="icon-button"
                onClick={() => navigate('/settings')}
              >
                <Settings02Icon />
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Main Content */}
      <div className="study-space-content">
        {/* Left Section - Instructions and Files */}
        <div className="space-sidebar">
          {/* Instructions Section */}
          <section 
            className="instructions-section"
            data-expanded={isInstructionsExpanded}
          >
            <div 
              className="section-header"
              onClick={() => setIsInstructionsExpanded(!isInstructionsExpanded)}
            >
              <h2>Goals & Objectives</h2>
            </div>
            {isEditingInstructions ? (
              <div className="instructions-editor-container">
                <textarea
                  value={instructions}
                  onChange={(e) => {
                    setInstructions(e.target.value);
                    adjustTextareaHeight(e.target);
                  }}
                  className="instructions-editor"
                  placeholder="What would you like to achieve in this study space? Add your goals, objectives, and any important notes..."
                  autoFocus
                />
                <div className="editor-actions">
                  <button 
                    className="cancel-button"
                    onClick={() => {
                      setIsEditingInstructions(false);
                      setInstructions(space?.instruction_text || '');
                    }}
                  >
                    Cancel
                  </button>
                  <button 
                    className="cancel-button save"
                    onClick={handleUpdateInstructions}
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <div 
                className="instructions-content"
                onClick={() => {
                  setIsEditingInstructions(true);
                  setIsInstructionsExpanded(true);
                }}
                style={{ cursor: 'pointer' }}
              >
                {instructions.split('\n').map((line, i) => (
                  <p key={i}>{line}</p>
                ))}
              </div>
            )}
          </section>

          {/* Files Section */}
          <section className="files-section">
            <div className="section-header">
              <h2>Study Materials</h2>
              <button 
                className="upload-button"
                onClick={() => {
                  const knowledgePercentage = Math.min((space?.knowledge_count || 0) / 30000 * 100, 100);
                  if (knowledgePercentage >= 100) {
                    // showCustomToast('error', 'Knowledge capacity is full. Please create a new study space.');
                    return;
                  }
                  fileInputRef.current?.click();
                }}
              >
                <Upload03Icon size={16} />
                Upload
              </button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                style={{ display: 'none' }}
                multiple
              />
            </div>
            {renderKnowledgeTracker()}
            <div className="resources-list">
              {uploadStatus.isUploading && renderUploadProgress()}
              {files.map((file, index) => (
                <div key={index} className="file-item">
                  <div className="file-icon">
                    <File02Icon size={15} />
                  </div>
                  <div className="file-info">
                    <a 
                      href={file.url} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="file-name"
                    >
                      {file.name}
                    </a>
                  </div>
                  <div className="file-actions">
                    <button
                      className="delete-file-button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setPdfToDelete(file);
                        openModal(
                          "Are you sure you want to delete this PDF?",
                          () => {
                            handleRemovePDF();
                          }
                        );
                      }}
                      aria-label="Delete PDF"
                    >
                      <Delete01Icon size={14} />
                    </button>
                  </div>
                </div>
              ))}

                            {/* Artifacts */}
              {artifacts.map((artifact, index) => renderResource(artifact))}
              {files.length === 0 && (
                <div className="empty-files-state">
                  <p>No files uploaded yet</p>
                </div>
              )}
            </div>
          </section>
        </div>

        {/* Right Section - Chats */}
        <div className="chats-section">
          <div className="section-header">
            <h2>{window.innerWidth < 700 ? 'Chats' : 'Conversations'}</h2>
            <button 
              className="create-chat-btn"
              onClick={handleCreateChat}
            >
              <Add01Icon size={16} />
              New Chat
            </button>
          </div>
          
          {chatsLoading ? (
            <div className="loading-state">Loading conversations...</div>
          ) : (spaceChatsData?.chats || []).length === 0 ? (
            <div className="empty-chats-state">
              <div className="empty-chats-content">
                <h2>No Conversations Yet</h2>
                <p>Start your first conversation in this study space to begin learning.</p>
                <button 
                  className="create-chat-btn-empty"
                  onClick={handleCreateChat}
                >
                  <Add01Icon size={16} />
                  Start Your First Chat
                </button>
              </div>
            </div>
          ) : (
            <div className="chats-grid">
              {(spaceChatsData?.chats || [])
                .sort((a, b) => {
                  const dateA = new Date(a.last_modified || a.lastActive);
                  const dateB = new Date(b.last_modified || b.lastActive);
                  return dateB - dateA;
                })
                .map(chat => (
                  <div 
                    key={chat.id} 
                    onClick={() => handleChatNavigation(chat.hashed_id)}
                    className={`chat-card ${navigatingChatId === chat.id ? 'navigating' : ''}`}
                  >
                    <div 
                      className="chat-card-content"
                      style={{ cursor: navigatingChatId ? 'wait' : 'pointer' }}
                    >
                      <div className="chat-card-header">
                        <Message01Icon size={18} />
                        <h3>{window.innerWidth < 500 ? chat.title.slice(0, 20) + (chat.title.length > 20 ? '...' : '') : chat.title}</h3>
                      </div>
                      <div className="chat-card-footer">
                        <span>Active {formatDate(chat.last_modified || chat.lastActive)}</span>
                      </div>
                    </div>
                    <button 
                      className="delete-button"
                      disabled={navigatingChatId === chat.id}
                      onClick={(e) => {
                        e.stopPropagation();
                        setChatToDelete(chat);
                        setDeleteModalOpen(true);
                      }}
                    >
                      <Delete01Icon size={16} />
                    </button>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>

      {deleteModalOpen && (
        <div className="delete-confirm-modal">
          <div className="delete-confirm-content">
            <h3>Delete Chat</h3>
            <p>Are you sure you want to delete "{chatToDelete?.title}"? This action cannot be undone.</p>
            <div className="delete-confirm-actions">
              <button 
                className="cancel"
                onClick={() => {
                  setDeleteModalOpen(false);
                  setChatToDelete(null);
                }}
              >
                Cancel
              </button>
              <button 
                className="delete"
                onClick={() => handleDeleteChat(chatToDelete.id)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <ConfirmationModal
        isOpen={modal.isOpen}
        text={modal.text}
        text2={modal.text2}
        onConfirm={modal.onConfirm}
        onClose={closeModal}
      />
    </div>
  );
};

export default StudySpace;