  /* eslint-disable no-unused-vars */
  import React, { useState, useMemo, useEffect, useRef } from 'react';
  import { NavLink, useNavigate } from 'react-router-dom';
  import { useQuery, useQueryClient } from 'react-query';
  import { useAuth } from '../hooks/useAuth';
  import '../Styles/StudySpaces.css';
  import { createSpace, fetchChats, uploadToSpace, getSpaces } from '../services/api';
  import RecentChats from './Layout/RecentChats';
  import Toast from './Partials/Toast';
  import LoadingOverlay from './Partials/LoadingOverlay';
  import { Add02Icon, Book02Icon, File02Icon, Message01Icon, Search01Icon, Settings02Icon } from 'hugeicons-react';
  import { FaChevronRight } from 'react-icons/fa';

  const CreateSpaceModal = ({ onClose }) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
      title: '',
      instructions: '',
      files: []
    });
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [loading, setLoading] = useState(false);
    const [draftKey] = useState(`study-space-draft-${Date.now()}`);
    const modalRef = useRef(null);
    const [uploadStatus, setUploadStatus] = useState({
      isUploading: false,
      progress: 0,
      totalFiles: 0,
      currentFile: '',
      completedFiles: [],
      failedFiles: []
    });
    const [spaceCreated, setSpaceCreated] = useState(false);

    const isUploadInProgress = uploadStatus.isUploading || loading;

    useEffect(() => {
      const draft = localStorage.getItem(draftKey);
      if (draft) {
        setFormData(JSON.parse(draft));
      }
    }, [draftKey]);

    useEffect(() => {
      const saveTimeout = setTimeout(() => {
        localStorage.setItem(draftKey, JSON.stringify(formData));
      }, 1000);
      return () => clearTimeout(saveTimeout);
    }, [formData, draftKey]);

    useEffect(() => {
      const modalElement = modalRef.current;

      const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          if (step === 1 && formData.title.trim()) {
            setStep(2);
          } else if (step === 2 && formData.instructions.trim()) {
            setStep(3);
          }
        } else if (e.key === 'Escape') {
          onClose();
        }
      };

      modalElement?.addEventListener('keydown', handleKeyDown);
      return () => modalElement?.removeEventListener('keydown', handleKeyDown);
    }, [step, formData, onClose]);

    const handleNext = () => {
      if (step < 3) setStep(step + 1);
    };

    const handleBack = () => {
      if (step > 1) setStep(step - 1);
    };

    const handleSubmit = async () => {
      if (formData.title.trim() && formData.instructions.trim()) {
        setLoading(true);
        try {
          const newSpace = await createSpace(formData.title, formData.instructions);
          setSpaceCreated(true);
          await queryClient.refetchQueries('spaces');
          
          if (formData.files.length > 0) {
            setUploadStatus({
              isUploading: true,
              progress: 0,
              totalFiles: formData.files.length,
              currentFile: formData.files[0].name,
              completedFiles: [],
              failedFiles: []
            });

            try {
              await uploadToSpace(newSpace.id, formData.files, (progress, currentFile) => {
                setUploadStatus(prev => ({
                  ...prev,
                  progress,
                  currentFile: currentFile || prev.currentFile
                }));
              });
            } catch (uploadError) {
              console.error('Error uploading files:', uploadError);
              // showCustomToast('error', 'Some files failed to upload, but your space was created.');
            }
          }
          
          localStorage.removeItem(draftKey);
          await queryClient.refetchQueries('spaces');
          onClose();
          navigate(`/study-spaces/${newSpace.id}`);
        } catch (error) {
          console.error('Error creating space:', error);
          // showCustomToast('error', 'Failed to create space. Please try again.');
        } finally {
          setLoading(false);
          setUploadStatus(prev => ({
            ...prev,
            isUploading: false,
            progress: 0
          }));
        }
      }
    };

    const handleFileUpload = (e) => {
      const MAX_FILE_SIZE = 5 * 1024 * 1024;
      const files = Array.from(e.target.files).filter(file => {
        if (file.type !== 'application/pdf') {
          // showCustomToast('error', `File "${file.name}" must be a PDF`);
          return false;
        }
        if (file.size > MAX_FILE_SIZE) {
          // showCustomToast('error', `File "${file.name}" exceeds 5MB limit`);
          return false;
        }
        return true;
      });

      if (files.length > 0) {
        setFormData(prev => ({
          ...prev,
          files: [...prev.files, ...files]
        }));
        // showCustomToast('success', `Added ${files.length} file(s) to upload queue`);
      }
    };

    const renderUploadProgress = () => (
      <div className="pdf-processing-message">
        <div className="processing-content">
          <div className="processing-icon">
            <File02Icon size={20} className="animate-pulse" />
          </div>
          <div className="processing-text">
            <h4>
              {spaceCreated ? 'Space Created Successfully!' : 'Creating Space...'}
            </h4>
            <p>
              {uploadStatus.isUploading
                ? `Uploading ${uploadStatus.currentFile}`
                : uploadStatus.progress === 100
                ? 'Processing files...'
                : 'Preparing your study space...'}
            </p>
          </div>
        </div>
      </div>
    );

    const showCustomToast = (type, message) => {
      setToastType(type);
      setToastMessage(message);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    };

    return (
      <div className="create-space-modal-overlay">
        <div 
          className={`create-space-modal-content ${isUploadInProgress ? 'uploading' : ''}`}
          ref={modalRef}
          tabIndex={-1}
        >
          <div className="create-space-modal-header">
            <h2>Create New Space</h2>
            <button className="close-button" onClick={onClose}>×</button>
          </div>

          <div className="create-space-progress">
            {[1, 2, 3].map(i => (
              <div 
                key={i}
                className={`progress-step ${i === step ? 'active' : ''} ${i < step ? 'completed' : ''}`}
              >
                <div className="step-number">{i}</div>
                <span className="step-label">
                  {i === 1 ? 'Title' : i === 2 ? 'Goals & Objectives' : 'Resources'}
                </span>
              </div>
            ))}
          </div>

          <div className="create-space-modal-body">
            {step === 1 && (
              <div className="create-space-form-step">
                <h3 className="create-space-form-title">What would you like to explore?</h3>
                <input
                  type="text"
                  placeholder="Enter a title for your study space..."
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  className="modal-input"
                  autoFocus
                  minLength={3}
                  disabled={isUploadInProgress}
                />
                <div className="validation-message">
                  {formData.title.length < 3 && 'Title should be at least 3 characters'}
                </div>
                <button 
                  className="primary-button"
                  onClick={handleNext}
                  disabled={!formData.title.trim() || formData.title.length < 3}
                >
                  Continue
                </button>
              </div>
            )}

            {step === 2 && (
              <div className="create-space-form-step">
                <h3>What would you like to achieve?</h3>
                <textarea
                  placeholder="Describe your learning objectives and what you'd like to accomplish..."
                  value={formData.instructions}
                  onChange={(e) => setFormData({ ...formData, instructions: e.target.value })}
                  className="modal-input"
                  rows={5}
                  autoFocus
                  minLength={10}
                  disabled={isUploadInProgress}
                />
                <div className="validation-message">
                  {formData.instructions.length < 10 && 'Goals & Objectives should be at least 10 characters'}
                </div>
                <div className="button-group">
                  <button 
                    className="secondary-button"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <button 
                    className="primary-button"
                    onClick={handleNext}
                    disabled={!formData.instructions.trim() || formData.instructions.length < 10}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}

            {step === 3 && (
              <div className="create-space-form-step">
                <h3>Add Initial Resources (Optional)</h3>
                <div className="file-upload-area">
                  <input
                    type="file"
                    multiple
                    onChange={handleFileUpload}
                    className="file-input"
                    id="file-upload"
                    disabled={isUploadInProgress}
                  />
                  <label htmlFor="file-upload" className="file-upload-label">
                    <Add02Icon size={24} />
                    <span>Click to upload (max 5MB per file)</span>
                  </label>
                </div>
                
                {formData.files.length > 0 && (
                  <div className="uploaded-files">
                    {formData.files.map((file, index) => (
                      <div key={index} className="uploaded-file">
                        <File02Icon size={16} />
                        <span>{file.name}</span>
                        <button
                          className="remove-file"
                          onClick={() => setFormData(prev => ({
                            ...prev,
                            files: prev.files.filter((_, i) => i !== index)
                          }))}
                        >
                          ×
                        </button>
                      </div>
                    ))}
                  </div>
                )}

                {!uploadStatus.isUploading && renderUploadProgress()}

                <div className="button-group">
                  <button 
                    className="secondary-button"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <button 
                    className="primary-button"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="loading-spinner">
                        <div className="loading-dots">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </span>
                    ) : (
                      'Create Space'
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        
        {showToast && (
        <Toast 
          message={toastMessage} 
          type={toastType} 
          onClose={() => setShowToast(false)} 
        />
        )}
      </div>
    );
  };

  const StudySpaces = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [searchTerm, setSearchTerm] = useState('');
    const [isCreating, setIsCreating] = useState(false);

    const { data: spaces = [], isLoading: isSpacesLoading } = useQuery(
      'spaces',
      () => {
        const cachedSpaces = queryClient.getQueryData('spaces');
        if (cachedSpaces) return cachedSpaces;
        return getSpaces();
      },
      {
        enabled: !!user,
        initialData: () => queryClient.getQueryData('spaces') || [],
        onError: () => queryClient.invalidateQueries('spaces')
      }
    );

    const { data: chatsData, isLoading: isChatsLoading } = useQuery(
      ['chats', user?.id],
      () => {
        const cachedChats = queryClient.getQueryData(['chats', user?.id]);
        if (cachedChats) return cachedChats;
        return fetchChats();
      },
      {
        enabled: !!user,
        initialData: () => queryClient.getQueryData(['chats', user?.id]) || { chats: [] },
        onError: () => queryClient.invalidateQueries(['chats', user?.id])
      }
    );

    const filteredSpaces = useMemo(() => {
      // First, get the most recent chat timestamp for each space
      const spaceLastActivity = {};
      chatsData?.chats?.forEach(chat => {
        const spaceId = chat.space_id?.toString();
        if (spaceId) {
          const chatTimestamp = new Date(chat.last_modified || chat.lastActive);
          if (!spaceLastActivity[spaceId] || chatTimestamp > spaceLastActivity[spaceId]) {
            spaceLastActivity[spaceId] = chatTimestamp;
          }
        }
      });

      return spaces
        .sort((a, b) => {
          const aTimestamp = spaceLastActivity[a.id] || new Date(0);
          const bTimestamp = spaceLastActivity[b.id] || new Date(0);
          return bTimestamp - aTimestamp;
        })
        .filter(space => 
          space.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          space.description?.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [spaces, searchTerm, chatsData?.chats]);

    if (isSpacesLoading || isChatsLoading) {
      return (
        <>
          <LoadingOverlay message="Loading study spaces..." isLoading={true} />
          <div className="loading-state">
            <div className="loading-header" />
            <div className="loading-cards">
              {[1, 2, 3, 4, 5, 6].map((i) => (
                <div key={i} className="loading-card" />
              ))}
            </div>
          </div>
        </>
      );
    }

    return (
      <div className="study-spaces-container">
        <div className="study-spaces-header">
          <div className="header-left">
            <NavLink to="/" className="return-to-chats">
              <Message01Icon size={20} />
              <span>Chats</span>
            </NavLink>
            <h1>Study Spaces</h1>
          </div>
          <div className="header-actions">
            <button 
              className="create-space-btn" 
              onClick={() => setIsCreating(true)}
            >
              <Add02Icon size={16} />
              New Space
            </button>
            <button 
              className="icon-button"
              onClick={() => navigate('/settings')}
            >
              <Settings02Icon />
            </button>

          </div>
        </div>

        <div className="search-bar">
          <Search01Icon size={18} className="search-icon" />
          <input
            type="text"
            placeholder="Search study spaces..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {filteredSpaces.length === 0 ? (
          <div className="empty-spaces-state">
            <div className="empty-spaces-content">
              <h2>No Study Spaces Yet</h2>
              <p>Create your first study space to organize your learning materials and conversations.</p>
              <button 
                className="create-space-btn-empty" 
                onClick={() => setIsCreating(true)}
              >
                <Add02Icon size={16} />
                Create Your First Space
              </button>
            </div>
          </div>
        ) : (
          <div className="study-spaces-grid">
            {filteredSpaces.map(space => (
              <NavLink 
                key={space.id} 
                to={`/study-spaces/${space.id}`}
                className="study-space-card"
              >
                <div className="space-header">
                  <Book02Icon size={20} />
                  <h3>{space.title}</h3>
                </div>
                <div className="space-footer">
                  <FaChevronRight size={16} className="arrow-icon" />
                </div>
              </NavLink>
            ))}
          </div>
        )}

        {user && (
          <>
            {chatsData?.chats && chatsData.chats.length > 0 && (
              <RecentChats chats={chatsData.chats} />
            )}
          </>
        )}

        {isCreating && (
          <CreateSpaceModal onClose={() => setIsCreating(false)} />
        )}
      </div>
    );
  };

  export default StudySpaces;
