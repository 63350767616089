import React from 'react';
import '../../Styles/Partials/ScreenSizeWarning.css';

const ScreenSizeWarning = () => {
  return (
    <div className="screen-size-warning">
      <h1 className="warning-title">Screen Size Too Small</h1>
    </div>
  );
};

export default ScreenSizeWarning;
