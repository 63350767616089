import React, { useState, useEffect, useRef, useCallback, useMemo, memo } from 'react';
import { useParams, useNavigate, Link, useLocation, useSearchParams } from 'react-router-dom';// Added X icon, Globe icon, and Calendar icon, and History icon, and Search icon, and LogOut icon
import Sidebar from './Partials/Sidebar';
import '../Styles/Chat.css';
import '../Styles/katex.css';
import '../Styles/TextRenderer.css';
import ResourcesSection from './Partials/Resources';
import { fetchMessages, createSpaceChat, updateProfile, selectComparisonMessage, editMessageActual, addChatToSpace, reportMessage, sendMessage, createNewChat, regenerateMessage, sendGuestMessage, addArtifactToSpace, convertPdfToText, convertPdfToTextOCR } from '../services/api';
import { generateAutoCompleteSuggestions } from './Data/AllSuggestions';
import {AIResponseRenderer, MarkdownRenderer } from './Partials/LLMTextRenderer';
import { useQuery, useQueryClient } from 'react-query';
import useDocumentTitle from '../UseDocumentTitle';
import { useAuth } from '../hooks/useAuth';
import { Helmet, HelmetProvider } from 'react-helmet-async'; 
import studyBuddyLogo from '../assets/Empty High-Res Logo.png';
import { fetchChats } from '../services/api';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa'
import ExpandedResource from './Partials/ExpandedResource';
import SalesModal from './Partials/SalesModal';
import CognoraLogo from '../assets/Empty High-Res Logo.png';
import CognoraLogoTransparentDark from '../assets/Cognore Inverse Logo (1).png'
import { useDropzone } from 'react-dropzone';
import debounce from 'lodash/debounce';
import { useTheme } from '../App';
import { SidebarLeftIcon, SparklesIcon, Cancel01Icon, File02Icon, Book02Icon, ThumbsDownIcon, DashboardCircleIcon, PlusSignCircleIcon, SlidersVerticalIcon, AccountSetting03Icon, FileAttachmentIcon, ArrowLeft01Icon, Image01Icon, Edit01Icon,
  Logout02Icon,
  HelpCircleIcon,
  Message01Icon,
  Image02Icon,
  SourceCodeIcon,
  Analytics01Icon,
  LinkSquare01Icon,
  AlertCircleIcon,
  PencilEdit02Icon,
 } from 'hugeicons-react';
import { AutoCompleteDropdown } from './Layout/AutoCompleteDropdown';
import RecentChats from './Layout/RecentChats';
import { getTokenCount, parseTextContent } from './helpers/chatHelper';
import { CopyButton, RegenerateButton, SubmitButton } from './Layout/Buttons';
import ModeSelector from './Layout/ModeSelector';
import ImageModal from './Partials/ImageModal';
import { InputArea } from './Layout/InputArea';
import { ACCEPTED_FILE_TYPES } from './Data/AcceptedFileTypes';
import { ThinkingPanel } from './Layout/ThinkingPanel';

// Add this helper function at the top of your component
const generateTempId = () => `temp-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

// Add this helper function at the top of the file
const getGuestChatsFromStorage = () => {
  const stored = localStorage.getItem('guestRemainingChats');
  const lastReset = localStorage.getItem('guestChatsLastReset');
  const today = new Date().toDateString();

  // Check if it's a new day or no last reset exists
  if (!lastReset || lastReset !== today) {
    // Reset to default value (3) and update last reset date
    localStorage.setItem('guestRemainingChats', '3');
    localStorage.setItem('guestChatsLastReset', today);
    return 3;
  }

  // Return stored value if it exists, otherwise default to 3
  return stored ? parseInt(stored, 10) : 3;
};

// Add these constants at the top of the file
const MAX_FILES_PER_MESSAGE = 3;
const MAX_FILES_PER_CHAT = {
  free: 1,
  premium: 2,
  pro: 3,
  ultimate: 7,
  beta: 10
};

const MAX_FILE_SIZE_MB = 10;

// Move UserDropdown outside of Chats component
const UserDropdown = React.memo(({ user, handleLogout }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && 
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Close dropdown when pressing Escape
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, []);

  

  const handleDropdownClick = useCallback(() => {
    setIsDropdownOpen(!isDropdownOpen);
  }, [isDropdownOpen]);

  const handleLogoutClick = useCallback(() => {
    handleLogout();
    setIsDropdownOpen(false);
  }, [handleLogout]);

  return (
    <div className="dropdown">
      <button 
        ref={buttonRef}
        className="settings-button"
        onClick={handleDropdownClick}
        aria-label="Open user menu"
        aria-expanded={isDropdownOpen}
        aria-haspopup="true"
      >
        <div 
          className="user-avatar" 
          role="img" 
          aria-label={`${user.name}'s avatar`}
        >
          {user.name ? user.name.charAt(0).toUpperCase() : '?'}
        </div>
      </button>
      
      {isDropdownOpen && (
        <div 
          ref={dropdownRef}
          className={`dropdown-menu ${isDropdownOpen ? 'active' : ''}`}
          role="menu"
          aria-orientation="vertical"
        >
          <Link 
            to="/settings" 
            className="dropdown-item"
            role="menuitem"
            onClick={() => setIsDropdownOpen(false)}
          >
            <AccountSetting03Icon size={18} style={{ marginRight: '8px' }} />
            Settings
          </Link>

          <a
            href='https://cognora.ca/faq'
            target="_blank"
            rel="noopener noreferrer"
            className="dropdown-item"
            role="menuitem"
            onClick={() => setIsDropdownOpen(false)}
          >
            <HelpCircleIcon size={18} style={{ marginRight: '18px' }} />
            Help
          </a>
          
          <div className="dropdown-divider"></div>

          <button 
            onClick={handleLogoutClick}
            className="dropdown-item"
            role="menuitem"
          >
            <Logout02Icon size={18} style={{ marginRight: '4px' }} />
            Logout
          </button>
        </div>
      )}
    </div>
  );
});

export const getResourceIcon = (type) => {
  switch (type) {
    case 'file':
      return <File02Icon />;
    case 'image':
      return <Image02Icon />;
    case 'essay':
      return <Book02Icon />;
    case 'code':
      return <SourceCodeIcon />;
    case 'visualization':
      return <Analytics01Icon />;
    default:
      return <Book02Icon />;
  }
};

// Move these outside the component or use useMemo if they need to be dynamic
const disallowedElements = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'img', 'iframe', 'script', 'style', 'table', 'blockquote', 'pre'];

const Chats = ({ subscription, isSpaceChat }) => {
  const { user, isLoading: authLoading, isDataLoading: dataLoading, logout } = useAuth();
  const { theme } = useTheme();
  

  useDocumentTitle('StudyBuddy');
  const initialRemainingChats = user ? user?.tier === 'free' ? user?.remainingChats : null : getGuestChatsFromStorage();
  const [remainingChats, setRemainingChats] = useState(initialRemainingChats);
  const queryClient = useQueryClient();
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const [input, setInput] = useState('');
  const inputRef = useRef('');
  const textareaRef = useRef(null);
  const [isMobile] = useState(window.innerWidth <= 768);

  // First, declare resources state
  const [resources, setResources] = useState({});

  // Then use it in other state initializations
  const [isResourcesVisible, setIsResourcesVisible] = useState(() => {
    return false;
  });
  
  const [isResourcesExpanded, setIsResourcesExpanded] = useState(false);
  const [expandedResource, setExpandedResource] = useState(null);
  const [expandedVersion, setExpandedVersion] = useState(-1);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const messagesEndRef = useRef(null);
  const [chatIdToUse, setChatIdToUse] = useState(null);
  const [currentChatTitle, setCurrentChatTitle] = useState('');
  const [isInputProcessing, setIsInputProcessing] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showScrollButton, setShowScrollButton] = useState(false);
  const messagesContainerRef = useRef(null);
  const [pendingNavigation, setPendingNavigation] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const [editFinalContent, setEditFinalContent] = useState('');
  
  // New state for editing and branching
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editedMessageId, setEditedMessageId] = useState(null);
  const [editContent, setEditContent] = useState('');
  const [isGeneratingResponse, setIsGeneratingResponse] = useState(false);
  const abortControllerRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const lastMessageRef = useRef(null);
  const [isDefaultDisplay, setIsDefaultDisplay] = useState(false);

  const [currentMode, setCurrentMode] = useState(() => {
    return localStorage.getItem('defaultChatMode') || 'qa';
  });

  const [filteredMessages, setFilteredMessages] = useState([]);

  const [regeneratingMessageId, setRegeneratingMessageId] = useState(null);
  const [generatedContent, setGeneratedContent] = useState('');
  // Add these new state variables
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('');
  const [showToast, setShowToast] = useState(false);

  const [autoCompleteSuggestions, setAutoCompleteSuggestions] = useState([]);
  const [showAutoComplete, setShowAutoComplete] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);

  const MAX_MESSAGE_LENGTH = 12000;

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [imageTitle, setImageTitle] = useState('');

  const [textFiles, setTextFiles] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);

  //const [isThinkHarderActive, setIsThinkHarderActive] = useState(false);
  const fileInputRef = useRef(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dynamicHeader, setDynamicHeader] = useState('');

  const location = useLocation();

  // eslint-disable-next-line no-unused-vars
  const [currentThinkingTime, setCurrentThinkingTime] = useState(null );

    // Add new state for hover
    const [isHoverOpen, setIsHoverOpen] = useState(false);
    const hoverTimeoutRef = useRef(null);
  
    // Handle hover open/close with delay
    const handleHoverOpen = useCallback(() => {
      if (!isSidebarOpen && window.innerWidth > 668) {
        setIsHoverOpen(true);
      }
    }, [isSidebarOpen]);
  
    const handleHoverClose = useCallback(() => {
      if (!isSidebarOpen && window.innerWidth > 668) {
        setIsHoverOpen(false);
      }
    }, [isSidebarOpen]); 
    
    useEffect(() => {
      const timeoutRef = hoverTimeoutRef.current;
      return () => {
        if (timeoutRef) {
          clearTimeout(timeoutRef);
        }
      };
    }, []);

  // Add this near the other state declarations
  const [remainingGuestChats, setRemainingGuestChats] = useState(getGuestChatsFromStorage);
  const [showAuthPrompt, setShowAuthPrompt] = useState(!user && remainingGuestChats <= 0);

  // Add this useEffect to handle guest chat count
  useEffect(() => {
    if (!user) {
      localStorage.setItem('guestRemainingChats', remainingGuestChats.toString());
      localStorage.setItem('guestChatsLastReset', new Date().toDateString());
    }
  }, [remainingGuestChats, user]);

    // Add this new useEffect for handling scroll on new chats
    useEffect(() => {
      if (chatId === 'new' || !chatId || chatId === 'undefined') {
        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop = 0;
        }
      }
    }, [chatId]);

    // Update the scrollToBottom function
    const scrollToBottom = useCallback(() => {
      const timeoutId = setTimeout(() => {
        if (messagesEndRef.current) {
          try {
            messagesEndRef.current?.scrollIntoView({ 
              behavior: "smooth", 
              block: "end",
              inline: "nearest"
            });
          } catch (error) {
            // Error handling removed
          }
        }
        setShowScrollButton(false);
      }, 100);
  
      return () => clearTimeout(timeoutId);
    }, []); // Remove isGeneratingResponse from dependencies
  
    // Update the existing useEffect for initial scroll behavior
    useEffect(() => {
      if (chatId && chatId !== 'new' && chatId !== 'undefined' && filteredMessages?.length > 0) {
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      } else if (!chatId || chatId === 'new' || chatId === 'undefined') {
        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop = 0;
        }
      }
    }, [chatId, filteredMessages?.length, scrollToBottom]);

  // Memoize SubmitButton component to prevent unnecessary re-renders


  // Add this function to show custom toast
  const showCustomToast = useCallback((type, message) => {
    setToastType(type);
    setToastMessage(message);
    setShowToast(true);
    
    const timeoutId = setTimeout(() => setShowToast(false), 3000);
    
    // Store timeout ID for cleanup
    const currentTimeoutId = timeoutId;
    return () => clearTimeout(currentTimeoutId);
  }, []);


  // Ref to track processed messages
  const processedMessageIdsRef = useRef(new Set());

  const [showSalesModal, setShowSalesModal] = useState(false);
  const [salesModalFeature, setSalesModalFeature] = useState('');

  // Add new state for loading status
  const [isInitialLoading, setIsInitialLoading] = useState(true);

    // Update the useEffect that handles messages
    useEffect(() => {
      if (chatId && chatId !== 'new' && messages) {
        // Short delay to ensure messages are properly loaded
        const timer = setTimeout(() => {
          setIsInitialLoading(false);
        }, 300); // Adjust timing as needed
  
        return () => clearTimeout(timer);
      } else if (chatId === 'new' || !chatId) {
        setIsInitialLoading(false);
      }
    }, [chatId, messages]);
  
  

  const closeSalesModal = () => {
    setShowSalesModal(false);
    setSalesModalFeature('');
  };


  const [searchEnable] = useState(() => {
    const savedFeatures = localStorage.getItem('betaFeatures');
    return savedFeatures ? JSON.parse(savedFeatures).searchConversations : false;
  });

  const [pdfOCR] = useState(() => {
    const savedFeatures = localStorage.getItem('betaFeatures');
    return savedFeatures ? JSON.parse(savedFeatures).pdfOCR : false;
  });


  const [isLoadingResource, setIsLoadingResource] = useState(false);

  const [spaceId, setSpaceId] = useState(null);

  const [isFileProcessing, setIsFileProcessing] = useState(false);
  useEffect(() => {
    if (textFiles.some(file => !file.isLoaded)) {
      setIsFileProcessing(true);
    } else {
      setIsFileProcessing(false);
    }
  }, [textFiles]);

   const removeTextFile = useCallback((id) => {
      setTextFiles(prev => prev.filter(file => file.id !== id));
    }, [setTextFiles]);

    const removeImageFile = useCallback((id) => {
      setImageFiles(prev => prev.filter(file => file.id !== id));
    }, [setImageFiles]);
  
    const convertPDF = useCallback(async (file) => {
      try {
        const storedResponse = localStorage.getItem(`file_${file.name}`);
        if (storedResponse) {
          setTextFiles(prev => prev.map(f =>
            f.file.id === file.id
              ? {
                  ...f,
                  content: storedResponse,
                  isLoaded: true,
                }
              : f
          ));
          return storedResponse;
        }
        const response = pdfOCR ? await convertPdfToTextOCR(file) : await convertPdfToText(file);
        
        setTextFiles(prev => prev.map(f => 
          f.file.id === file.id 
            ? { 
                ...f, 
                content: response, 
                isLoaded: true,
              }
            : f
        ));

        localStorage.setItem(`file_${file.name}`, response);
        return response;
      } catch (error) {
        removeTextFile(file.id);
        showCustomToast('error', `Error converting ${file.name.substring(0, 50)}${file.name.length > 50 ? '...' : ''}`);
        throw error;
      }
    }, [removeTextFile, showCustomToast, pdfOCR]);
    
  const onDrop = useCallback((acceptedFiles) => {
    if (!user) {
      showCustomToast('error', 'Please sign in to upload files.');
      return;
    }

    const validFiles = acceptedFiles.filter(file => 
      ACCEPTED_FILE_TYPES[file.type]
    );
    
    if (validFiles?.length > 0) {
      validFiles.forEach(file => {
        if (file.type.startsWith('image/')) {
          setImageFiles(prev => [...prev, {
            name: file.name,
            file: file,
            id: `${file.name}-${file.size}-${Date.now()}`,
          }]);
        } else {
          setTextFiles(prev => [...prev, {
            name: file.name,
            file: file,
            id: `${file.name}-${file.size}-${Date.now()}`,
            content: null,
            isLoaded: false 
          }]);
          convertPDF(file);
        }
      });
      showCustomToast('success', `${validFiles.length} file(s) attached successfully.`);
    } else {
      showCustomToast('error', 'Only PDFs, images, and documents are allowed.');
    }
  }, [user, showCustomToast, convertPDF]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
      'text/plain': ['.txt']
    },
    multiple: true,
    noClick: true,
    noKeyboard: true,
    disabled: !user,
  });

  useEffect(() => {
    if(filteredMessages?.length === 0) {
      setIsDefaultDisplay(true);
    } else {
      setIsDefaultDisplay(false);
    }
  }, [filteredMessages]);

  useEffect(() => {
    setRemainingChats(initialRemainingChats);
  }, [initialRemainingChats]);

  const [chatExists, setChatExists] = useState(true);

  const handleLogout = useCallback(() => {
    logout();
    setMessages([]);
    navigate('/');
  }, [logout, navigate]);

  const { data: chatsData, isSuccess: isChatsLoaded } = useQuery('chats', fetchChats, {
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    enabled: !!user,
  });

  useEffect(() => {
    if (chatsData?.chats) {
      const currentChat = chatsData.chats.find(chat => chat.id === chatId);
      const spaceIdFromParams = searchParams.get('spaceId');
      if (currentChat?.spaceId) {
        setSpaceId(currentChat.spaceId);
      } else if(isSpaceChat && spaceIdFromParams !== null) {
        const spaceIdFromParams = searchParams.get('spaceId');
        setSpaceId(spaceIdFromParams);
      }

      if(spaceId !== null && !isSpaceChat) {
        navigate(`/ss/${chatId}`);
        
      }
    }
  }, [chatsData, chatId, navigate, isSpaceChat, spaceId, searchParams]);

  const { data: messagesData, error: messagesError } = useQuery(
    ['messages', chatId],
    () => fetchMessages(chatId),
    {
      enabled: Boolean(chatId) && chatId !== 'new',
      // Force a check on mount/focus to ensure updated data
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      staleTime: 0, 
      cacheTime: 30 * 60 * 1000,
      retry: false,
      onSuccess: (data) => {
        setMessages(data);
        try {
          localStorage.setItem(`chat_${chatId}`, JSON.stringify(data));
        } catch (err) {
        }
      },
      onError: (error) => {
        if (error.isAuthError) {
          localStorage.clear();
          queryClient.clear();
          navigate('/login');
        }
      }
    }
  );

  const handleResourceClick = useCallback((resourceTitle, messageId) => {
    setResources(currentResources => {
      if (!currentResources || !currentResources[chatId] || !Array.isArray(currentResources[chatId])) {
        return currentResources;
      }

      const foundResources = currentResources[chatId].filter(resource => resource.title === resourceTitle);
      
      if (foundResources?.length === 0) {
        return currentResources;
      }

      let selectedResource;

      if (messageId) {
        selectedResource = foundResources.find(resource => resource.messageId === messageId)
        if (!selectedResource) {
          selectedResource = foundResources.reduce((latest, current) => 
            ((current.version || 1) > (latest.version || 1)) ? current : latest
          );
        } 
      } else {
        selectedResource = foundResources.reduce((latest, current) => 
          ((current.version || 1) > (latest.version || 1)) ? current : latest
        );
      }

      setIsResourcesVisible(true);
      setIsResourcesExpanded(true);
      if (isResourcesVisible && !isResourcesExpanded) {
        setIsResourcesExpanded(true);
      } 

      setExpandedVersion(selectedResource.version || 0);
      setExpandedResource({
        ...selectedResource,
      });

      setSearchParams(prev => ({
        ...Object.fromEntries(prev),
        resource: resourceTitle,
        version: (selectedResource.version || 1).toString()
      }));

      return currentResources;
    });
  }, [chatId, setSearchParams, isResourcesVisible, isResourcesExpanded]);

  const renderResource = useCallback((resource, isLoading = false) => (
    <div className="resource-item-container">
      <div className={`resource-item`} 
           onClick={() => !resource.isLoading && handleResourceClick(resource.title, resource.messageId)}>
        <span className="resource-icon">
          {isLoading ? (
            <div className="resource-loading-spinner" />
          ) : (
            getResourceIcon(resource.type)
          )}
        </span>
        <div className="resource-info">
          <h3 className="resource-title">
            {resource.title || (isLoading ? 'Generating Resource...' : '')}
          </h3>
          <span className="resource-type">
            {((isLoading && isGeneratingResponse) ? 'Please wait' : 'Click to Open Resource')}
          </span>
        </div>
        {!isLoading && <FaChevronRight className="resource-arrow" />}
      </div>
      {resource.content && isLoading && (
        <div className="resource-streaming">
          <span>
            {resource.content.replace(/<(artifacts|code|essay|quote|visualization)>/g, '[').replace(/<\/(artifacts|code|essay|quote|visualization)>/g, ']').slice(-300)}
          </span>
        </div>
      )}
    </div>
  ), [handleResourceClick, isGeneratingResponse]);
  
  useEffect(() => {
    if (isChatsLoaded && chatId && chatId !== 'new') {
      const chatExists = chatsData?.chats?.some(chat => chat.id === chatId);
      setChatExists(chatExists);
    }
  }, [isChatsLoaded, chatsData, chatId]);

  const handleEditStart = useCallback((message) => {
    setEditedMessageId(message.id);
    setEditingMessageId(message.id);
    setEditContent(message.content);
    setTimeout(() => {
      if (editTextareaRef.current) {
        editTextareaRef.current.style.height = 'auto';
        editTextareaRef.current.style.height = `${editTextareaRef.current.scrollHeight}px`;
      }
    }, 100);
  }, []);
  
  useEffect(() => {
    if (editingMessageId && editTextareaRef.current) {
      editTextareaRef.current.style.height = 'auto';
      editTextareaRef.current.style.height = `${editTextareaRef.current.scrollHeight}px`;
      editTextareaRef.current.focus();
    }
  }, [editingMessageId]);

  const handleEditCancel = useCallback(() => {
    setEditingMessageId(null);
    setEditedMessageId(null);
    setEditContent('');
  }, []);

  const handleCreateResource = useCallback(async (newResource) => {
    setResources(prevResources => {
      const updatedResources = {
        ...prevResources,
        [chatId]: prevResources[chatId] || []
      };

      const sameResources = updatedResources[chatId].filter(r => 
        r.title === newResource.title && 
        r.type === newResource.type
      );

      const highestVersion = sameResources.reduce((max, r) => 
        Math.max(max, r.version || 0), 0);

      newResource.version = highestVersion + 1;

      updatedResources[chatId] = [...updatedResources[chatId], newResource];

      return updatedResources;
    });
  }, [chatId]);

  const processArtifacts = useCallback((content, id, flag) => {
    let newResources = [];
    const artifactsMatch = content.match(/<artifacts>([\s\S]*?)<\/artifacts>/g);
    
    if (artifactsMatch) {
      artifactsMatch.forEach((artifact, index) => {
        const isUnclosed = !artifact.includes('</artifacts>');
        
        if (isUnclosed && !flag) {
          const resourceId = `resource-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
          const content = artifact.replace('<artifacts>', '').trim();
          
          const essayMatch = content.match(/<essay>[\s\S]*?<title>(.*?)<\/title>/);
          const codeMatch = content.match(/<code.*?language="(.*?)".*?title="(.*?)">/);
          
          let newResource;
          if (essayMatch) {
            newResource = {
              id: resourceId,
              type: 'essay',
              title: essayMatch[1] || 'Untitled Essay',
              content: content.replace(/<essay>[\s\S]*?<title>.*?<\/title>/, ''),
              timestamp: new Date().toISOString(),
              messageId: id
            };
          } else if (codeMatch) {
            newResource = {
              id: resourceId, 
              type: 'code',
              language: codeMatch[1],
              title: codeMatch[2] || 'Untitled Code',
              content: content.replace(/<code.*?>/, ''),
              timestamp: new Date().toISOString(),
              messageId: id
            };
          } else {
            newResource = {
              id: resourceId,
              type: 'generic',
              title: 'Generated Resource',
              content: content,
              timestamp: new Date().toISOString(),
              messageId: id
            };
          }
          
          if(!flag) {
            handleCreateResource(newResource);
          }
          if(newResource !== undefined) {
            newResources.push(newResource);
          }
          return;
        }

        const essayMatch = artifact.match(/<essay>([\s\S]*?)<\/essay>/);
        if (essayMatch) {
          const essayContent = essayMatch[1];
          const titleMatch = essayContent.match(/<title>(.*?)<\/title>/);
          const contentMatch = essayContent.match(/<content>([\s\S]*?)<\/content>/);
          
          if (titleMatch && contentMatch) {
            const resourceId = `essay-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
            let newResource = {
              id: resourceId,
              type: 'essay',
              title: titleMatch[1],
              content: contentMatch[1],
              timestamp: new Date().toISOString(),
              messageId: id
            };
            
            if(!flag) {
              handleCreateResource(newResource);
            }
            newResources.push(newResource);
            return;
          }
        }

        const codeMatch = !artifact.includes('<essay>') ? artifact.match(/<code.*?>([\s\S]*?)<\/code>/) : null;
        if (codeMatch) {
          const codeContent = codeMatch[1];
          const languageMatch = codeMatch[0].match(/language="(.*?)"/);
          const titleMatch = codeMatch[0].match(/title="(.*?)"/);
          
          if (languageMatch && titleMatch) {
            const resourceId = `code-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
            let newResource = {
              id: resourceId,
              type: 'code',
              language: languageMatch[1],
              title: titleMatch[1],
              content: codeContent,
              timestamp: new Date().toISOString(),
              messageId: id
            };
            
            if(!flag) {
              handleCreateResource(newResource);
            }
            newResources.push(newResource);
          }
        }

        const vizMatch = !artifact.includes('<essay>') ? artifact.match(/<visualization.*?>([\s\S]*?)<\/visualization>/) : null;
        if (vizMatch) {
          const vizContent = vizMatch[1];
          const titleMatch = vizMatch[0].match(/title="(.*?)"/);
          
          if (titleMatch) {
            const resourceId = `viz-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
            let newResource = {
              id: resourceId,
              type: 'visualization',
              title: titleMatch[1],
              content: vizContent,
              timestamp: new Date().toISOString(),
              messageId: id
            };
            
            if(!flag) {
              handleCreateResource(newResource);
            }
            newResources.push(newResource);
          }
        }
      });
    }

    if(flag) {
      setResources(prevResources => {
        const updatedResources = { ...prevResources };
        if (!updatedResources[chatId]) {
          updatedResources[chatId] = [];
        }

        newResources.forEach(newResource => {
          const sameResources = updatedResources[chatId].filter(r => 
            r.title === newResource.title && 
            r.type === newResource.type
          );

          const highestVersion = sameResources.reduce((max, r) => 
            Math.max(max, r.version || 0), 0);

          newResource.version = highestVersion + 1;

          updatedResources[chatId].push(newResource);
        });

        return updatedResources;
      });
    }
    return newResources;
  }, [chatId, handleCreateResource]);

  const appendChunk = (chunk) => {
    setGeneratedContent(prev => prev + chunk);
  };

  const handleStopStreaming = useCallback(() => {
    
  }, []);

 

  const RegenerateMessage = useCallback(async (messageId, mode) => {
    setRegeneratingMessageId(messageId);

    if (expandedResource?.messageId === messageId) {
      setExpandedResource(null);
      setIsResourcesExpanded(false);
      
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('resource');
      newParams.delete('version');
      setSearchParams(newParams);
    }

    setResources(prevResources => {
      const updatedResources = { ...prevResources };
      Object.keys(updatedResources).forEach(chatId => {
        updatedResources[chatId] = updatedResources[chatId].filter(
          resource => resource.messageId !== messageId
        );
      });
      return updatedResources;
    });

    const originalMessage = messages.find(msg => msg.id === messageId);
    const originalContent = originalMessage?.content;

    setMessages(prevMessages => 
      prevMessages.map(msg => 
        msg.id === messageId 
          ? { ...msg, content: '' }
          : msg
      )
    );

    try {
      const { stream } = await regenerateMessage(chatId, messageId, null, mode);
      let accumulatedResponse = '';

      const updateMessageContent = (newContent) => {
        setMessages(prevMessages => prevMessages.map(msg => {
          if (msg.id === messageId) {
            return { ...msg, content: newContent };
          }
          return msg;
        }));
      };

      for await (const chunk of stream) {
        if (chunk.content) {
          const text = chunk.content;
          
          for (let i = 0; i < text.length; i++) {
            accumulatedResponse += text[i];
            updateMessageContent(accumulatedResponse);
          }

          // If we see a stop condition token in the chunk
          if (
            accumulatedResponse.endsWith('[Generation stopped]') ||
            chunk.content.includes('{"status": "complete"}')
          ) {
            break;
          }
        }

        if(accumulatedResponse && accumulatedResponse.includes('<artifact>')) {
          setIsLoadingResource(true);
        }
      }

      processArtifacts(accumulatedResponse, messageId, false);
      
      setRegeneratingMessageId(null);
      setIsGeneratingResponse(false);
      
      queryClient.invalidateQueries(['messages', chatId]);

    } catch (error) {
      showCustomToast('error', 'Failed to regenerate message. Please try again.');
      
      setMessages(prevMessages =>
        prevMessages.map(msg =>
          msg.id === messageId
            ? { ...msg, content: originalContent }
            : msg
        )
      );
      
      setRegeneratingMessageId(null);
      setIsGeneratingResponse(false);
    }
  }, [
    chatId,
    messages,
    searchParams,
    setSearchParams,
    expandedResource,
    queryClient,
    processArtifacts,
    showCustomToast,
  ]);

  const groupedMessages = useMemo(() => {
    if (!Array.isArray(messages)) {
      return [];
    }

    const groups = {};
    const validMessages = messages.filter(message => message != null);

    validMessages.forEach(message => {
      const messageKey = `${message.id}-${message.timestamp}`;
      
      if (message.parent_message_id) {
        if (!groups[message.parent_message_id]) {
          groups[message.parent_message_id] = [];
        }
        groups[message.parent_message_id].push({
          ...message,
          uniqueKey: messageKey
        });
      } else {
        if (!groups[message.id]) {
          groups[message.id] = [{
            ...message,
            uniqueKey: messageKey
          }];
        }
      }
    });
  
    return Object.values(groups);
  }, [messages]);

  const [searchResults, setSearchResults] = useState([]);

  const searchTimeoutRef = useRef(null);
  const debouncedSearch = useCallback((query) => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    
    searchTimeoutRef.current = setTimeout(() => {
      const lowercasedQuery = query.toLowerCase();
      const results = [];
      
      const currentChatResults = messages.filter(message =>
        message.content.toLowerCase().includes(lowercasedQuery)
      ).map(message => ({
        ...message,
        chatId: chatId,
        chatTitle: currentChatTitle || 'Current Chat',
        preview: message.content.substring(0, 150) + '...'
      }));

      results.push(...currentChatResults);

      for (let i = 0; i < localStorage?.length; i++) {
        const key = localStorage.key(i);
        if (key.startsWith('chat_')) {
          try {
            const chatMessages = JSON.parse(localStorage.getItem(key));
            const chatId = key.replace('chat_', '');
            
            const chatInfo = chatsData?.chats?.find(chat => chat.id === chatId);
            const chatTitle = chatInfo?.title || 'Untitled Chat';

            const matchingMessages = chatMessages.filter(message =>
              message.content.toLowerCase().includes(lowercasedQuery)
            ).map(message => ({
              ...message,
              chatId,
              chatTitle,
              preview: message.content.substring(0, 150) + '...'
            }));

            results.push(...matchingMessages);
          } catch (error) {
          }
        }
      }

      const uniqueResults = Array.from(
        new Map(results.map(item => [item.id, item])).values()
      );

      uniqueResults.sort((a, b) => 
        new Date(b.timestamp) - new Date(a.timestamp)
      );

      const currentChatFiltered = uniqueResults.filter(
        result => result.chatId === chatId
      );
      setFilteredMessages(currentChatFiltered);

      setSearchResults(uniqueResults);
    }, 300);

    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, [messages, chatId, currentChatTitle, chatsData]);

  const handleSearch = useCallback((e) => {
    const value = e.target.value;
    setSearchQuery(value);
    
    if (!value.trim()) {
      setSearchResults([]);
      setFilteredMessages([]);
      return;
    }

    debouncedSearch(value);
  }, [debouncedSearch]);

  const OtherChatsSearchResults = ({ results, onResultClick }) => {
    if (!results?.length) return null;

    return (
      <div className="other-chats-results">
        <div className="other-chats-header">Search Results from Other Chats</div>
        {results.map((result) => (
          <div
            key={result.id}
            className="other-chat-result"
            onClick={() => onResultClick(result.chatId)}
          >
            <div className="result-chat-title">
              <Message01Icon size={16} />
              {result.chatTitle || ''}
            </div>
            <div className="result-preview">
              <AIResponseRenderer content={result.preview?.length > 100 
                ? `${result.preview.substring(0, 100)}...` 
                : result.preview} 
                renderResource={renderResource} 
                messageId={result.id}
              />
            </div>
            <FaChevronRight className="preview-arrow" size={16} />
          </div>
        ))}
      </div>
    );
  };
  
  const clearMessages = useCallback(() => {
    setMessages([]);
    setFilteredMessages([]);
    setCutMessages([]);
  }, [setMessages, setFilteredMessages]);

  const handleSearchResultClick = useCallback((chatId) => {
    setSearchQuery('');
    setSearchResults([]);
    navigate(`/${chatId}`);
  }, [navigate]);

  const handleAddChat = useCallback(async () => {
    try {
      clearMessages();

      setInput('');
      setIsDefaultDisplay(true);
      setChatIdToUse(null);
      navigate('/', { replace: false });

      if(messagesError){
        window.location.reload();
      }

    } catch (error) {
      showCustomToast('error', 'Failed to create a new chat. Please try again.');
    }
  }, [navigate, showCustomToast, clearMessages, messagesError]);

  useEffect(() => {
    if (chatId && chatId !== 'new') {
      const cachedMessages = localStorage.getItem(`chat_${chatId}`);
      if (cachedMessages && cachedMessages !== 'undefined') {
        try {
          const parsedMessages = JSON.parse(cachedMessages);
          setMessages(parsedMessages);
        } catch (error) {
          localStorage.removeItem(`chat_${chatId}`);
          setMessages(messagesData || []);
        }
      } else if (messagesData) {
        setMessages(messagesData);
      }
    } else if (chatId === 'new') {
      setMessages([]);
    }
  }, [chatId, messagesData]);

    useEffect(() => {
      if (!user) {
        const lastReset = localStorage.getItem('guestChatsLastReset');
        const today = new Date().toDateString();
  
        if (!lastReset || lastReset !== today) {
          setRemainingGuestChats(3);
          localStorage.setItem('guestRemainingChats', '3');
          localStorage.setItem('guestChatsLastReset', today);
        }
      }
    }, [user]);

  // Update the useEffect for window resize
  useEffect(() => {
    const savedSidebarState = localStorage.getItem('isSidebarOpen');
    if(user){
    if (savedSidebarState !== null) {
      setIsSidebarOpen(JSON.parse(savedSidebarState));
    }
    
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
      
      // Close sidebar and hover state when transitioning to mobile
      if (newWidth <= 768) {
        setIsSidebarOpen(false);
        setIsHoverOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    
    // Initial check on mount
    handleResize();
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }
  }, [user]);

  // Add a separate useEffect for setting the initial dynamic header
  useEffect(() => {
    setDynamicHeader(getRandomHeader(user?.name));
  }, [user?.name]); // Only regenerate when user name changes

  // Update the handleUpdateTitle function
  const handleUpdateTitle = useCallback((newTitle) => {
    setCurrentChatTitle(newTitle);
  }, []);

  // Update chatIdToUse based on chatId
  useEffect(() => {
    if (chatId === 'new') {
      setChatIdToUse(null);
      setMessages([]);
      setFilteredMessages([]);
      setCurrentChatTitle('');
    } else if (chatId) {
      setChatIdToUse(chatId);
    }
  }, [chatId]);

const handleImprove = async (message) => {
  setInput(message);
  inputRef.current = message;
  // Wait for state to update before submitting
  await new Promise(resolve => setTimeout(resolve, 0));
  handleSubmit({ preventDefault: () => {} });
};

const handleExplain = async (message) => {
  setInput(message);
  inputRef.current = message;
  // Wait for state to update before submitting
  await new Promise(resolve => setTimeout(resolve, 0));
  handleSubmit({ preventDefault: () => {} });
};
  
  useEffect(() => {
    // Skip if no chats data
    if (!chatsData?.chats?.length) return;

    // Create a temporary object to store all new resources
    const newResources = {};

    // Process each chat's resources
    chatsData.chats.forEach(chat => {
      if (!chat?.pdf_content) return;

      try {
        // Use parseTextContent helper to process the PDF content
        const parsedResources = parseTextContent(chat.pdf_content);
        
        // Add parsed resources to newResources if any
        if (parsedResources.length > 0) {
          if (!newResources[chat.id]) {
            newResources[chat.id] = [];
          }
          newResources[chat.id].push(...parsedResources);
        }

      } catch (error) {
        console.error('Error processing PDF content:', error);
      }
    });

    // Update resources state in a single batch using functional update
    setResources(prevResources => {
      const updatedResources = { ...prevResources };

      Object.entries(newResources).forEach(([chatId, resourcesToAdd]) => {
        const existingResources = updatedResources[chatId] || [];
        
        // Check if there are any non-image resources already present
        const hasNonImageResource = existingResources.some(resource => resource.type !== 'image');
        if (hasNonImageResource) return; // Skip adding new resources if condition is met

        // Initialize array for this chat's resources if not present
        if (!updatedResources[chatId]) {
          updatedResources[chatId] = [];
        }

        // Add the new resources
        updatedResources[chatId] = [...updatedResources[chatId], ...resourcesToAdd];
      });

      return updatedResources;
    });

  }, [chatsData, chatId]);

  const addImagesToResources = useCallback(async (images, messageId) => {
    if (!images || !images.length) return;
  
    // Ensure images is an array of objects with required properties
    const validImages = images.filter(img => img.url && img.name);
  
    for (const image of validImages) {
      const resourceId = `image-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
      
      const newResource = {
        id: resourceId,
        type: 'image',
        title: image.name,
        content: `Image file: ${image.name}`,
        timestamp: new Date().toISOString(),
        messageId: messageId,
        chatId: chatId,
        url: image.url
      };

      try {
        await handleCreateResource(newResource);

        setResources(prevResources => {
          const updatedResources = { ...prevResources };
          if (!updatedResources[chatId]) {
            updatedResources[chatId] = [];
          }
          updatedResources[chatId] = [...updatedResources[chatId], newResource];
          return updatedResources;
        });
      } catch (error) {
        console.error(`Failed to create resource for image: ${image.name}`, error);
      }
    }
  }, [handleCreateResource, chatId]);
  
const parseAndAddPdfContent = useCallback(async (pdfContent, chatId) => {
  if (!pdfContent) return;

  try {
    // Use parseTextContent helper to process the PDF content
    const parsedResources = parseTextContent(pdfContent);
    
    // Add parsed resources to state
    if (parsedResources.length > 0) {
      setResources(prevResources => {
        const updatedResources = { ...prevResources };
        
        if (!updatedResources[chatId]) {
          updatedResources[chatId] = [];
        }

        // Add only new resources
        parsedResources.forEach(newResource => {
          updatedResources[chatId].push(newResource);
        });

        return updatedResources;
      });
    }
  } catch (error) {
    console.error('Error processing PDF content:', error);
  }
}, []);


// Separate useEffect for processing message artifacts
useEffect(() => {
  if (!messages) return;

  messages.forEach(message => {
    if (!processedMessageIdsRef.current.has(message?.id)) {
      if (message?.is_ai) {
        processArtifacts(message.content, message.id);
      } else if (message?.imagestitle) {
        try {
          const imageTitles = JSON.parse(message.imagestitle);
          const images = JSON.parse(message.images);

          imageTitles.forEach((title, i) => {
            const resourceId = `image-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
            handleCreateResource({
              id: resourceId,
              type: 'image',
              title,
              content: `Image file: ${title}`,
              timestamp: new Date().toISOString(),
              messageId: message.id,
              url: images[i].url
            });
          });
        } catch (error) {
          // Silent error handling
        }
      }
      processedMessageIdsRef.current.add(message.id);
    }
  });
}, [messages, processArtifacts, handleCreateResource, chatId]);

const restoreInput = useCallback((content) => {
  setInput(content);
  inputRef.current = content;
  if (textareaRef.current) {
    textareaRef.current.value = content;
  }
}, []);

// Remove temporary messages in case of error
const removeTemporaryMessages = useCallback((userMessageId, aiMessageId) => {
  setMessages(prevMessages => 
    prevMessages.filter(msg => msg.id !== userMessageId && msg.id !== aiMessageId)
  );
}, []);

const handleSubmissionError = useCallback((error, currentInput, userMessageId, currentTextFiles, currentImageFiles) => {
  removeTemporaryMessages(userMessageId);
  restoreInput(currentInput);
  setTextFiles(currentTextFiles); // Restore Text file
  setImageFiles(currentImageFiles); // Restore Image file
  if (error.name === 'AbortError') {
  } else {
    showCustomToast('error', 'An error occurred while sending your message. Please try again.');
  }
}, [removeTemporaryMessages, restoreInput, setTextFiles, showCustomToast]);

const [cutMessages, setCutMessages] = useState([]);

useEffect(() => {
  // Check for change in remainingGuestChats
  if (remainingGuestChats === 0 && !user) {
    const chatState = {
      inputValue: inputRef.current,
    };
    localStorage.setItem('pendingChatState', JSON.stringify(chatState));
    setShowAuthPrompt(true);
  }

  if(user){
    setShowAuthPrompt(false);
  }
}, [remainingGuestChats, user]);

useEffect(() => {
  const container = messagesContainerRef.current;
  if (!container) return;

  const handleScroll = () => {
    // Any threshold you'd like, e.g. 50px from bottom
    const threshold = isGeneratingResponse ? 50 : 450;
    const isNearBottom =
      container.scrollHeight - container.scrollTop - container.clientHeight < threshold;

    setShowScrollButton(!isNearBottom);
  };

  container.addEventListener('scroll', handleScroll);

  // Clean up when the component unmounts
  return () => {
    container.removeEventListener('scroll', handleScroll);
  };
}, [messagesContainerRef, setShowScrollButton, isGeneratingResponse]);

const handleSubmit = useCallback(async (e) => {
  if (e) e.preventDefault();

  // Guest vs user checks
  const currentRemaining = user ? remainingChats : remainingGuestChats;
  if (!user && currentRemaining <= 0) {
    const chatState = {
      inputValue: inputRef.current,
    };

    localStorage.setItem('pendingChatState', JSON.stringify(chatState));
    setShowAuthPrompt(true);
    return;
  } else if (user && currentRemaining <= 0 && currentRemaining !== null) {
    setShowLimitBanner(true);
  }

  if (isSubmitting) {
    return;
  }

  setIsSubmitting(true);
  setIsGeneratingResponse(true);
  setGeneratedContent('');

  // Gather necessary references
  let currentInput = '';
  let editMode = currentMode;

  if (editedMessageId) {
    currentInput = e.editContent || editFinalContent;
    editMode = e.mode;
  } else {
    currentInput = inputRef.current;
  }

  if (!editedMessageId && !currentInput.trim() && (!textFiles?.length && !imageFiles?.length)) {
    setIsSubmitting(false);
    setIsGeneratingResponse(false);
    return;
  }

  const currentTimestamp = new Date().toISOString();
  let tempUserMessageId = generateTempId();
  const tempAiMessageId = generateTempId();

  let userMessage;
  const imageFilesArray = Array.isArray(imageFiles)
    ? imageFiles.map((image) => image?.file || image).filter(Boolean)
    : [];

  const imageTitles =
    imageFiles?.length > 0
      ? JSON.stringify(imageFiles.map((image) => image.name || 'Untitled'))
      : null;
  const textFileTitles =
    textFiles?.length > 0
      ? JSON.stringify(textFiles.map((file) => file.name || 'Untitled'))
      : null;

  const textContentArray =
    textFiles?.map((file) => ({
      content: file.content,
    })) || [];

  let toSwitch = null;
  let tempTextFiles = [];
  let tempImageFiles = [];

  try {
    // Create a user message in-memory (not immediately in the state)
    if (!editedMessageId) {
      userMessage = {
        id: tempUserMessageId,
        content: currentInput,
        is_ai: false,
        role: 'user',
        timestamp: currentTimestamp,
        pdf_title: textFileTitles,
        imagestitle: imageTitles,
      };
      tempTextFiles = [...(textFiles || [])];
      tempImageFiles = [...(imageFiles || [])];
      setTextFiles([]);
      setImageFiles([]);
    }

    setIsInputProcessing(true);

  setInput('');

    setIsInputProcessing(true);
    let currentChatId = chatIdToUse;

    if (user) {
      if (!currentChatId || currentChatId === 'new' || currentChatId === 'undefined') {
        let newChat;
        if (spaceId) {
          newChat = await createSpaceChat(spaceId);
          queryClient.refetchQueries('space-chats');
        } else {
          newChat = await createNewChat();
        }
        localStorage.removeItem('draft_new');
        localStorage.removeItem(`draft_${newChat.id}`);
        toSwitch = newChat.id;
        currentChatId = newChat.id;
        setChatIdToUse(currentChatId);

        // Optimistically add the new chat to the chat list (if not a space chat)
        if (!isSpaceChat) {
          queryClient.setQueryData('chats', (oldData) => {
            if (!oldData) return { chats: [] };
            return {
              ...oldData,
              chats: [
                {
                  id: currentChatId,
                  title: 'New Chat',
                  last_modified: new Date().toISOString(),
                },
                ...oldData.chats,
              ],
            };
          });
        }
      }
    }

    inputRef.current = '';

    const controller = new AbortController();
    abortControllerRef.current = controller;

    const formattedContext = messages.map((msg) => ({
      role: msg.is_ai ? 'assistant' : 'user',
      content: msg.content,
    }));

    let usedWeb = false;
    let userMessageId = null;
    let aiMessageId = null;
    let newTitle = null;

    const aiMessage = {
      id: tempAiMessageId,
      content: '',
      is_ai: true,
      role: 'assistant',
      timestamp: new Date().toISOString(),
      used_web: user ? usedWeb : false,
    };

    // If editing, we remove subsequent messages from previous messages
    if (editedMessageId) {
      setMessages((prevMessages) => {
        const index = prevMessages.findIndex((m) => m.id === editedMessageId);
        if (index === -1) return prevMessages;
        setCutMessages(prevMessages.slice(index + 1));
        return prevMessages.map((msg, i) => 
          i === index ? { ...msg, content: currentInput } : msg
        ).slice(0, index + 1);
      });
    } else {
      setMessages((prev) => [...prev, userMessage, aiMessage]);
    }

    // Make the server request
    let stream, response, isComparison;

    if (editedMessageId) {
      ({ stream, response } = await editMessageActual(
        currentChatId,
        editedMessageId,
        currentInput,
        editMode,
      ));
    } else {
      if (user) {
        const result = await sendMessage(
          currentChatId,
          currentInput,
          currentMode,
          controller.signal,
          imageFilesArray,
          textContentArray
        );
        if (result.error) {
          showCustomToast('error', result.error);
          if (typeof result.remainingChats === 'number') {
            setRemainingChats(result.remainingChats);
          }
          throw result;
        }
        ({ stream, response, isComparison } = result);
      } else {
        ({ stream, response, isComparison } = await sendGuestMessage(
          formattedContext,
          currentInput,
          currentMode
        ));
        if (response.status === 429) {
          showCustomToast('error', 'Log in to send more messages!');
          setShowAuthPrompt(true);
          localStorage.setItem('remainingGuestChats', '0');
          setRemainingGuestChats(0);
          setRemainingChats(0);
          return;
        }
      }
    }

    let imgUrls = [];
    try {
      imgUrls = JSON.parse(response.headers.get('X-Image-Urls') || '[]');
    } catch (err) {
      console.error('Error parsing image URLs:', err);
      imgUrls = [];
    }

    let modelUsed = '';
    let messagePairId = null;
    let tempRemainingChats = remainingChats;

    if (user) {
      usedWeb = response.headers.get('X-Used-Web') === 'true';
      userMessageId = response.headers.get('X-User-Message-Id');
      aiMessageId = response.headers.get('X-AI-Message-Id');
      newTitle = response.headers.get('X-New-Title');
      imgUrls = JSON.parse(response.headers.get('X-Image-Urls') || '[]');
      tempRemainingChats = parseInt(response.headers.get('X-Remaining-Chats') || remainingChats);
    } else {
      modelUsed = response.headers.get('X-Model');
    }

    // Handle the streaming (including comparison)
    let accumulatedResponse = '';

    if (isComparison) {
      setMessagePairId(messagePairId);
      setIsComparisonModalOpen(true);

      const [stream1Response, stream2Response] = stream;

      let accumulatedResponse1 = '';
      let accumulatedResponse2 = '';

      try {
        await Promise.all([
          (async () => {
            for await (const chunk of stream1Response) {
              if (chunk.content) {
                accumulatedResponse1 += chunk.content;
                // eslint-disable-next-line no-loop-func
                setChat1((prev) => ({
                  ...prev,
                  content: accumulatedResponse1,
                  role: 'assistant',
                }));
              }
            }
          })(),
          (async () => {
            for await (const chunk of stream2Response) {
              if (chunk.content) {
                accumulatedResponse2 += chunk.content;
                // eslint-disable-next-line no-loop-func
                setChat2((prev) => ({
                  ...prev,
                  content: accumulatedResponse2,
                  role: 'assistant',
                }));
              }
            }
          })(),
        ]);
      } catch (error) {
        console.error('Error processing comparison streams:', error);
        setIsComparisonModalOpen(false);
      }
    } else {
      const updateMessageContent = (newContent) => {
        setMessages(prevMessages => prevMessages.map(msg => {
          if (msg.is_ai && 
              ((editedMessageId && msg.id === editedMessageId) ||
               (!editedMessageId && msg.id === tempAiMessageId))
          ) {
            return { ...msg, content: newContent };
          }
          return msg;
        }));
      };

      for await (const chunk of stream) {
        if (chunk.content) {
          const text = chunk.content;
          
          for (let i = 0; i < text.length; i++) {
            accumulatedResponse += text[i];
            updateMessageContent(accumulatedResponse);
            appendChunk(text[i]);
          }

          // If we see a stop condition token in the chunk
          if (
            accumulatedResponse.endsWith('[Generation stopped]') ||
            chunk.content.includes('{"status": "complete"}')
          ) {
            break;
          }
        }

        if(accumulatedResponse && accumulatedResponse.includes('<artifact>')) {
          setIsLoadingResource(true);
        }
        
      }
    }

    // Done streaming
    setIsGeneratingResponse(false);

    // When editing, we finalize the content
    if (editedMessageId) {
      setMessages((prevMessages) => {
        // Replace the last AI message with the updated content
        const editingIndex = prevMessages.findIndex((msg) => msg.id === editedMessageId);
        if (editingIndex === -1) return prevMessages;
        const updatedMessages = prevMessages.slice(0, editingIndex + 1);
        const finalAiMsg = {
          ...aiMessage,
          id: aiMessageId || tempAiMessageId,
          timestamp: new Date().toISOString(),
          content: accumulatedResponse,
          used_web: usedWeb,
        };
        return [...updatedMessages, finalAiMsg];
      });
    } else {
      // Normal, non-edited message
      // If the server gave us proper userMessageId & aiMessageId, we can update placeholders
      if (userMessageId && aiMessageId) {
        // Convert our temporary user message & AI message into final records
        const finalUserMessage = {
          ...userMessage,
          id: userMessageId,
          content: currentInput,
          role: 'user',
        };
        const finalAiMessage = {
          ...aiMessage,
          id: aiMessageId,
          content: accumulatedResponse,
          model: modelUsed,
        };

        setMessages((prevMsgs) => {
          // Remove the placeholders from the array
          const filtered = prevMsgs.filter(
            (msg) => msg.id !== tempUserMessageId && msg.id !== tempAiMessageId
          );
          return [...filtered, finalUserMessage, finalAiMessage];
        });
      } else {
        // If for some reason the server didn't send IDs, leave placeholders as is
        // (but update their content if we haven't)
        setMessages((prevMsgs) => {
          return prevMsgs.map((m) => {
            if (m.id === tempAiMessageId) {
              return {
                ...m,
                content: accumulatedResponse,
              };
            }
            return m;
          });
        });
      }
    }

    // Possibly update the chat title
    if (user && newTitle) {
      if (isSpaceChat) {
        queryClient.setQueryData('space-chats', (oldData) => {
          if (!oldData?.chats) return oldData;

          return {
            ...oldData,
            chats: oldData.chats.map((chat) =>
              chat.id === currentChatId
                ? { ...chat, title: newTitle }
                : chat
            ),
          };
        });
      } else {
        setCurrentChatTitle(newTitle);
        handleUpdateTitle(newTitle);
        queryClient.setQueryData('chats', (oldData) => {
          if (!oldData?.chats) return oldData;
          const updated = oldData.chats.filter((c) => c.id !== currentChatId);
          return {
            ...oldData,
            chats: [
              {
                id: currentChatId,
                title: newTitle,
                last_modified: new Date().toISOString(),
              },
              ...updated,
            ],
          };
        });
      }
    }

    // Update resource references
    if (aiMessageId) {
      setResources((prev) => {
        const updated = { ...prev };
        if (!updated[currentChatId]) updated[currentChatId] = [];
        updated[currentChatId] = updated[currentChatId].map((r) =>
          r.messageId === tempAiMessageId
            ? { ...r, messageId: aiMessageId }
            : r
        );
        return updated;
      });
    }

    // Combine new image URLs with the existing image files
    if (imgUrls.length > 0 && tempImageFiles.length > 0) {
      tempImageFiles = tempImageFiles.map((file, idx) => ({
        ...file,
        url: imgUrls[idx],
      }));
    }

    // Parse PDF content from text files
    const textString = textContentArray.map((f) => f.content?.text).join('\n');
    await parseAndAddPdfContent(textString, currentChatId);

    // Add images to resources
    await addImagesToResources(tempImageFiles, userMessageId || tempUserMessageId);

    if (tempTextFiles) {
      tempTextFiles.forEach((file) => {
        localStorage.removeItem(`file_${file.name}`);
      });
    }
    localStorage.removeItem(`draft_${chatId}`);
    localStorage.removeItem('draft_current');

    // Adjust remaining chat count
    if (remainingChats !== null) {
      if (!user) {
        const newRemaining = remainingGuestChats - 1;
        setRemainingGuestChats(newRemaining);
        localStorage.setItem('guestRemainingChats', newRemaining.toString());
      } else {
        if(typeof tempRemainingChats === 'number'){
          setRemainingChats(tempRemainingChats);
        } else {
          setRemainingChats((prev) => prev - 1);
        }
      }
    }

    // If we created a new chat, store the new message in localStorage, then redirect
    // so that the message can be restored after navigation
    if (toSwitch) {
      // Save the new user message & AI placeholder to localStorage
      const newTempMessages = editedMessageId
        ? []
        : [
            {
              ...userMessage,
            },
            {
              ...aiMessage,
              content: accumulatedResponse,
            },
          ];

      localStorage.setItem(`temp_messages_${toSwitch}`, JSON.stringify(newTempMessages));
      setPendingNavigation(toSwitch);
    }

    // At the very end (after the messages are confirmed stored on the server):
    if (user) {
      await queryClient.invalidateQueries(['messages', currentChatId]);
    }
    
  } catch (error) {
    console.log('Error in handleSubmit:', error);
    if (error.status === 429) {
      if (user && user?.tier === 'free') {
        showCustomToast('error', 'Daily message limit reached');
      } else if (!user) {
        showCustomToast('error', 'Sign in to continue');
      }
    } else {
      showCustomToast('error', error.message || 'Something went wrong');
    }
    if (typeof error.remainingChats === 'number') {
      setRemainingChats(error.remainingChats);
    }

    // Remove the placeholders
    setMessages((prev) =>
      prev.filter(
        (msg) => msg.id !== tempUserMessageId && msg.id !== tempAiMessageId
      )
    );

    if (editedMessageId) {
      setMessages((prev) => [...prev, ...cutMessages]);
      setCutMessages([]);
    } else {
      handleSubmissionError(
        error,
        currentInput,
        tempUserMessageId,
        tempTextFiles,
        tempImageFiles
      );
    }
  } finally {
    if (editedMessageId) {
      setEditingMessageId(null);
      setEditedMessageId(null);
      setEditContent('');
    }
    setIsInputProcessing(false);
    setIsGeneratingResponse(false);
    setIsSubmitting(false);

    // Refocus input
    setTimeout(() => {
      textareaRef.current?.focus();
    }, 100);
  }
}, [
  user,
  remainingChats,
  remainingGuestChats,
  isSubmitting,
  chatIdToUse,
  spaceId,
  isSpaceChat,
  messages,
  currentMode,
  chatId,
  queryClient,
  handleUpdateTitle,
  handleSubmissionError,
  showCustomToast,
  editedMessageId,
  cutMessages,
  parseAndAddPdfContent,
  imageFiles,
  textFiles,
  addImagesToResources,
  editFinalContent
]);

  // Add this effect to handle message persistence after navigation
  useEffect(() => {
    if (chatId && messages.length === 0) {
      const cachedMessages = localStorage.getItem(`temp_messages_${chatId}`);
      if (cachedMessages) {
        try {
          const parsedMessages = JSON.parse(cachedMessages);
          setMessages(parsedMessages);
          localStorage.removeItem(`temp_messages_${chatId}`);
        } catch (error) {
          console.error('Error parsing cached messages:', error);
        }
      }
    }
  }, [chatId,messages.length]);

  // Update the navigation handling
  useEffect(() => {
    if (pendingNavigation) {
      // Store messages temporarily before navigation
      if (messages.length > 0) {
        localStorage.setItem(`temp_messages_${pendingNavigation}`, JSON.stringify(messages));
      }
      navigate(`/${pendingNavigation}`, { replace: true });
      setPendingNavigation(null);
    }
  }, [pendingNavigation, navigate, messages]);

  const selectMode = useCallback((mode) => {
    setCurrentMode(mode);
    localStorage.setItem('defaultChatMode', mode); // Add this line to persist the selection
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const incomingMessage = params.get('message');
    const incomingMode = params.get('mode') || 'tutor';
    
    if (incomingMessage) {
      // Set the mode first
      selectMode(incomingMode);
      
      // Set the message and submit it
      setInput(decodeURIComponent(incomingMessage));
      inputRef.current = decodeURIComponent(incomingMessage);
      
      // Use setTimeout to ensure state updates have propagated
      setTimeout(() => {
        handleSubmit({ preventDefault: () => {} });
      }, 100);
      
      // Clean up the URL
      const newUrl = window.location.pathname;
      window.history.replaceState({}, '', newUrl);
    }
  }, [location, handleSubmit, selectMode]); // Add any other dependencies as needed

    // Submit edited message
  const handleEditSubmit = useCallback((messageId, mode = currentMode) => {
    setEditedMessageId(messageId);
    setEditingMessageId(null);
    
    // Find message using messageId parameter instead of editedMessageId state
    const message = messages.find(msg => msg.id === editingMessageId);
    
    if (message) {
      const pdfTitles = message.pdf_title ? JSON.parse(message.pdf_title) : [];
      const imageTitles = message.imagestitle ? JSON.parse(message.imagestitle) : [];
      
      const updatedContent = editContent.includes('<|endofinput|>') ? (editContent) : (editContent + '\n<|endofinput|>' + 
        (pdfTitles.length > 0 ? ` [Document: ${pdfTitles.join(', ')}]` : '') + 
        (imageTitles.length > 0 ? ` [Image: ${imageTitles.join(', ')}]` : '')) ;

      // Set both states synchronously before submitting
      setEditFinalContent(updatedContent);
      setInput(updatedContent);
      inputRef.current = updatedContent;
      
      // Use the updatedContent directly in handleSubmit
      handleSubmit({ 
        preventDefault: () => {},
        editContent: updatedContent,
        mode: mode
      });
    }
  }, [editContent, handleSubmit, messages, editingMessageId, currentMode]);

  const handleRegenerate = useCallback((isLastMessage, message, mode) => {
    if (!isLastMessage) {
      let previousMessage = null;
      const messageIndex = messages.findIndex(msg => msg.id === message.id);
      if (messageIndex > 0) {
        previousMessage = messages[messageIndex - 1];
      } else {
        return;
      }
      handleEditStart(previousMessage);
      setTimeout(() => {
        handleEditSubmit(previousMessage.id, mode);
      }, 100);
      return;
    } else {
      RegenerateMessage(message.id, mode);
    }
  }, [messages, handleEditStart, RegenerateMessage, handleEditSubmit]);

  // Handle stopping the generation of AI messages
  // const handleStopGenerating = () => {
  //   if (abortController) {
  //     abortController.abort();
  //     setIsGenerating(false);

  //     setIsSubmitting(false);
      

  //   } else {
  //     console.warn('No active AbortController to abort.');
  //   }
  // };

  // Restore input in the textarea


  // Cleanup after submission
  // Abort controller cleanup on unmount
    useEffect(() => {
      
  }, []);

  // Handle pending navigation
  useEffect(() => {
    if (pendingNavigation && !abortControllerRef.current?.signal.aborted) {
      navigate(`/${pendingNavigation}`, { replace: true });
      setPendingNavigation(null);
    }
  }, [pendingNavigation, navigate]);

  // Update the toggleSidebar function
  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(prev => {
      const newState = !prev;
      localStorage.setItem('isSidebarOpen', JSON.stringify(newState));
      return newState;
    });
    
    if (window.innerWidth <= 768) {
      setIsHoverOpen(false);
    }
  }, []);

  // Add this helper function to count total attachments
  const countTotalAttachments = (messages) => {
    return messages.reduce((count, message) => {
      let fileCount = 0;
      
      // Count PDFs
      if (message.pdf_title) {
        try {
          const pdfTitles = JSON.parse(message.pdf_title);
          fileCount += Array.isArray(pdfTitles) ? pdfTitles.length : 0;
        } catch (e) {}
      }
      
      // Count Images
      if (message.imagestitle) {
        try {
          const imageTitles = JSON.parse(message.imagestitle);
          fileCount += Array.isArray(imageTitles) ? imageTitles.length : 0;
        } catch (e) {}
      }
      
      return count + fileCount;
    }, 0);
  };

  // Update handleFileChange function
  const handleFileChange = (e) => {
    if (!user) {
      showCustomToast('error', 'Please sign in to upload files.');
      return;
    }

    const files = Array.from(e.target.files);
    
    // Check per-message limit
    const currentPendingFiles = (textFiles?.length || 0) + (imageFiles?.length || 0);
    const remainingMessageSlots = MAX_FILES_PER_MESSAGE - currentPendingFiles;

    if (remainingMessageSlots <= 0) {
      showCustomToast('error', `You can only attach up to ${MAX_FILES_PER_MESSAGE} files per message.`);
      return;
    }

    // Get total attachments count including pending files
    const totalExistingAttachments = countTotalAttachments(messages) + currentPendingFiles;
    
    // Get user's tier and max files allowed
    const userTier = user?.subscription?.tier?.toLowerCase() || 'free';
    const maxFilesForTier = MAX_FILES_PER_CHAT[userTier];
    const remainingTierSlots = maxFilesForTier - totalExistingAttachments;

    if (remainingTierSlots <= 0) {
      if (userTier === 'free' || userTier === 'premium' || userTier === 'pro') {
        setSalesModalFeature('Additional File Upload');
        setShowSalesModal(true);
      } else {
        showCustomToast('error', `You've reached the maximum of ${maxFilesForTier} files for this chat.`);
      }
      return;
    }

    // Process only the number of files that fit within both limits
    const filesToProcess = files.slice(0, Math.min(remainingMessageSlots, remainingTierSlots));

    filesToProcess.forEach(file => {
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > MAX_FILE_SIZE_MB) {
        showCustomToast('error', `File ${file.name} exceeds ${MAX_FILE_SIZE_MB}MB limit`);
        return;
      }

      if (ACCEPTED_FILE_TYPES[file.type]) {
        if (file.type.startsWith('image/')) {
          setImageFiles(prev => [...prev, {
            name: file.name,
            file: file,
            id: `${file.name}-${file.size}-${Date.now()}`,
          }]);
        } else {
          setTextFiles(prev => [...prev, {
            name: file.name,
            file: file,
            id: `${file.name}-${file.size}-${Date.now()}`,
            content: null,
            isLoaded: false 
          }]);
          convertPDF(file);
        }
      }
    });

    // Show warning if some files were skipped
    if (files.length > Math.min(remainingMessageSlots, remainingTierSlots)) {
      showCustomToast('warning', `Only processing first ${Math.min(remainingMessageSlots, remainingTierSlots)} files due to limits`);
    }

    e.target.value = '';
  };

  // Update handleAttachClick to use the same counting logic
  const handleAttachClick = (e) => {
    e.preventDefault();
    
    if (!user) {
      showCustomToast('error', 'Please sign in to upload files.');
      return;
    }

    const currentPendingFiles = (textFiles?.length || 0) + (imageFiles?.length || 0);
    
    // Check per-message limit
    if (currentPendingFiles >= MAX_FILES_PER_MESSAGE) {
      showCustomToast('error', `You can only attach up to ${MAX_FILES_PER_MESSAGE} files per message.`);
      return;
    }

    // Check total attachments limit
    const totalExistingAttachments = countTotalAttachments(messages) + currentPendingFiles;
    const userTier = user?.subscription?.tier?.toLowerCase() || 'free';
    const maxFilesForTier = MAX_FILES_PER_CHAT[userTier];

    if (totalExistingAttachments >= maxFilesForTier) {
      if (userTier === 'free' || userTier === 'premium' || userTier === 'pro') {
        setSalesModalFeature('Additional File Upload');
        setShowSalesModal(true);
      } else {
        showCustomToast('error', `You've reached the maximum of ${maxFilesForTier} files for this chat.`);
      }
      return;
    }

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  
  const handleReportMessage = useCallback(async (message, isLastAIMessage) => {
    if(user){
      if(isLastAIMessage){
        await reportMessage(message);
        if(remainingChats > 0 || remainingChats === null){
          RegenerateMessage(message.id, (message.mode || currentMode));
        }
      } else {
        await reportMessage(message);
        if(remainingChats > 0 || remainingChats === null){
          handleEditStart(message);
        }
      }
    } else {
      await reportMessage(message);
    }
    showCustomToast('success', 'Message reported successfully');
  }, [user, remainingChats, RegenerateMessage, handleEditStart, showCustomToast, currentMode]);

  // Update the constant at the top
  const MAX_TOKEN_COUNT = 8000; // Adjust this value based on your model's requirements

    // Adjust textarea height dynamically
  const adjustTextareaHeight = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '23px';
      const newHeight = Math.min(textareaRef.current.scrollHeight, 250);
      textareaRef.current.style.height = `${newHeight}px`;
    }
  }, []);

  // Update the handleCharacterLimit function to use token counting
  const handleCharacterLimit = useCallback(async (text) => {
    try {
      const tokenCount = await getTokenCount(text);
      if (tokenCount > MAX_TOKEN_COUNT) {
        showCustomToast('error', `You've reached the character limit!`);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error counting tokens:', error);
      // Fallback to character count if token counting fails
      if (text.length > MAX_MESSAGE_LENGTH) {
        showCustomToast('error', `You've reached the character limit!`);
        return true;
      }
      return false;
    }
  }, [showCustomToast]);

  // Add this new state to track cursor position
  const [cursorPosition, setCursorPosition] = useState(null);

  // Update handleInputChange to store cursor position
  const handleInputChange = useCallback(async (e) => {
    const newValue = e.target.value;
    const cursorPos = e.target.selectionStart;
    
    const isOverLimit = await handleCharacterLimit(newValue);
    if (!isOverLimit) {
      setInput(newValue);
      inputRef.current = newValue;
      setCursorPosition(cursorPos);
      adjustTextareaHeight();

      // Generate auto-complete suggestions
      const suggestions = generateAutoCompleteSuggestions(newValue);
      setAutoCompleteSuggestions(suggestions);
      setShowAutoComplete(suggestions.length > 0);
      setSelectedSuggestionIndex(-1);
    }
  }, [handleCharacterLimit, adjustTextareaHeight]);

  // Add useEffect to restore cursor position
  useEffect(() => {
    if (cursorPosition !== null && textareaRef.current) {
      textareaRef.current.setSelectionRange(cursorPosition, cursorPosition);
      setCursorPosition(null); // Reset after applying
    }
  }, [cursorPosition, input]);

  // Add a new ref for edit textarea
  const editTextareaRef = useRef(null);

  // Add a separate height adjustment function for edit textarea
  const adjustEditTextareaHeight = useCallback(() => {
    if (editTextareaRef.current) {
      editTextareaRef.current.style.height = 'auto';
      const newHeight = Math.min(editTextareaRef.current.scrollHeight + 10, 350); // Add 10px padding and cap at 250px
      editTextareaRef.current.style.height = `${newHeight}px`;
    }
  }, []);

  // Add state for edit cursor position
  const [editCursorPosition, setEditCursorPosition] = useState(null);

  // Update handleEditInputChange to store cursor position
  const handleEditInputChange = useCallback(async (e) => {
    const newValue = e.target.value;
    const cursorPos = e.target.selectionStart;
    
    const isOverLimit = await handleCharacterLimit(newValue);
    if (!isOverLimit) {
      setEditContent(newValue);
      setEditCursorPosition(cursorPos);
      adjustEditTextareaHeight();
    }
  }, [handleCharacterLimit, adjustEditTextareaHeight]);

  // Add useEffect to restore edit cursor position
  useEffect(() => {
    if (editCursorPosition !== null && editTextareaRef.current) {
      editTextareaRef.current.setSelectionRange(editCursorPosition, editCursorPosition);
      setEditCursorPosition(null); // Reset after applying
    }
  }, [editCursorPosition, editContent]);

  const handleSuggestionSelect = useCallback((suggestion) => {
    setInput(suggestion.text);
    inputRef.current = suggestion.text;
    setShowAutoComplete(false);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  const [showLimitBanner, setShowLimitBanner] = useState(true);

    // Handle key down events for the textarea
    const handleKeyDown = useCallback((e) => {
      if (showAutoComplete) {
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedSuggestionIndex(prev => 
            prev < autoCompleteSuggestions.length - 1 ? prev + 1 : 0
          );
        } else if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedSuggestionIndex(prev => prev > 0 ? prev - 1 : autoCompleteSuggestions.length - 1);
        } else if (e.key === 'Enter' && selectedSuggestionIndex >= 0) {
          e.preventDefault();
          handleSuggestionSelect(autoCompleteSuggestions[selectedSuggestionIndex]);
        } else if (e.key === 'Tab') {
          e.preventDefault();
          if (selectedSuggestionIndex >= 0) {
            handleSuggestionSelect(autoCompleteSuggestions[selectedSuggestionIndex]);
          }
        } else if (e.key === 'Escape') {
          setShowAutoComplete(false);
        } else if (e.key === 'Backspace' && !input.trim()) {
          setShowAutoComplete(false);
        }
      } else if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        
        // Check the same conditions as the submit button
        const isDisabled = 
          isSubmitting || 
          isInputProcessing || 
          (!input.trim()) || 
          (remainingChats <= 0 && remainingChats != null);
  
        if (!isDisabled) {
          handleSubmit(e);
        }
  
        if(user && user.tier === 'free' && remainingChats === 0){
          setShowLimitBanner(true);
        }
      } else if (e.key === '/' && !input.trim()) {
        // Show sentence starters when typing '/'
        e.preventDefault();
        setShowAutoComplete(true);
        const suggestions = generateAutoCompleteSuggestions('/');
        if (suggestions.length > 0) {
          setAutoCompleteSuggestions(suggestions);
          setSelectedSuggestionIndex(0);
        }
      }
    }, [
      showAutoComplete,
      autoCompleteSuggestions,
      selectedSuggestionIndex,
      handleSuggestionSelect,
      input,
      isSubmitting,
      isInputProcessing,
      remainingChats,
      handleSubmit,
      user,
      setShowLimitBanner,
      setAutoCompleteSuggestions,
      setSelectedSuggestionIndex,
      setShowAutoComplete
    ]);

  // Memoize the textarea component
  const MemoizedTextarea = useMemo(() => {
    return function TextareaComponent() {
      return (
        <textarea
          ref={(el) => {
            textareaRef.current = el;
            if (el) {
              el.value = input;
              inputRef.current = input;
              adjustTextareaHeight();
              el.selectionStart = el.selectionEnd = el.value.length;
            }
          }}
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onInput={adjustTextareaHeight}
          placeholder="Type your message here..."
          aria-label="Chat input" 
          className="chat-input scrollable"
          disabled={isSubmitting}
          autoFocus={true}
        />
      );
    };
  }, [input, handleInputChange, handleKeyDown, adjustTextareaHeight, isSubmitting]);

  const MemoizedAutoCompleteDropdown = useMemo(() => {
    return function AutoCompleteDropdownComponent() {
      return (
        <AutoCompleteDropdown
          suggestions={autoCompleteSuggestions}
          handleSuggestionSelect={handleSuggestionSelect}
          setSelectedSuggestionIndex={setSelectedSuggestionIndex}
          selectedSuggestionIndex={selectedSuggestionIndex}
        />
      );
    };
  }, [autoCompleteSuggestions, handleSuggestionSelect, setSelectedSuggestionIndex, selectedSuggestionIndex]);

  // Function to extract thinking time and content from thinking string
  const extractThinkingInfo = useCallback((thinkingString) => {
    if(!thinkingString){
      return null;
    }

    const timeMatch = thinkingString.match(/\[Time: (\d+)s\]/);
    const contentMatch = thinkingString.match(/\[Content: (.*?)\]/);

    return {
      time: timeMatch ? parseInt(timeMatch[1]) : null,
      content: contentMatch ? contentMatch[1] : ''
    };
  }, []);
  
  // Update the renderMessage function
  const renderMessage = useCallback((message, index, array) => {
    
    const isEditing = message.id === editingMessageId;
    const activeIndex = 0;
    const activeMessageGroup = groupedMessages.find(group => group[0].id === message.id);
    const activeMessage = activeMessageGroup ? activeMessageGroup[activeIndex] : message;

    const isFirstAIMessage = message.is_ai && array.slice(0, index).every(msg => !msg.is_ai);
    const isLastMessage = index === array?.length - 1;
    const isLastAIMessage = message.is_ai && array.slice(index + 1).every(msg => !msg.is_ai);
    const imageTitles = message.imagestitle ? JSON.parse(message.imagestitle) : [];
    const pdfTitles = message.pdf_title ? JSON.parse(message.pdf_title) : [];
    const thinkingInfo = extractThinkingInfo(message.thinking) || null;

    // Add this condition at the start of the regenerating message check
    if (message.id === regeneratingMessageId) {
      return (
        <div key={message.id} className={`message ai ${isSidebarOpen || isHoverOpen ? 'sidebar-open' : ''}`}>
          <img src={theme === 'dark' ? CognoraLogoTransparentDark : studyBuddyLogo} alt="StudyBuddy AI" className={`ai-icon ${theme === 'dark' ? 'dark' : ''}`} style={{backgroundColor: 'transparent'}} />
          <div className="message-content">            
            <AIResponseRenderer 
              content={message.content} // Show the current accumulated content
              renderResource={renderResource} 
              messageId={message.id}
            />
          </div>
          {renderLoadingSymbol()} {/* Updated condition */}
        </div>
      );
    }

    /**
     * Renders content with support for multiple <quote></quote> tags.
     * Splits the content based on the <quote> tags and renders each part accordingly.
     * 
     * @param {string} content - The message content to render.
     * @returns {JSX.Element} The rendered content with styled quotes.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-unused-vars
    const renderContent = (content) => {
      const quoteRegex = /<quote>(.*?)<\/quote>/gs;
      // Updated regex to better handle code blocks
      const codeBlockRegex = /```([\w-]*)\n?([\s\S]*?)```/g;
      const parts = [];
      let lastIndex = 0;
      let match;

      // Function to process text and add it to parts
      const addTextToParts = (text, key) => {
        if (text.trim()) {
          parts.push(
            <MarkdownRenderer 
              key={key} 
              content={text} 
              disallowedElements={disallowedElements}
            />
          );
        }
      };

      // Iterate over all matches (quotes and code blocks)
      while ((match = quoteRegex.exec(content)) !== null || (match = codeBlockRegex.exec(content)) !== null) {
        const start = match.index;
        const fullMatch = match[0];

        // Add the text before the current match
        if (start > lastIndex) {
          const text = content.substring(lastIndex, start);
          addTextToParts(text, lastIndex);
        }

        if (fullMatch.startsWith('<quote>')) {
          // Handle quote content
          const quoteContent = match[1]?.trim();
          if (quoteContent) {
            parts.push(
              <div key={start} className="quote-content">
                {quoteContent}
              </div>
            );
          }
        }
        lastIndex = start + fullMatch?.length;
      }

      // Add any remaining text after the last match
      if (lastIndex < content?.length) {
        const text = content.substring(lastIndex);
        addTextToParts(text, lastIndex);
      }

      return <>{parts}</>;
    };

    const messageKey = `${message.id}-${index}`;


    return (
      <div 
        key={messageKey}
        className={`message ${message.is_ai ? 'ai' : 'user'} ${isSidebarOpen || isHoverOpen ? 'sidebar-open' : ''} ${isResourcesVisible ? 'resources-visible' : ''}`}
        ref={isLastMessage ? lastMessageRef : null}
      >
        {message.is_ai ? (
          <img src={theme === 'dark' ? CognoraLogoTransparentDark : studyBuddyLogo} alt="StudyBuddy AI" className={`ai-icon ${theme === 'dark' ? 'dark' : ''}`} style={{backgroundColor: 'transparent'}} />
        ) : null}
        <div className={`message-content ${isEditing ? 'editing' : ''}`}>

          {isEditing ? (
            <form onSubmit={(e) => { e.preventDefault(); handleEditSubmit(message.id, message); }}>
              <textarea
                ref={editTextareaRef}
                className="edit-input"
                value={editContent.split('<|endofinput|>')[0]} // Remove trim()
                onChange={handleEditInputChange}
                onInput={adjustEditTextareaHeight}
                autoFocus={true}
                spellCheck="true"
              />
            </form>
          ) : (
            message.is_ai ? (
              <>
                <ThinkingPanel 
                  thinkingTime={thinkingInfo?.time} 
                  currentThinkingTime={isLastAIMessage ? currentThinkingTime : null} 
                  thinkingContent={thinkingInfo?.content}
                />
                <AIResponseRenderer
                  content={activeMessage.content}           
                  renderResource={renderResource}
                  messageId={message.id}
                />
                {isGeneratingResponse && isLastAIMessage && !regeneratingMessageId && !editedMessageId && (
                  renderLoadingSymbol()
                )}
              </>
            ) : (
              <div className="user-message-content">
                <div className="user-message-left">
                  {(activeMessage.content.split('<|endofinput|>')[0].trim()) || ''}
                </div>
                <div className="user-message-right">
                  <div className="user-icon" aria-label="User icon" title="User icon">
                    {user?.name?.charAt(0).toUpperCase()}
                  </div>
                  {(imageTitles?.length > 0 || pdfTitles?.length > 0) ? (
                    <button className="file-tag" onClick={() => setIsResourcesVisible(true)}>
                      {imageTitles?.length > 0 && (
                        <>
                          <Image01Icon size={14} />
                          <span>{imageTitles.length}</span>
                        </>
                      )}
                      {pdfTitles?.length > 0 && (
                        <>
                          <FileAttachmentIcon size={14} />
                          <span>{pdfTitles.length}</span>
                        </>
                      )}
                    </button>
                  ) : null}
                </div>
              </div>
            )
          )}
          {message.is_ai ? (
            isGeneratingResponse && isLastAIMessage && !regeneratingMessageId && !editedMessageId ? (
              null
            ) : (
              <div className="message-footer">
                <div className={`message-actions ${(isLastAIMessage || windowWidth < 768) ? 'last-message' : 'not-last-message'}`}>
                  <CopyButton activeMessage={activeMessage} />           
                  <button className="action-button thumbs-down-button" onClick={() => handleReportMessage(message, isLastAIMessage)}>
                    <ThumbsDownIcon size={18} />
                    <span className="hover-text bottom">Report Response</span>
                  </button>
                  {isLastAIMessage && user && (
                    <>
                      <RegenerateButton message={message} currentMode={message.mode || currentMode} onRegenerate={handleRegenerate} isLastMessage={isLastAIMessage} isFirstMessage={isFirstAIMessage} />
                    </>
                  )}
                </div>
              </div>
            )
          ) : (
            <div className={`message-footer ${isEditing ? 'editing' : ''}`}>
              {isEditing ? (
                <>
                  <div className="edit-actions">
                    <button type="button" onClick={handleEditCancel} className="cancel-button">Cancel</button>
                    <button type="submit" onClick={handleEditSubmit} className="save-button">Save</button>
                  </div>
                  <div className="edit-disclaimer">
                    Note: Editing will remove subsequent messages in this conversation, this action cannot be undone.
                  </div>
                </>
              ) : (
                <>
                    {user && (remainingChats === null || remainingChats > 0) && (
                      <button 
                        className="action-button edit-button" 
                        title="Edit message" 
                        onClick={() => handleEditStart(message)}
                      >
                        <Edit01Icon size={20} />
                        <span className="hover-text bottom">Edit Message</span>
                      </button>
                    )}               
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }, [
    editedMessageId,
    editingMessageId,
    editContent,
    windowWidth,
    handleEditSubmit,
    groupedMessages,
    remainingChats,
    handleRegenerate,
    regeneratingMessageId,
    renderResource,
    isSidebarOpen,
    isHoverOpen,
    isResourcesVisible,
    setIsResourcesVisible,
    currentMode,
    lastMessageRef,
    editTextareaRef,
    handleEditCancel,
    handleEditStart,
    handleReportMessage,
    user,
    theme,
    handleEditInputChange,
    adjustEditTextareaHeight,
    isGeneratingResponse,
    currentThinkingTime,
    extractThinkingInfo
  ]);



  // Move getRandomHeader outside the component to prevent recreating it on every render
  const getRandomHeader = (userName) => {
    const greetingWords = ['Hello', 'Hi', 'Hey', 'Welcome', 'Good day'];
    const formattedName = userName && userName?.length > 2 ? ` ${userName.split(' ')[0].charAt(0).toUpperCase() + userName.split(' ')[0].slice(1).toLowerCase()}` : '';
    const messages = [
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! I'm your AI tutor.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! How can I help you learn?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What shall we explore today?`, 
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Ready to learn?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What questions do you have?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! How can I help?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What topic interests you?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Let's tackle any subject.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! I'm here to help.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Let's learn together.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Ready to begin?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What's on your mind?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! How can I assist you?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What interests you?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Let's get started.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What can we solve?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Let's explore together.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Ready to discover?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What's your goal?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What sparks your interest?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! How can I guide you?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Let's overcome challenges.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Where shall we start?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What's on your mind?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Let's begin learning.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Ready to study?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! What's your focus?`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Let's learn something.`,
      `${greetingWords[Math.floor(Math.random() * greetingWords?.length)]}${formattedName}! Ready to excel?`,
    ];

    return messages[Math.floor(Math.random() * messages?.length)];
  };

  // Render default display when there are no messages
  const renderDefaultDisplay = () => (
    <div className= "default-display">
      <img 
        src={theme === 'light' ? CognoraLogo : CognoraLogoTransparentDark} 
        alt="Cognora Logo" 
        className="imglogo" 
      />
      {windowWidth > 600 ? (
        <h1 className="dynamic-header">
          {dynamicHeader}
        </h1>
      ) : (
        <h1 className="dynamic-header">
          Hello!
        </h1>
      )}

      {user && remainingChats!= null && remainingChats < 1 && filteredMessages?.length === 0 && renderChatLimitBanner()}
      
      <div className={`input-area empty ${user ? 'user' : ''}`}>

      <InputArea
        user={user}
        textFiles={textFiles}
        imageFiles={imageFiles}
        removeImageFile={removeImageFile}
        removeTextFile={removeTextFile}
        MemoizedSubmitButton={MemoizedSubmitButton}
        MemoizedAutoCompleteDropdown={MemoizedAutoCompleteDropdown}
        showAutoComplete={showAutoComplete}
        ModeSelector={MemoizedSelector}
        handleSubmit={handleSubmit}
        MemoizedTextarea={MemoizedTextarea}
        handleAttachClick={handleAttachClick}
        handleFileChange={handleFileChange}
        fileInputRef={fileInputRef}
        remainingChats={remainingChats}
        windowWidth={windowWidth}
        isChat={false}
      />


    {!user && !authLoading && filteredMessages?.length === 0 && (
        <div className="learn-more-container">
          <Link to="/pricing" className="learn-more-button">
            <SparklesIcon size={18} />
            Learn More
          </Link>
        </div>
      )}
      
  </div>


          
      {user && (
        <>
        {chatsData?.chats && chatsData?.chats?.length > 0 && (
          <RecentChats chats={chatsData.chats} />
        )}
        </>
      )}
      </div>
    );

  


  // Move this outside the renderChatLimitBanner function


  // Update the render function
  const renderChatLimitBanner = () => {
    const currentRemaining = user ? remainingChats : remainingGuestChats;
    
    if (!showLimitBanner || currentRemaining > 0) return null;

    return (
      <div className="chat-limit-banner">
        <div className="chat-limit-content">
          <span>
            Daily limit reached
            <Link to="/pricing" className="signup-link">
              Upgrade
            </Link>
          </span>
        </div>
        {window.innerWidth > 500 && (
          <button 
            className="close-banner"
            onClick={() => setShowLimitBanner(false)}
            aria-label="Close banner"
          >
            ✕
          </button>
        )}
      </div>
    );
  };

// Add debounce to prevent too frequent saves
const SAVE_DELAY = 500; // ms

// Load drafts
useEffect(() => {
  try {
    // Single source of truth for draft loading
    const drafts = {
      current: localStorage.getItem('draft_current'),
      new: localStorage.getItem('draft_new'),
      chatSpecific: chatId ? localStorage.getItem(`draft_${chatId}`) : null
    };

    const draftToUse = chatId === 'new' 
      ? (drafts.current || drafts.new)
      : (drafts.chatSpecific || drafts.new);

    if (draftToUse) {
      try {
        // Parse the JSON and extract just the content
        const parsedDraft = JSON.parse(draftToUse);
        const draftContent = parsedDraft.content || '';
        
        setInput(draftContent);
        if (inputRef.current) inputRef.current = draftContent;
        if (textareaRef.current) textareaRef.current.value = draftContent;
      } catch (e) {
        // Fallback for old draft format
        setInput(draftToUse);
        if (inputRef.current) inputRef.current = draftToUse;
        if (textareaRef.current) textareaRef.current.value = draftToUse;
      }
    }
  } catch (error) {
    console.error('Error loading draft:', error);
  }
}, [chatId]);

// Add these constants at the top of your file
const MAX_DRAFTS = 50; // Maximum number of drafts to keep
const DRAFT_PREFIX = 'draft_';

// Add this function to manage draft limits
const enforceDraftLimit = () => {
  try {
    // Get all draft keys
    const draftKeys = Object.keys(localStorage)
      .filter(key => key.startsWith(DRAFT_PREFIX))
      .sort((a, b) => {
        // Sort by last modified timestamp
        const timeA = localStorage.getItem(a)?.timestamp || 0;
        const timeB = localStorage.getItem(b)?.timestamp || 0;
        return timeB - timeA;
      });

    // Remove oldest drafts if we exceed the limit
    if (draftKeys?.length > MAX_DRAFTS) {
      draftKeys
        .slice(MAX_DRAFTS)
        .forEach(key => localStorage.removeItem(key));
    }
  } catch (error) {
    console.error('Error enforcing draft limit:', error);
  }
};

// Update your draft saving effect to include the limit check
useEffect(() => {
  const saveDraft = debounce(() => {
    try {
      if (input.trim() && !isSubmitting) {
        // Save the draft with timestamp
        const draftData = {
          content: input,
          timestamp: Date.now()
        };

        if (!chatId) {
          localStorage.setItem('draft_new', JSON.stringify(draftData));
        } else if(chatId === 'new'){
          localStorage.setItem('draft_new', JSON.stringify(draftData));

        } else {
          localStorage.setItem(`draft_${chatId}`, JSON.stringify(draftData));
          localStorage.setItem('draft_current', JSON.stringify(draftData));
        }

        // Check and enforce draft limit
        enforceDraftLimit();
      }
    } catch (error) {
      console.error('Error saving draft:', error);
    }
  }, SAVE_DELAY);

  saveDraft();
  return () => saveDraft.cancel();
}, [input, isSubmitting, chatId]);

// Add cleanup for old drafts
useEffect(() => {
  const DRAFT_MAX_AGE = 7 * 24 * 60 * 60 * 1000; // 7 days

  const cleanupOldDrafts = () => {
    try {
      Object.keys(localStorage).forEach(key => {
        if (key.startsWith('draft_')) {
          const draftData = localStorage.getItem(key);
          if (draftData) {
            const timestamp = JSON.parse(draftData).timestamp;
            if (Date.now() - timestamp > DRAFT_MAX_AGE) {
              localStorage.removeItem(key);
            }
          }
        }
      });
    } catch (error) {
      console.error('Error cleaning up drafts:', error);
    }
  };

  cleanupOldDrafts();
}, []); // Run once on component mount

  // Update the renderLoadingSymbol function
  const renderLoadingSymbol = () => (
    <div className="ai-typing-indicator">
      <div className="typing-dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );

  // Initial textarea height
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '23px';
    }
  }, []);



  // Handle search query changes
  useEffect(() => {
    if (searchQuery) {
      const lowercasedQuery = searchQuery.toLowerCase();
      setFilteredMessages(prevMessages => 
        messages.filter(message =>
          message?.content?.toLowerCase().includes(lowercasedQuery)
        )
      );
    } else {
      setFilteredMessages(messages);
    }
  }, [searchQuery, messages]); // Keep both dependencies

  // Add this useEffect to update currentMode when localStorage changes
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'defaultChatMode') {
        setCurrentMode(e.newValue || 'qa');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []); // No dependencies needed

  // Add this function to toggle the Resources section
  const toggleResources = () => {
    setIsResourcesVisible(prev => !prev);
    if (isMobile) {
        setIsSidebarOpen(false);
      }
    };

  // Add this new function to handle closing the Resources section
  const closeResources = () => {
    setIsResourcesVisible(false);
  };



  const renderExpanded = () => {
    return (
    <ExpandedResource
      resource={expandedResource}
      onClose={() => {
       // Use a callback to ensure state updates are complete before modifying URL parameters
        setSearchParams(prevParams => {
          const newParams = new URLSearchParams(prevParams);
          newParams.delete('resource');
          newParams.delete('version');
          setExpandedResource(null);
          setIsResourcesExpanded(false);
          return newParams;
        });

      }}
      onImprove={handleImprove}
      onExplain={handleExplain}
      allVersions={resources[chatId]?.filter(r => r.title === expandedResource.title)}
      desiredVersion={expandedVersion}
      showCustomToast={showCustomToast}
      isSpaceChat={isSpaceChat}  // Add this prop
      onAddToSpace={handleAddArtifactToSpace}  // Add this prop
    />
    )
  }

  // Add this new useEffect
  useEffect(() => {
    if (isResourcesExpanded) {
      setIsSidebarOpen(false);
    }
  }, [isResourcesExpanded]);

  // Check for state from auth flow
  useEffect(() => {
    if (location.state) { 
      const { inputValue } = location.state;
      if (inputValue) {
        setInput(inputValue);
        // Clear the navigation state after using it
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    }

    // Check for pending state from checkout
    const pendingState = localStorage.getItem('pendingChatState');
    if (pendingState) {
      const { inputValue } = JSON.parse(pendingState);
      if (inputValue) setInput(inputValue);

      // Remove from localStorage after using it
      localStorage.removeItem('pendingChatState');
    }
  }, [location]);

  // Add this effect to handle auto-submit on page load
  useEffect(() => {
    if (location.state?.autoSubmitMessage) {
      // Set the input and mode
      setInput(location.state.autoSubmitMessage);
      if (location.state.mode) {
        selectMode(location.state.mode);
      }
      
      // Auto submit after a short delay
      const timer = setTimeout(() => {
        handleSubmit(new Event('submit'));
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [location.state, handleSubmit, selectMode]);

  // Add this new component for the chat not found message
  const ChatNotFound = () => { 
    const message = messagesError?.isAuthError
      ? "Please log in to view this chat."
      : "This chat doesn't exist.";

    return (
      <div className="chat-not-found">

        <div className="chat-not-found-content">
        <img src={theme === 'light' ? CognoraLogo : CognoraLogoTransparentDark} alt="Cognora Logo" className="notexistslogo" />
          <h2>Chat Not Found</h2>
          <p>{message}</p>
          
          {messagesError?.isAuthError ? (
            <Link to="/login" className="login-button">
              Log In
            </Link>
          ) : (
            <button 
              type="submit" 
              className="submit-button"
              onClick={() => handleAddChat()}
            >
              Create New Chat
            </button>
          )}
        </div>
      </div>
    );
  };



  const MemoizedCustomToast = memo(({ message, type, onClose }) => {
    return (
      <div className={`custom-toast ${type}`}>
        <span>{message}</span>
      </div>
    );
  }, (prevProps, nextProps) => {
    return (
      prevProps.message === nextProps.message &&
      prevProps.type === nextProps.type
    );
  });

  // Update the useEffect that handles resources visibility
  useEffect(() => {
    const hasMaterialsOrResources = resources?.[chatId]?.length > 0;
    
    setIsResourcesVisible(() => 
      chatId !== 'new' && 
      chatId !== undefined && 
      user?.id && // Only depend on user ID
      hasMaterialsOrResources && 
      !isMobile
    );
  }, [chatId, resources, user?.id, isMobile]); // Simplified dependencies

  // Add this effect to handle chat switching
  useEffect(() => {
    if (chatId && expandedResource) {
      // Check if the current expanded resource belongs to the current chat
      const chatResources = resources[chatId] || [];
      const resourceExists = chatResources.some(
        resource => resource.title === expandedResource.title
      );

      if (!resourceExists) {
        // Reset expanded resource and related states
        setExpandedResource(null);
        setIsResourcesExpanded(false);
        
        // Remove resource-related query params
        const newParams = new URLSearchParams(searchParams);
        newParams.delete('resource');
        newParams.delete('version');
        setSearchParams(newParams);
      }
    }
  }, [chatId, expandedResource, resources, setSearchParams, searchParams]);



  // Update the useEffect cleanup for message streaming
  useEffect(() => {
    return () => {
      // Instead of aborting the request, just clean up the frontend state
      setIsGeneratingResponse(false);
      setIsSubmitting(false);
      
      // Don't abort the controller, let backend continue
      abortControllerRef.current = null;
    };
  }, []);

  // Add this new useEffect to focus the textarea on page load
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []); // Empty dependency array means this runs once on mount

  // Update the header section to show different search UI based on screen size
  const renderHeaderCenter = () => {
    if (!user) return null;

    return (
      <div className="search-container">
          {windowWidth > 768 ? (
            <input 
              type="text" 
              placeholder="Search conversations..." 
              className="search-input"
              value={searchQuery}
              onChange={handleSearch}
              aria-label="Search conversations"
              inputMode="search"
              autoComplete="off"
            />
          ) : null}
      </div>
    );
  };

    // Add memoization for the sidebar component
    const MemoizedSidebar = useMemo(() => (
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        updateTitle={handleUpdateTitle}
        showCustomToast={showCustomToast}
        showSalesModal={showSalesModal}
        setSalesText={setSalesModalFeature}
        handleAddChat={handleAddChat}
        onMouseEnter={handleHoverOpen}
        onMouseLeave={handleHoverClose}
        isHoverOpen={isHoverOpen}
        isLoading={dataLoading}
      />
    ), [
      isSidebarOpen,
      toggleSidebar,
      handleUpdateTitle,
      showCustomToast,
      showSalesModal,
      setSalesModalFeature,
      handleAddChat,
      handleHoverOpen,
      handleHoverClose,
      isHoverOpen,
      dataLoading
    ]);

  
  // State variables for storing chat data for comparison
  const [chat1, setChat1] = useState(null);
  const [chat2, setChat2] = useState(null);
  // State for comparison modal
  const [isComparisonModalOpen, setIsComparisonModalOpen] = useState(false);

  const [messagePairId, setMessagePairId] = useState(null);

  //const openComparisonModal = () => setIsComparisonModalOpen(false);
  const closeComparisonModal = () => {
    setIsComparisonModalOpen(false);
    setChat1(null);
    setChat2(null);
    setMessagePairId(null);
  };
  // Modal component for displaying two chats side by side
  const ComparisonModal = ({ isOpen, onClose, chat1, chat2 }) => {
    
    if (!isOpen) return null;
    const handleSelection = async (selectedContent) => {
      try {
        await selectComparisonMessage(messagePairId, selectedContent);
        onClose();
        // Optionally refresh messages or update UI
      } catch (error) {
        console.error('Error selecting comparison:', error);
        showCustomToast('error', 'Failed to select comparison');
      }
    };

    return (
      <div className="comparison-modal">
        <div className="comparison-modal-content">
          <div className="comparison-modal-header">
            <h2>Which do you like better?</h2>
          </div>
          
          <div className="comparison-panels">
            {/* Left chat panel */}
            <div 
              className="comparison-panel"
              onClick={() => handleSelection(chat1?.content)}
            >
              <h3>{chat1?.title || 'Chat 1'}</h3>
              <div>
                {chat1?.messages?.map((message, index) => (
                  <div 
                    key={`chat1-${index}`}
                    className={`comparison-message ${message.role}`}
                  >
                    <AIResponseRenderer content={message.content} renderResource={renderResource}/>
                  </div>
                ))}
              </div>
            </div>

            {/* Right chat panel */}
            <div 
              className="comparison-panel"
              onClick={() => handleSelection(chat2?.content)}
            >
              <h3>{chat2?.title || 'Chat 2'}</h3>
              <div>
                {chat2?.messages?.map((message, index) => (
                  <div
                    key={`chat2-${index}`}
                    className={`comparison-message ${message.role}`}
                  >
                    <AIResponseRenderer content={message.content} renderResource={renderResource}/>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MemoizedSubmitButton = useMemo(() => {
    return function SubmitButtonComponent() {
      return (
        <SubmitButton
          isSubmitting={isSubmitting}
          isInputProcessing={isInputProcessing} 
          input={input}
          remainingChats={remainingChats}
          isFileProcessing={isFileProcessing}
          onStopStreaming={handleStopStreaming}
        />
      );
    };
  }, [isSubmitting, isInputProcessing, input, remainingChats, isFileProcessing, handleStopStreaming]);
  

  const MemoizedSelector = useMemo(() => {
    return function ModeSelectorComponent({ isChat }) {
      return (
        <ModeSelector currentMode={currentMode} selectMode={selectMode} isChat={isChat} />
      );
    };
  }, [currentMode, selectMode]);

  // Add this new useEffect near your other useEffect hooks
  useEffect(() => {
    // Only proceed if we have a valid chatId
    if (chatId && chatId !== 'new' && chatId !== 'undefined') {
      // Update URL based on whether it's a space chat or not
      if (spaceId && isSpaceChat) {
        window.history.replaceState({}, '', `/ss/${chatId}`);
      } else if (!isSpaceChat) {
        window.history.replaceState({}, '', `/${chatId}`);
      }
    }
  }, [chatId, isSpaceChat, spaceId]); // Dependencies that trigger the effect

  // Add this new component for the space selection dropdown
  const AddToSpaceButton = ({ chatId, onAdd }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    
    // Fetch available spaces
    const { data: spaces = [] } = useQuery(
      'spaces',
      () => queryClient.getQueryData('spaces') || [],
      {
        enabled: false // Don't fetch, just access cache
      }
    );

    // Handle clicking outside to close dropdown
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleAddToSpace = async (spaceId) => {
      try {
        await addChatToSpace(spaceId, chatId);
        onAdd(spaceId);
        setIsOpen(false);
        setIsSidebarOpen(false);
        queryClient.invalidateQueries('space-chats');
        showCustomToast('success', 'Chat added to space successfully');
      } catch (error) {
        console.error('Error adding chat to space:', error);
        showCustomToast('error', 'Failed to add chat to space');
      }
    };

    return (
      <div className="add-to-space-container" ref={dropdownRef}>
        <button
          className=""
          onClick={() => {
            if (!isSpaceChat && filteredMessages?.length === 0) {
              if (user?.tier !== 'free' && user?.tier !== 'premium') {
                navigate('/study-spaces');
              } else{
                navigate('/pricing#study-spaces');
              }
            } else {
              setIsOpen(!isOpen);
            }
          }}
        >
          {!isSpaceChat && filteredMessages?.length === 0 ? (
            <>
              <div className="rounded-icon">
                <DashboardCircleIcon size={13} />
              </div>
              <span className="hover-text bottom">Go to Study Spaces</span>
            </>
          ) : (
            <>
              <PlusSignCircleIcon size={24} />
              <span className="hover-text bottom">Add Chat to Study Space</span>
            </>
          )}
        </button>
        {isOpen && spaces?.length > 0 && (
        <div className="space-dropdown">
          <div className="space-dropdown-header">
            <span>Add to Space</span>
            <Link to="/study-spaces" className="spaces-link">
              <LinkSquare01Icon size={14} />
              View All
            </Link>
          </div>
          {spaces.map((space) => (
            <div
              key={space.id}
              className="space-option"
              onClick={() => handleAddToSpace(space.id)}
            >
              {space.title}
            </div>
          ))}
        </div>
      )}
      </div>
    );
  };

    // Memoize the container className calculation
    const containerClassName = useMemo(() => {
      return `messages-container ${isSidebarOpen || isHoverOpen ? 'sidebar-open' : ''} ${isResourcesVisible ? 'resources-open' : ''}`;
    }, [isSidebarOpen, isHoverOpen, isResourcesVisible]);
  

    // Add this new state
    const [showNamePrompt, setShowNamePrompt] = useState(false);

    // Add this useEffect to check if we need to show the name prompt
    useEffect(() => {
      setShowNamePrompt(Boolean(user?.id && !user?.s_name));
    }, [user?.id, user?.s_name]); 


  const handleAddToSpace = (spaceId) => {
    // Refresh the chats data after adding to space
    queryClient.invalidateQueries('chats');
    
    // Navigate to the space chat view
    navigate(`/ss/${chatId}?spaceId=${spaceId}`);
  };

  // Add the button to your message header or chat header section
  // Find where you render the chat header and add:


  // Add this new handler
  const handleAddArtifactToSpace = async (resource) => {
    try {
      const wrappedContent = wrapContentInArtifactTags(resource);
      if (!wrappedContent) {
        showCustomToast('error', 'Failed to process resource');
        return;
      }

      if (!spaceId) {
        showCustomToast('error', 'No space ID found');
        return;
      }

      await addArtifactToSpace(spaceId, wrappedContent);
      showCustomToast('success', 'Added to space successfully');
          } catch (error) {
      console.error('Failed to add artifact to space:', error);
      showCustomToast('error', 'Failed to add to space');
    }
  };

  // Add this helper function
  const wrapContentInArtifactTags = (resource) => {
    let wrappedContent = '<artifacts>\n';
    wrappedContent += `<chatid>${chatId}</chatid>\n`;
    
    switch (resource.type) {
      case 'essay':
        wrappedContent += `<essay>\n<title>${resource.title}</title>\n<content>${resource.content}</content>\n</essay>`;
        break;
      case 'code':
        wrappedContent += `<code language="${resource.language}" title="${resource.title}">${resource.content}</code>`;
        break;
      case 'visualization':
        wrappedContent += `<visualization title="${resource.title}">${resource.content}</visualization>`;
        break;
      default:
        console.error('Unknown resource type:', resource.type);
        return null;
    }
    
    wrappedContent += '\n</artifacts>';
    return wrappedContent;
  };

    // Update the useEffect that handles URL parameters and state
  useEffect(() => {
    // Handle immediate redirection for space chats
    if (chatId && spaceId && !isSpaceChat) {
      navigate(`/ss/${chatId}?spaceId=${spaceId}`, { replace: true });
      return;
    }

    // Extract resource title and version from URL parameters
    const resourceTitle = searchParams.get('resource');
    const version = searchParams.get('version');
    const stateTitle = location.state?.title;

    // Determine the title to find
    const titleToFind = resourceTitle || stateTitle;

    // If a title is found, set the expanded resource
    if (titleToFind && chatId && resources[chatId]) {
      const resource = resources[chatId].find(r => r.title === titleToFind);
      if (resource) {
        setExpandedResource(resource);
        setIsResourcesExpanded(true);
        setExpandedVersion(version || '0');

        // Clear the navigation state
        window.history.replaceState({}, document.title);
      }
    }
  }, [chatId, spaceId, isSpaceChat, navigate, searchParams, location.state?.title, resources]);
  // Add this useEffect to handle clicks outside the sidebar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (window.innerWidth <= 568) {  // Only on mobile devices
        const sidebar = document.querySelector('.sidebar');
        const chatHeader = document.querySelector('.chat-header');
        
        // Check if click is outside sidebar and chat header
        if (sidebar && 
            chatHeader && 
            !sidebar.contains(event.target) && 
            !chatHeader.contains(event.target)) {
          setIsSidebarOpen(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleImageClick = (url, title) => {
    setIsImageModalOpen(true);
    setImageUrl(url);
    setImageTitle(title);
  };



  // if (isLoading) {
  //   return (
  //     <div className="chat-area">
  //       <div className="chat-header">
  //         <div className="loading-header" />
  //       </div>
  //       <LoadingMessages />
  //     </div>
  //   );
  // }



  // Add this function to handle name submission
  const handleNameSubmit = async (name) => {
    try {
      // Only update if name is provided and not empty
      if (!name?.trim()) {
        showCustomToast('error', 'Please enter a valid name');
        return;
      }

      // Create profile update object with existing data
      const profileData = {
        name: user?.name || '', // Keep existing name
        phone_number: user?.phone_number || '', // Keep existing phone number
        s_name: name.trim() // Update study name
      };

      await updateProfile(profileData);
      
      // Update local user object
      if (user) {
        user.s_name = name.trim();
      }
      
      setShowNamePrompt(false);
    } catch (error) {
      console.error('Error updating name:', error);
      showCustomToast('error', 'Failed to update name preference');
    }
  };

  // Add this component inside Chats component
// Add this component near your other component declarations
const NamePromptModal = ({ isOpen, onClose, onSubmit }) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.trim().length < 2) {
      setError('Name must be at least 2 characters');
      return;
    }
    onSubmit(name);
  };

  if (!isOpen) return null;

  return (
    <div className="name-prompt-overlay">
      <div className="name-prompt-modal">
      <img src={CognoraLogo} alt="Cognora Logo" className="imglogo" />
        <h2>Hello!</h2>
        <p>What should I call you?</p>
        
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setError('');
            }}
            placeholder="Enter your name"
            autoFocus
            maxLength={50}
          />
          {error && <div className="name-prompt-error">{error}</div>}
          
          <div className="name-prompt-buttons">
            <button type="submit" className="name-prompt-button primary">
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};



  return (
    <div 
      className={`chat-page ${isSidebarOpen || isHoverOpen ? 'sidebar-open' : ''} ${isResourcesVisible ? 'resources-open' : ''}`}
      {...getRootProps()}
    >

      <ImageModal
        isOpen={isImageModalOpen}
        onClose={() => {
          setIsImageModalOpen(false);
          setImageUrl("");
        }}
        imageUrl={imageUrl}
        title={imageTitle}
      />

            {showToast && (
        <MemoizedCustomToast
          message={toastMessage}
          type={toastType}
          onClose={() => setShowToast(false)}
        />
      )}

      {user && (
        <div 
          className={`sidebar-trigger-area ${isSpaceChat ? 'space-chat' : ''}`}
          onMouseEnter={handleHoverOpen}
          onMouseLeave={handleHoverClose}
        />
      )}
      <HelmetProvider>
        <Helmet>
          <title>StudyBuddy</title>
          <meta name="description" content="StudyBuddy is your personal AI tutor powered by advanced language models. Get instant help with homework, exam prep, and learning across all subjects." />
          
          {/* Enhanced keywords */}
          <meta name="keywords" content="StudyBuddy, Cognora AI, artificial intelligence tutor, intelligent tutoring, homework help, online tutoring, educational chatbot, study assistant, exam preparation, personalized learning, AI education, virtual tutor, academic support, intelligent tutoring system, machine learning education, ai chat, ai, study buddy, study bdy, study buddy 3, study buddy login, study buddy website, study buddy, study bddy, chat cognora, chat, chatai, chatbot study buddy, studyb, studybuddy login, studybuddy, study buddy chat, cognora, cognora chat, cognora studybuddy, cognora" />
          {/* Additional meta tags */}
          <meta property="og:title" content="StudyBuddy - Your AI-Powered Learning Companion" />
          <meta property="og:description" content="Experience personalized tutoring with our advanced AI technology. Get instant help with any subject, 24/7." />
          
          {/* Primary meta tags */}
          <link rel="canonical" href={`https://studdybuddy.ca/`} />
          
          {/* OpenGraph tags */}
          <meta property="og:title" content={`StudyBuddy`} />
          <meta property="og:description" content="Talk with StudyBuddy, your AI-powered study companion" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://studdybuddy.ca" />
          
          {/* Additional SEO meta tags */}
          <meta name="author" content="Cognora" />
          <meta name="robots" content="index, follow" />
          
          {/* Schema.org markup enhancement */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "SoftwareApplication",
              "name": "StudyBuddy",
              "applicationCategory": "EducationalApplication",
              "creator": {
                "@type": "Organization",
                "name": "Cognora",
                "url": "https://cognora.ca"
              },
              "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "USD"
              },
              "description": "AI-powered educational platform by Cognora offering personalized tutoring and academic support",
              "featureList": [
                "Advanced AI tutoring technology",
                "Interactive learning interface",
                "PDF and image analysis and comprehension",
                "Multiple learning modes",
                "Personalized study assistance",
                "Real-time AI responses"
              ]
            })}
          </script>
        </Helmet>
      </HelmetProvider>

      {/* Enhanced visually hidden headings */}
      <h1 className="visually-hidden">StudyBuddy by Cognora: Leading AI-Powered Educational Assistant</h1>
      <h2 className="visually-hidden">Next-Generation Learning with Cognora's AI Technology</h2>

      {/* Enhanced hidden semantic content */}
      <div className="visually-hidden">
        <h3>Cognora's Advanced AI Learning Features</h3>
        <ul>
          <li>Powered by Cognora's proprietary AI technology</li>
          <li>Integration with GPT, Claude, and Gemini AI</li>
          <li>Industry-leading educational AI platform</li>
          <li>Advanced natural language processing for education</li>
          <li>Premium alternative to traditional tutoring services</li>
        </ul>
        
        <h3>Comprehensive Educational Support</h3>
        <ul>
          <li>Advanced STEM subjects and humanities support</li>
          <li>Professional academic writing assistance</li>
          <li>Standardized test preparation</li>
          <li>Research methodology guidance</li>
          <li>Custom learning path development</li>
        </ul>
      </div>

        {showSalesModal && (
          <SalesModal
            feature={salesModalFeature}
            onClose={closeSalesModal}
            subscription={subscription}
          />
        )}

        <ComparisonModal
          isOpen={isComparisonModalOpen}
          onClose={closeComparisonModal}
          chat1={chat1}
          chat2={chat2}
        />

        <input {...getInputProps()} />
        {/* Sidebar Component */}
          {MemoizedSidebar}
          {/* ... rest of your JSX ... */}
      
      {/* Chat Header */}
      
      <div className={`chat-header ${(isSidebarOpen || isHoverOpen) ? 'sidebar-open' : ''}`}>
        <div className="header-left">
          {authLoading ? null : (
            <>
              {(!user) ? (
                <div className="logo-text">StudyBuddy</div>
              ) : (
                <>
                  {isSpaceChat ? (
                    <>
                      <button
                        className="return-chat-button"
                        onClick={() => spaceId ? navigate(`/study-spaces/${spaceId}`) : navigate('/study-spaces')}
                        aria-label="Return to space"
                      >
                        <ArrowLeft01Icon size={24}/>
                        <span className="hover-text right">Return to Space</span>
                      </button>
                      {filteredMessages?.length > 0 && (
                        <button
                          className="header-button hoverable"
                          onClick={() => navigate(`/ss/new?spaceId=${spaceId}`)}
                          aria-label="Start new chat"
                        >
                          <PencilEdit02Icon size={22} />
                          <span className="hover-text chat">New Space Chat</span>
                        </button>
                      )}
                    </>
                  ) : (
                    <div>
                      {!isSidebarOpen && !isHoverOpen && (
                        <div className="header-buttons">
                          <button 
                            onClick={toggleSidebar} 
                            onMouseEnter={handleHoverOpen} 
                            onMouseLeave={handleHoverClose}
                            className="header-button"
                          >
                            <SidebarLeftIcon size={24} />
                          </button>
                          <button 
                            onClick={handleAddChat}
                            aria-label="Start new chat"  
                            className="header-button hoverable"
                          >
                            <PencilEdit02Icon size={24} />
                            <span className="hover-text chat">New Chat</span>
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          )}
          
        </div>        
          <div className="header-center">
          {!isSpaceChat && searchEnable && renderHeaderCenter()}
        </div>

        <div className="header-right">
          {authLoading ? null : (
            user ? (
              <>
                  {!isSpaceChat && (
                    <AddToSpaceButton 
                      chatId={chatId} 
                      onAdd={handleAddToSpace}
                    />
                  )}
                <button 
                  onClick={toggleResources}
                  className="header-button hoverable"
                >
                  <SlidersVerticalIcon size={25} />
                   <span className="hover-text resources">{isResourcesVisible ? 'Close Resources' : 'Open Resources'}</span>
                </button>
                <UserDropdown user={user} handleLogout={handleLogout} />
              </>
            ) : (
              <>
                  <Link 
                    to="/signup" 
                    className="auth-button" 
                  >
                    Sign Up
                  </Link>
                <Link 
                  to="/login" 
                  className="auth-button sign-in"
                >
                  Sign In
                </Link>
              </>
            )
          )}
        </div>
      </div>

      {/* Chat Area */}
      <div className={`chat-area ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'} ${isResourcesVisible ? 'resources-open' : ''} ${isResourcesExpanded ? 'resources-expanded' : ''}`}>
        {/* Messages Container */}
        <div 
          className={containerClassName}
          
        >
          {(authLoading || isInitialLoading || dataLoading ) && (chatId!==null && chatId !== undefined) && (
              <div className="messages-skeleton">
                {[1, 2, 3].map((i) => (
                  <React.Fragment key={i}>
                    <div className="message user skeleton">
                      <div className="message-content">
                        <div className="skeleton-text"></div>
                        <div className="skeleton-text"></div>
                      </div>
                    </div>
                    <div className="message ai skeleton">
                      <div className="message-content">
                        <div className="skeleton-text"></div>
                        <div className="skeleton-text"></div>
                        <div className="skeleton-text"></div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
          )}
          <div 
            className={`messages-wrapper ${!isDefaultDisplay ? 'not-default-display' : ''}`}
            style={{ overscrollBehavior: 'contain' }}
            ref={messagesContainerRef}
          >
            <div className="messages-content">
              { !isInitialLoading && filteredMessages && filteredMessages?.length === 0 && !editedMessageId? (
                searchQuery ? (
                  <div className="no-results">No messages found matching your search.</div>
                ) : (
                  isDefaultDisplay && !isGeneratingResponse && !editedMessageId ? renderDefaultDisplay() : null
                )
              ) : (
                <>
                  {messages && messages.map((message, index, array) => renderMessage(message, index, array))}
                </>
              )}
              {searchQuery && searchResults?.length > 0 && (
                <OtherChatsSearchResults 
                  results={searchResults}
                  onResultClick={handleSearchResultClick}
                />
              )}
              
              {isGeneratingResponse && editedMessageId ? (
                <div className="message ai">
                  <img src={theme === 'dark' ? CognoraLogoTransparentDark : studyBuddyLogo} alt="StudyBuddy AI" className={`ai-icon ${theme === 'dark' ? 'dark' : ''}`} style={{backgroundColor: 'transparent'}} />
                  <div className="message-content">                    
                    <div className="message-content-wrapper">
                      <AIResponseRenderer content={generatedContent} renderResource={renderResource} messageId={regeneratingMessageId} />
                    </div>
                    {renderLoadingSymbol()}
                  </div>
                </div>
              ) : null}            
              <div ref={messagesEndRef} style={{ height: '70px' }} /> {/* Make sure this is at the very end */}
            </div>
            <ResourcesSection
              isLoadingResource={isLoadingResource}
              renderExpanded={renderExpanded}           
              ExpandedResource={ExpandedResource}
              isVisible={isResourcesVisible}
              projectTitle={currentChatTitle}
              isExpanded={isResourcesExpanded}
              setIsExpanded={setIsResourcesExpanded}
              onClose={closeResources}
              resources={resources[chatId] || []}  // Pass the resources specific to the current chat
              onResourceClick={handleResourceClick}
              expandedResource={expandedResource}
              setExpandedResource={setExpandedResource}
              showCustomToast={showCustomToast}
              getResourceIcon={getResourceIcon}
              handleImageClick={handleImageClick}
            />
          </div>
        </div>

        {showScrollButton && !isDefaultDisplay && (
          <button 
            className={`scroll-to-bottom ${isGeneratingResponse ? 'generating-response' : ''}`}
            onClick={() => {
              scrollToBottom();
            }} 
            aria-label="Scroll to bottom"
          >
            <FaChevronDown size={20} />
          </button>
        )}

        {showAuthPrompt && (
            <div className="auth-prompt">
              <AlertCircleIcon size={24} />
              <div className="auth-prompt-content">
                <button 
                  className="close-auth-prompt"
                  onClick={() => setShowAuthPrompt(false)}
                  aria-label="Close auth prompt"
                >
                  <Cancel01Icon />
                </button>
                <p>Please sign up or log in to continue messaging</p>
                <div className="auth-prompt-buttons">
                  <Link to="/signup" className="auth-prompt-button signup">
                    Sign Up
                  </Link>
                  <Link to="/login" className="auth-prompt-button login">
                    Log In
                  </Link>
                </div>
              </div>
            </div>
        )}


        {user && remainingChats!= null && !authLoading && remainingChats < 1 && filteredMessages?.length > 0 && renderChatLimitBanner()}
        {/* Input Container */}
        <div 
          className={`input-container ${isResourcesVisible ? 'resources-open' : ''} ${isDragActive ? 'dragging' : ''} ${!user ? 'auth-disclaimer' : ''}`}
        >
          
          {filteredMessages && (filteredMessages?.length > 0) && !isComparisonModalOpen ? (
            <>
              <div className={`input-area ${!user ? 'auth-disclaimer' : ''}`}>
                <InputArea 
                  user={user}
                  textFiles={textFiles}
                  imageFiles={imageFiles}
                  removeImageFile={removeImageFile}
                  removeTextFile={removeTextFile}
                  MemoizedSubmitButton={MemoizedSubmitButton}
                  showAutoComplete={showAutoComplete}
                  ModeSelector={MemoizedSelector}
                  handleSubmit={handleSubmit}
                  MemoizedTextarea={MemoizedTextarea}
                  handleAttachClick={handleAttachClick}
                  handleFileChange={handleFileChange}
                  fileInputRef={fileInputRef}
                  remainingChats={remainingChats}
                  windowWidth={windowWidth}
                  MemoizedAutoCompleteDropdown={MemoizedAutoCompleteDropdown}
                  isChat={true}
                />
              </div>
            </>
          ) : null}

        </div>
        {!user && (
          <div className={`auth-disclaimer ${filteredMessages && filteredMessages?.length > 0 ? 'has-messages' : ''}`}>
            <p>Chats are not saved in guest mode. Sign in to unlock full chat capabilities and get personalized learning.</p>
            {filteredMessages?.length === 0 && (
              <p>By sending a message, you agree to our <span className="link-wrapper"><a href="https://cognora.ca/terms-of-service" target="_blank" rel="noopener noreferrer">Terms & Conditions</a></span> and <span className="link-wrapper"><a href="https://cognora.ca/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></span>.</p>
            )}
          </div>
        )}
      </div>

      {isDragActive && (
        <div className="file-drop-overlay">
          <div className="file-drop-content">
            <h3>Drop your files here</h3>
            <p>Release to attach PDFs, images or documents to your chat</p>
          </div>
        </div>
      )}

      {/* Add this new component for the auth prompt */}


      {/* Update the error handling condition */}
      {(!chatExists && isChatsLoaded) || messagesError?.isNotFoundError || messagesError?.isAuthError ? (
        <ChatNotFound />
      ) : (
        <>
          {/* Rest of your existing JSX */}

        </>
      )}
      <NamePromptModal 
        isOpen={showNamePrompt} 
        onSubmit={handleNameSubmit}
      />
    </div>
  );
};

export default React.memo(Chats);
