import React from 'react';
import { Link } from 'react-router-dom';
import '../../Styles/Footer.css';
import CognoraLogo from '../../assets/Cognore Inverse Logo (1).png';
import { useTheme } from '../../App';
import { DarkModeIcon, Sun01Icon, SystemUpdate01Icon } from 'hugeicons-react';

const Footer = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <Link to="/">
            <img src={CognoraLogo} alt="Cognora Logo" className="footerlogo" />
          </Link>
          <Link to="https://cognora.ca" className="logo-text">Cognora</Link>
          <div className="theme-picker">
            <button 
              onClick={toggleTheme}
              className="theme-button"
              aria-label="Toggle theme"
            >
              {theme === 'light' ? (
                <>
                  <Sun01Icon size={20} />
                </>
              ) : theme === 'dark' ? (
                <>
                  <DarkModeIcon size={20} />
                </>
              ) : (
                <>
                 <SystemUpdate01Icon size={20} />
                </>
              )}
            </button>
          </div>
        </div>
        <div className="footer-section">
          <h4>Quick Links</h4>
          <ul>
            <li><Link to="/">Chat</Link></li>
            <li><Link to="/pricing">Pricing</Link></li>
            {/* <li><Link to="/features">Features</Link></li> */}
            <li><Link to="https://cognora.ca/faq">Contact Us</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Legal</h4>
          <ul>
            <li><Link to="https://cognora.ca/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="https://cognora.ca/terms-of-service">Terms of Service</Link></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} LEKAN INC. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;