import React, { useState } from 'react';
import '../../Styles/Partials/SitewideBanner.css';
import { handleStripePortalRedirect } from '../../services/api';
import { Cancel01Icon } from 'hugeicons-react';

const SitewideBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="sitewide-banner">
      <div className="banner-content">
        Your latest payment has failed. 
        <button onClick={handleStripePortalRedirect} className="link-like-button">
          Please update your payment information.
        </button>
      </div>
      <button 
        onClick={() => setIsVisible(false)} 
        className="banner-close-button"
        aria-label="Close banner"
      >
        <Cancel01Icon />
      </button>
    </div>
  );
};

export default SitewideBanner;