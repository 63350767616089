import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { createCheckoutSession, handleStripePortalRedirect } from '../services/api';
import { FaCheck, FaArrowRight, FaChevronRight } from 'react-icons/fa';
import useDocumentTitle from '../UseDocumentTitle';
import '../Styles/Pricing.css';
import { Helmet } from 'react-helmet';
import { pricingTiers } from './Data/PricingData';
import LoadingOverlay from './Partials/LoadingOverlay';
import { Add01Icon, Book02Icon, File02Icon, Message01Icon, Search01Icon, Upload01Icon } from 'hugeicons-react';
import faqs from './Data/Faqs';

const Pricing = () => {
    useDocumentTitle('StudyBuddy - Pricing');
    const navigate = useNavigate();
    const { user, isAuthenticated } = useAuth();
    const subscription = user?.subscription;
    const { tierName } = useParams();
    const searchParams = useMemo(() => new URLSearchParams(window.location.search), []);
    const promoCode = searchParams.get('promo');
    const [currency, setCurrency] = useState('usd');
    const [billingCycle, setBillingCycle] = useState('monthly');
    const [activeFaqId, setActiveFaqId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const userLanguage = navigator.language || navigator.userLanguage;
        if (userLanguage.toLowerCase().includes('ca')) {
            setCurrency('cad');
        } else {
            setCurrency('usd');
        }
    }, []);

    const handleSubscription = useCallback(async (tier, interval = billingCycle, currencyCode = currency, trialdays) => {
        setIsLoading(true);
        if (!isAuthenticated) {
            navigate('/signup', { 
                state: { 
                    selectedTier: tier, 
                    interval, 
                    currency: currencyCode,
                    promoCode,
                    trialdays: trialdays || searchParams.get('promodays')
                } 
            });
            return;
        }


        try {
            if(subscription && subscription.tier !== "free"){
                await handleStripePortalRedirect();
                return;
            }
            let response;
            
            if(tier === "free" || tier === "premium"){
                response = await createCheckoutSession(tier, interval, currencyCode, promoCode, trialdays || searchParams.get('promodays'));
            } else {
                response = await createCheckoutSession(tier, interval, currencyCode, promoCode, trialdays || searchParams.get('promodays'));
            }

            if (response.url) {
                window.location.href = response.url;
            } else {
                console.error('Unexpected response format:', response);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }, [isAuthenticated, navigate, subscription, billingCycle, currency, promoCode, searchParams]);

    const renderButton = (tier) => {
        if (!user) {
            return (
                <button 
                    onClick={() => handleSubscription(
                        tier.name.toLowerCase(), 
                        billingCycle, 
                        currency, 
                        searchParams.get('promodays')
                    )} 
                    className={`signup-button ${isLoading ? 'loading' : ''}`}
                    disabled={isLoading}
                >
                    {tier.name.toLowerCase() === 'free' ? 'Get Started' : `Get ${tier.name}`}
                </button>
            );
        }

        if (subscription && subscription.tier !== 'free') {
            if (subscription.tier.toLowerCase() === tier.name.toLowerCase()) {
                return (
                    <button onClick={handleStripePortalRedirect} className="signup-button">
                        Manage Subscription
                    </button>
                );
            } else {
                return (
                    <button 
                        className="signup-button" 
                        style={{visibility: 'hidden'}} 
                        disabled
                    >
                        Invisible
                    </button>
                );
            }
        }

        return (
            <button 
                onClick={() => handleSubscription(
                    tier.name.toLowerCase(), 
                    billingCycle, 
                    currency, 
                    searchParams.get('promodays')
                )} 
                className="signup-button"
            >
                {tier.name.toLowerCase() === 'free' ? 'Current Plan' : `Choose ${tier.name}`}
            </button>
        );
    };

    useEffect(() => {
        const detectUserLocation = async () => {
            try {
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                if (data.country_code === 'CA') {
                    setCurrency('cad');
                } else if (data.country_code === 'US') {
                    setCurrency('usd');
                } else if (data.continent_code === 'EU') {
                    setCurrency('eur');
                } else {
                    setCurrency('usd'); // Default to USD for other countries
                }
            } catch (error) {
                console.error('Error detecting user location:', error);
                setCurrency('usd'); // Default to USD if there's an error
            }
        };

        detectUserLocation();
    }, []);

    const toggleFaq = (id) => {
        setActiveFaqId(activeFaqId === id ? null : id);
    };

    useEffect(() => {
        if (tierName) {
            const tier = pricingTiers.find(t => t.name.toLowerCase() === tierName.toLowerCase());
            if (tier) {
                handleSubscription(tier.name.toLowerCase());
            }
        }
    }, [tierName, handleSubscription, searchParams]);

    const scrollToStudySpaces = () => {
        const element = document.getElementById('study-spaces-showcase');
        if (element) {
            const headerOffset = 80; // Adjust this value based on your header height
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        if (window.location.hash === '#study-spaces') {
            // Small delay to ensure content is rendered
            setTimeout(scrollToStudySpaces, 100);
        }
    }, []);

    return (
        <>
            {isLoading ?
                <LoadingOverlay />
            :
            <div className="pricing-container">
                <Helmet>
                    <title>StudyBuddy Pricing - Choose Your Study Plan</title>
                    <meta name="description" content="Explore StudyBuddy's flexible pricing plans. From free basic tutoring to unlimited AI assistance, find the perfect plan for your academic needs." />
                    <meta name="keywords" content="StudyBuddy pricing, AI tutor plans, academic assistance pricing, student tutoring costs" />
                    <link rel="canonical" href="https://studdybuddy.ca/pricing" />
                    <meta name="robots" content="noindex, follow" />
                </Helmet>
                <div className="pricing-hero">
                    <h1>Upgrade Your Learning Experience</h1>
                    <p>Unlock advanced features and maximize your potential. Start with a 7-day free trial.</p>
                    
                    <div className="pricing-toggle">
                        <span className={billingCycle === 'monthly' ? 'active' : ''}>Monthly</span>
                        <div className="toggle-switch">
                            <input
                                type="checkbox"
                                id="billing-toggle"
                                checked={billingCycle === 'annual'}
                                onChange={() => setBillingCycle(billingCycle === 'monthly' ? 'annual' : 'monthly')}
                            />
                            <label htmlFor="billing-toggle"></label>
                        </div>
                        <span className={billingCycle === 'annual' ? 'active' : ''}>
                            Annual
                            <span className="save-badge">
                                {window.innerWidth < 600 ? '30%' : 'Save 30%'}
                            </span>
                        </span>
                    </div>
                </div>

                <div className="pricing-cards">
                    {pricingTiers.filter(tier => tier.name !== 'Free').map((tier) => (
                        <div 
                            key={tier.name} 
                            className={`pricing-card ${tier.highlight ? 'highlight' : ''}`}
                        >
                            {tier.freeTrial && (
                                <div className="free-trial-badge">
                                    {tier.freeTrial} Free Trial
                                </div>
                            )}
                            <div className="card-header">
                                <h3>{tier.name}</h3>
                                <p className="tagline">{tier.tagline}</p>
                                <div className="price-container">
                                    {tier.name !== 'Free' ? (
                                        <>
                                            <span className="price">
                                                {billingCycle === 'annual' ? (parseFloat(tier.price[currency].annual.replace(/[^0-9.-]+/g,"")) / 12).toFixed(2) : tier.price[currency][billingCycle]}
                                            </span>
                                            <span className="period">
                                                /mo
                                            </span>

                                            {billingCycle === 'annual' && (
                                                <span className="annual-total">
                                                    {tier.price[currency].annual}/yr
                                                </span>
                                            )}
                                        </>
                                    ) : (
                                        <span className="price">Free</span>
                                    )}
                                </div>
                            </div>

                            <div className="features">
                                {tier.features.map((feature, index) => (
                                    <div key={index} className="feature">
                                        <FaCheck /> {feature}
                                    </div>
                                ))}
                            </div>

                            {renderButton(tier)}
                        </div>
                    ))}
                </div>
                <div className="free-tier-footer">
                    <p>Not ready to upgrade? Try our <Link to="/signup">free plan</Link> to get started with basic features.</p>
                </div>
                    
                <div id="study-spaces-showcase" className="study-spaces-showcase">
                    <div className="showcase-header">
                        <h2>Organize Your Learning with Study Spaces</h2>
                        <p>Create dedicated spaces for each subject, complete with AI-powered discussions and study materials</p>
                    </div>

                    <div className="showcase-content">
                        <div className="showcase-visual">
                            <div className="feature-preview">
                                <div className="space-preview-header">
                                    <div className="header-left">
                                        <div className="return-to-chats">
                                            <Message01Icon size={20} />
                                            <span>Chats</span>
                                        </div>
                                        <h3>Study Spaces</h3>
                                    </div>
                                    <div className="header-actions">
                                        <button className="create-space-btn">
                                            <Add01Icon size={16} />
                                            New Space
                                        </button>
                                    </div>
                                </div>

                                <div className="space-preview-search">
                                    <Search01Icon size={18} className="search-icon" />
                                    <input
                                        type="text"
                                        placeholder="Search study spaces..."
                                        disabled
                                    />
                                </div>

                                <div className="space-preview-grid">
                                    {[
                                        {
                                            title: "Physics 101",
                                            description: "Classical mechanics and kinematics",
                                            index: 0
                                        },
                                        {
                                            title: "Data Structures",
                                            description: "Algorithms and programming concepts",
                                            index: 1
                                        },
                                        {
                                            title: "World History",
                                            description: "Modern historical analysis",
                                            index: 2
                                        }
                                    ].map((space) => (
                                        <div 
                                            key={space.title}
                                            className="space-preview-card"
                                            style={{ '--index': space.index }}
                                        >
                                            <div className="space-header">
                                                <Book02Icon size={20} />
                                                <h3>{space.title}</h3>
                                            </div>
                                            <p className="space-description">{space.description}</p>
                                            <div className="space-footer">
                                                <div className="space-stats">
                                                    <span className="stat">
                                                        <Message01Icon size={14} />
                                                        3 chats
                                                    </span>
                                                    <span className="stat">
                                                        <File02Icon size={14} />
                                                        2 files
                                                    </span>
                                                </div>
                                                <FaChevronRight size={16} className="arrow-icon" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="feature-grid">
                            <div className="feature-column left">
                                <div className="feature-card">
                                    <div className="feature-icon">
                                        <Message01Icon size={24} />
                                    </div>
                                    <div className="feature-content">
                                        <h3>AI-Powered Discussions</h3>
                                        <p>Start multiple chat sessions within each space, maintaining context across conversations for deeper understanding.</p>
                                    </div>
                                </div>
                                <div className="feature-card">
                                    <div className="feature-icon">
                                        <Upload01Icon size={24} />
                                    </div>
                                    <div className="feature-content">
                                        <h3>Document Analysis</h3>
                                        <p>Upload PDFs and study materials for AI-assisted analysis and interactive discussions about your content.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="feature-column right">
                                <div className="feature-card">
                                    <div className="feature-icon">
                                        <Book02Icon size={24} />
                                    </div>
                                    <div className="feature-content">
                                        <h3>Subject Organization</h3>
                                        <p>Create dedicated spaces for each subject with custom titles, descriptions, and organized study materials.</p>
                                    </div>
                                </div>
                                <div className="feature-card">
                                    <div className="feature-icon">
                                        <File02Icon size={24} />
                                    </div>
                                    <div className="feature-content">
                                        <h3>Learning Resources</h3>
                                        <p>Access study materials, chat history, and AI-generated content in one organized space for each subject.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="showcase-cta">
                            <div className="cta-content">
                                <h3>Ready to Enhance Your Study Experience?</h3>
                                <p>Choose a plan with Study Spaces to create your personalized learning environment.</p>
                                <button 
                                    className="cta-button"
                                    onClick={() => {
                                        const pricingCards = document.querySelector('.pricing-cards');
                                        if (pricingCards) {
                                            pricingCards.scrollIntoView({ 
                                                behavior: 'smooth',
                                                block: 'center'
                                            });
                                        }
                                    }}
                                >
                                    View Plans with Study Spaces
                                    <FaChevronRight size={20} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pricing-faq">
                    <h2>Frequently Asked Questions</h2>
                    <div className="faq-grid">
                        {faqs.map((faq) => (
                            <button 
                                key={faq.id} 
                                className={`faq-item ${activeFaqId === faq.id ? 'active' : ''}`}
                                onClick={() => toggleFaq(faq.id)}
                            >
                                <div className="faq-question">
                                    <span>{faq.question}</span>
                                    <FaArrowRight className="arrow" />
                                </div>
                                <div className="faq-answer">{faq.answer}</div>
                            </button>
                        ))}
                    </div>
                </div>

                <div className="free-tier-footer">
                    <p>Have more questions? Visit our <Link to="https://cognora.ca/faq">FAQ page</Link> for help.</p>
                </div>
            </div>
            }
        </>
    );
};

export default Pricing;
